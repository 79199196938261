import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSaleOrderBills,
  getSaleUsingInvoiceId,
  getSaleOrderBillsBydate,
  deleteSalesOrderBillById,
  saleOrderBySearchValue,
} from "../../../redux/actions/saleAction.js";
import Highlighter from "react-highlight-words";
import DateWiseSearch from "../../../util/DateWiseSearch.js";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import ShowSoBillProduct from "./ShowSoBillProduct.js";
import Search from "../../../util/Search.js";
const { Column } = Table;
const Id = localStorage.getItem("roleName");
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];
class PurchaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      selectedBill: [],
      checkBoxStatus: false,
    };
  }

  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };

  componentDidMount() {
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };

          this.props.getSalesListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };

          this.props.getSalesListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };

        this.props.getSaleOrderBillsBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getSaleOrderBillsBydate(reportObj);
    }
  }
  //   onDeleteClick = (id) => {
  //     this.props.deletePurchase(id);
  //   };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) => {
      if (dataIndex == "agentName") {
        return record?.userId?.agentId?.name
          ? record?.userId?.agentId?.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      } else if (dataIndex == "dealerName") {
        return record?.userId?.name
          ? record?.userId?.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      } else {
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  deleteSaleOrder = async (record) => {
    //
    let obj = {
      invoiceId: record.invoiceId,
    };
    console.log("obj-098", obj);

    await this.props
      .deleteSalesOrderBillById(obj)
      .then(() => {
        // let startDate = moment(new Date()).format("YYYY-MM-DD")
        //   let endDate = moment(new Date()).format("YYYY-MM-DD")
        //   let reportObj = {
        //     startDate: startDate,
        //     endDate: endDate + " " + moment().format("HH:mm:ss"),
        //   };
        let localStartDate = localStorage.getItem("startDate");
        let localEnddate = localStorage.getItem("endDate");
        let reportObj = {
          startDate: localStartDate,
          endDate: localEnddate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getSaleOrderBillsBydate(reportObj);
      })
      .catch((error) => {
        console.error("Error deleting bill:", error);
      });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  updateBill = async (text) => {
    console.log("test sales order bill", text);
    await this.props.getSaleUsingInvoiceId(text.invoiceId);
    this.props.history.push({
      pathname: "/dagaImpex/updateSales",
      state: {
        id: text._id,
        saleObj: [text, this.props.salesOrders],
        salesOrderChangeStatus: true,
      },
    });
  };

  listData = () => {
    const { salesorderBydaterange, searchData } = this.props;
    const value =
      salesorderBydaterange && salesorderBydaterange.length > 0
        ? salesorderBydaterange[0]
        : [];
    // if (value.message == "Empty") {
    //   return [];
    // }

    if (this.state.searchValue) {
      const data = searchData?.details?.map((obj) => {
        return { ...obj, c_name: obj?.userId?.name ? obj.userId.name : "" };
      });
      const sortedArray = data?.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      return sortedArray;
    } else if (salesorderBydaterange.data) {
      return salesorderBydaterange.data;
    } else {
      return this.props.salesOrderBills;
    }
  };

  // Mohit New Logic For Merging Sales Orders Bills------------Start
  handleCheckBox = (e, data) => {
    if (e.target.checked == true) {
      let selectedSalesOrders = this.state.selectedBill;
      selectedSalesOrders.push(data.invoiceId);
      this.setState({ selectedBill: selectedSalesOrders });
    } else {
      let selectedSalesOrders = this.state.selectedBill.filter(
        (obj) => obj !== data.invoiceId
      );
      this.setState({ selectedBill: selectedSalesOrders });
    }
  };

  handleSearchType = (value) => {
    if (value === "CustomerName") {
      this.setState({ checkBoxStatus: true });
    } else {
      this.setState({ checkBoxStatus: false });
    }
  };

  // Mohit New Logic For Merging Sales Orders Bills------------End

  render() {
    const { salesorderBydaterange } = this.props;
    return (
      <div className="subCategory-dashboard">
        {console.log("check my selected bills", this.state.selectedBill)}
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Sales Orders</div>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}></Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} alig="end">
              <Button
                type="primary"
                className="save-modal text-white"
                onClick={() => {
                  this.props.history.push("/dagaImpex/addSales");
                }}
              >
                Add Sales
              </Button>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Search
                title="Sales Orders"
                placeholder="Sale Return Id, Barcode, Agent Name or CustomerName"
                searchopt={searchOptions}
                changeFunction={this.onChange}
                cancelState={this.cancelState}
                location={this.props.history.location}
                handleSearchType={this.handleSearchType}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <DateWiseSearch keyId={"Sales Orders"} />
            </Col>
          </Row>

          <div className="card ">
            <div className="card-body">
              <Table
                dataSource={this.listData()}
                // dataSource={salesorderBydaterange.data ? salesorderBydaterange.data : this.props.salesOrderBills ? this.props.salesOrderBills : []}
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 100,
                  total:
                    this.props.salesOrderBills &&
                    this.props.salesOrderBills.count
                      ? this.props.salesOrderBills.count
                      : "",
                  onChange: (page) => {
                    this.props.getSaleOrderBills(page);
                  },
                }}
                scroll={{ y: 400 }}
              >
                {/* {this.state.checkBoxStatus && (
                  <Column
                    title="Select"
                    key="index"
                    fixed="left"
                    render={(value, item, index) => (
                      <Checkbox
                        onChange={(e) => this.handleCheckBox(e, item)}
                      />
                    )}
                    width={100}
                  />
                )} */}
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={210}
                  render={(value, item, index) =>
                    `${moment(item.date).format("YYYY-MM-DD")} (${moment(
                      item.date
                    ).format("HH:mm:ss")} )`
                  }
                />
                <Column
                  title="Invoice No."
                  {...this.getColumnSearchProps("invoiceId")}
                  dataIndex="invoiceId"
                  key="invoiceId"
                  render={(value, item, index) => `S-${item.invoiceId}`}
                  width={150}
                />
                <Column
                  title="Customer Name"
                  {...this.getColumnSearchProps("dealerName")}
                  dataIndex="dealerName"
                  key="dealerName"
                  width={200}
                  render={(value, item, index) => `${item.userId.name}`}
                />
                <Column
                  title="Agent Name"
                  {...this.getColumnSearchProps("agentName")}
                  dataIndex="userId"
                  key="userId"
                  render={(value, item, index) =>
                    `${
                      item?.userId?.agentId ? item?.userId?.agentId?.name : ""
                    }`
                  }
                  width={170}
                />
                <Column
                  title="Total Boxes"
                  dataIndex="totalBoxes"
                  key="totalBoxes"
                  width={120}
                />
                <Column
                  title="Total Qty"
                  dataIndex="totalQuantity"
                  key="totalQuantity"
                  width={120}
                />
                <Column
                  title="Total Amt"
                  dataIndex="totalAmount"
                  key="totalAmount"
                  render={(value, item, index) =>
                    parseInt(item.totalAmount).toFixed()
                  }
                  width={100}
                />
                <Column
                  title="Gr Amt"
                  dataIndex="grAmountUsed"
                  key="grAmountUsed"
                  render={(value, item, index) =>
                    Number(item.grAmountUsed).toFixed()
                  }
                  width={100}
                />
                <Column
                  title="Net Amt"
                  dataIndex="netAmount"
                  key="netAmount"
                  width={110}
                />
                <Column
                  title="Remark"
                  dataIndex="remark"
                  key="remark"
                  width={120}
                />
                <Column
                  title="Action"
                  key="action"
                  fixed="right"
                  width={200}
                  render={(text, record) =>
                    this.props.roleId === "629346953e2dcbf2f6d53242" ||
                    this.props.roleId === "6227b38275361cd819e16067" ? (
                      <Row>
                        {" "}
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          {" "}
                          <Popconfirm
                            title="Are you sure to Edit this bill?"
                            onConfirm={() => this.updateBill(text)}
                            okText="Yes"
                            cancelText="No"
                            disabled={
                              record?.remark === "Bill Deleted." ? true : false
                            }
                          >
                            {" "}
                            <div className="btn">
                              {" "}
                              <Tooltip title="Edit Bill">
                                {" "}
                                <EditOutlined />{" "}
                              </Tooltip>{" "}
                            </div>{" "}
                          </Popconfirm>{" "}
                        </Col>{" "}
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          {" "}
                          <ShowSoBillProduct
                            invoiceId={record.invoiceId}
                            data={record}
                          />{" "}
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          {" "}
                          <Popconfirm
                            title="Are you sure to delete this bill?"
                            onConfirm={() => this.deleteSaleOrder(text)}
                            okText="Yes"
                            cancelText="No"
                            disabled={
                              record?.remark === "Bill Deleted." ? true : false
                            }
                          >
                            {" "}
                            <div className="btn">
                              {" "}
                              <Tooltip title="delete Bill">
                                {" "}
                                <DeleteOutlined />{" "}
                              </Tooltip>{" "}
                            </div>{" "}
                          </Popconfirm>{" "}
                        </Col>{" "}
                      </Row>
                    ) : (
                      <Row>
                        {" "}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {" "}
                          <Popconfirm
                            title="Are you sure to Edit this bill?"
                            onConfirm={() => this.updateBill(text)}
                            okText="Yes"
                            cancelText="No"
                            disabled={
                              record?.remark === "Bill Deleted." ? true : false
                            }
                          >
                            {" "}
                            <div className="btn">
                              {" "}
                              <Tooltip title="Edit Bill">
                                {" "}
                                <EditOutlined />{" "}
                              </Tooltip>{" "}
                            </div>{" "}
                          </Popconfirm>{" "}
                        </Col>{" "}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {" "}
                          <ShowSoBillProduct
                            invoiceId={record.invoiceId}
                            data={record}
                          />{" "}
                        </Col>{" "}
                      </Row>
                    )
                  }
                />
              </Table>
            </div>
            {this.state.selectedBill.length > 0 && (
              <Row gutter={16} align="center" className="mb-4">
                <Col span={4}>
                  <Button
                    type="primary"
                    className="save-modal text-white"
                    onClick={() => {
                      this.props.history.push(
                        "/dagaImpex/merge-SalesOrders-bills",
                        { invoiceIds: this.state.selectedBill }
                      );
                    }}
                  >
                    Merge Bills
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PurchaseList.propTypes = {
  getSaleOrderBills: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getSaleOrderBillsBydate: PropTypes.object.isRequired,
  deleteSalesOrderBillById: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  salesOrderBills: state.saleData.salesOrderBills,
  searchData: state.saleData.saleOrderSearchData,
  salesOrders: state.saleData.salesOrders,
  salesorderBydaterange: state.saleData.salesorderBydaterange,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getSaleOrderBills,
  getSaleUsingInvoiceId,
  getSaleOrderBillsBydate,
  deleteSalesOrderBillById,
  saleOrderBySearchValue,
})(PurchaseList);
