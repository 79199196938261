import React, { useState } from "react";
import { Row, Col, Button, Modal, message, Upload, Spin, Checkbox } from "antd";
import {
  CloudUploadOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  addSingleGlobalEcomImage,
  deleteSingleGlobalImage,
  saveEcomBanner,
  getBannerList,
  getLatestBannerList
} from "../../../redux/actions/purchaseAction";
const { Dragger } = Upload;
let idealWidth = 1440;
let idealHeight = 430;
const UploadBannerModal = ({ getLatestBannerData }) => {
  const [isMobile, setisMobile] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalloading, setfinalLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [bannerImage, setbannerImage] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
    setLoading(false);
    setImageUrl(false);
    setbannerImage([]);
    setErrorMsg(null);
    window.location.reload();
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  );

  const handleChange = async ({ fileList }) => {
    if (isMobile) {
      idealWidth = 1080;
      idealHeight = 1080;
    }
    const file = fileList[0].originFileObj;
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
      const image = new Image();
      image.src = imageDataUrl;
      image.onload = async () => {
        const width = image.width;
        const height = image.height;
        if (width !== idealWidth || height !== idealHeight) {
          message.error(
            `Please upload a banner with dimensions ${idealWidth}x${idealHeight}`
          );
          setErrorMsg(
            `Please upload a banner with dimensions ${idealWidth}x${idealHeight}`
          );
          setbannerImage(fileList);
          setLoading(false);
          return;
        }
        setbannerImage(fileList);
        setLoading(true);
        let response = await dispatch(
          addSingleGlobalEcomImage(fileList[0].originFileObj)
        );
        if (response && response.status == 200) {
          setImageUrl(response.data.filePath);
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = async () => {
    setLoading(true);
    let response = await dispatch(
      deleteSingleGlobalImage({ bannerImages: imageUrl })
    );
    setbannerImage([]);
    setImageUrl("");
    setErrorMsg(null);
    if (response && response.status == 200) {
      message.success(response.data);
    }
    setLoading(false);
  };

  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    accept: ".png, .jpg",
    listType: "picture-card",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: {
      showPreviewIcon: false,
    },
    onChange(info) {
      setLoading(true);
      if (
        info &&
        info.file &&
        info.file.status !== "uploading" &&
        info.fileList.length !== 0
      ) {
        handleChange(info);
      }
      if (info.fileList.length == 0) {
        handleRemove();
      }
    },
  };

  const handleSubmit = async () => {
    if (imageUrl && bannerImage.length > 0) {
      const file = bannerImage[0].originFileObj;
      const reader = new FileReader();
  
      reader.onload = () => {
        const imageDataUrl = reader.result;
        const image = new Image();
        image.src = imageDataUrl;
  
        image.onload = async () => {
          const width = image.width;
          const height = image.height;
  
          if (width !== idealWidth || height !== idealHeight) {
            message.error(
              `Please ensure the uploaded banner has dimensions ${idealWidth}x${idealHeight}.`
            );
            setLoading(false);
            return;
          }
  
          // If dimensions are correct, proceed with submission
          setLoading(true);
          await dispatch(saveEcomBanner({ bannerImages: imageUrl, isMobile: isMobile })).then(
            (respo) => {
              dispatch(getLatestBannerList());
            }
          );
  
          setLoading(false);
          setImageUrl("");
          setbannerImage([]);
          setVisible(false);
          window.location.reload();
        };
      };
  
      reader.readAsDataURL(file);
    } else {
      message.warn("Please upload an image before submitting!");
    }
  };
  

  const onChecBoxChange = (e) => {
    if (e.target.checked) {
      setisMobile(true);
      idealWidth = 1080;
      idealHeight = 1080;
    } else {
      setisMobile(false);
      idealWidth = 1440;
      idealHeight = 430;
    }
  };


  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            className="ok-modal"
            style={{ color: "#ffff" }}
            onClick={() => showModal()}
          >
            <CloudUploadOutlined /> Upload
          </Button>
          <Modal
            visible={visible}
            onCancel={closeModal}
            title="Banner Upload"
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <>
              <Checkbox onChange={onChecBoxChange} defaultChecked={isMobile}>
                Upload For Mobile
              </Checkbox>
              <p style={{ color: "red" }}>{errorMsg}</p>
              <Upload {...props}>
                {bannerImage.length === 0 && !loading ? uploadButton : null}
              </Upload>
              <p>{`Note: Please upload a banner with dimensions ${idealWidth}x${idealHeight}.`}</p>
              <Row gutter={12} className="mt-2">
                <Col span={24} align="center">
                  {loading ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      loading={finalloading}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UploadBannerModal;
