import React, { Component } from "react";
import { Button, Layout, Result } from "antd";
import Dashboard from "./dashboard/Dashboard";
import SiderMenu from "./SiderMenu";
import HeaderAdmin from "./layouts/HeaderAdmin";
import PropTypes from "prop-types";
import Dealers from "./Add Details/Dealer/Dealers";
import Brands from "./Add Details/Brands/Brands";
import Category from "./Add Details/Category/Category";
import Colors from "./Add Details/Color/Colors";
import Group from "./Add Details/Group/Group";
import MetaTags from "react-meta-tags";
import Location from "./Add Details/Location/Location";
import Season from "./Add Details/Season/Season";
import Section from "./Add Details/Section/Section";
import Style from "./Add Details/Style/Style";
import Size from "./Add Details/Size/Size";
import Purchase from "./Add Details/Purchase/Purchase";
import AddPurchase from "./Add Details/Purchase/AddPurchase";
import GenerateBarcode from "./barcode/GenerateBarcode";
import PurchaseBill from "./Add Details/Purchase/Purchase Bills/PurchaseBill";
import AddSales from "./sales/AddSales";
import SalesOrders from "./sales/SalesOrders";
import Inventory from "./inventory/Inventory";
import AgeGroups from "./Add Details/Age Group/AgeGroups";
import Floors from "./Add Details/Floor/Floor";
import EcomSale from "./e-com sales/EcomSale";
import EcomProductList from "./e-com sales/EcomProductList";
import jwt_decode from "jwt-decode";
import { Redirect, Link } from "react-router-dom";
import AgentList from "../../components/admin/user/Agent/AgentList";
import AddSalesReturn from "./sales/sales return/AddSalesReturn";
import SalesReturns from "./sales/sales return/SalesReturns";
import SaleReturnBill from "./sales/sales return/saleReturnBill/saleReturnBill";
import DefectedProductBill from "./sales/sales return/defectedProductBill/defectedProductBill";
import SalesList from "./sales/SalesList";
import SalesReturnsDefected from "./sales/sales return/SalesReturnsDefected";
import AddPurchaseReturn from "./Add Details/Purchase/Purchase Return/AddPurchaseReturn";
import UpdatePurchaseReturn from "./Add Details/Purchase/UpdatePurchaseReturn";
import SalesCustomerReport from "./reports/sales customer report/SalesCustomerReport";
import UsersList from "./user/UsersList";
import SettleGRAmount from "./user/SettleGRAmount";
import PurchaseReport from "./reports/purchase report/PurchaseReport";
import SalesSummaryReport from "./reports/sales summary report/SalesSummaryReport";
import BestSellingItemReport from "./reports/best selling item report/BestSellingItemReport";
import SalesReturnReport from "./reports/sales return report/SalesReturnReport";
import DealerPurchaseReport from "./reports/dealer purchase report/DealerPurchaseReport";
import BarcodeReport from "./reports/barcode wise report/BarcodeReport";
import PurchaseReturnReport from "./reports/purchase return report/PurchaseReturnReport";
import NonMovingItemsReport from "./reports/non moving items/NonMovingItemsReport";
import FastMovingItemsReport from "./reports/fast moving items report/FastMovingItemsReport";
import SalesPersonReport from "./reports/sales person report/SalesPersonReport";
import SalesPersonComparisionReport from "./reports/sales person comparison/SalesPersonComparisionReport";
import CustomerList from "./user/CustomerList";
import EmployeeList from "./user/EmployeeList";
import PurchaseReturns from "./Add Details/Purchase/Purchase Return/PurchaseReturns";
import PurchaseReturnBillList from "./Add Details/Purchase/Purchase Return/PurchaseReturnBillList";
import Stockage from "./reports/stock age/Stockage";
import PO from "./PO Credentials/PO";
import saleReturnBillList from "./sales/sales return/saleReturnBill/saleReturnBillList";
import TopCustomer from "./reports/top customer report/TopCustomer";
import InactiveCustomerReport from "./reports/inactive customer report/InactiveCustomerReport";
import EcomOnlineSales from "./e-com sales/EcomOnlineSales";
import AgentWiseReport from "./reports/agent wise report/AgentWiseReport";
import UpdateProfile from "../../pages/other/UpdateProfile";
import SalesReport from "./reports/sales report/SalesReport";
import PurchaseAllReport from "./reports/purchase all report/PurchaseAllReport";
import DeletePurchase from "./Delete Bills/DeletePurchase";
import DeleteSales from "./Delete Bills/DeleteSales";
import UpdatePurchaseBill from "./Add Details/Purchase/Purchase Bills/update purchase/UpdatePurchaseBill";
import UpdateSales from "./sales/UpdateSales";
import CreditandDebitReport from "./reports/credit and debit report/CreditandDebitReport";
import OpeningStock from "./Opening Stock/OpeningStock";
import StockReport from "./reports/stock report/StockReport";
import UpdateSalesReturn from "./sales/sales return/UpdateSalesReturn";
import Reportone from "./Bireport/report1/Reportone";
import GrossprofitReport from "./Bireport/report1/grossprofit/GrossprofitReport";
import StockAgeBiReport from "./Bireport/stockageReport/StockAgeBiReport";
import MergeBill from "./sales/merge bill/MergeBill";
import RecyclePurchase from "../admin/recycleBin/recyclePurchase/RecyclePurchase";
import RecyclePurchaseReturn from "../admin/recycleBin/recyclePurchaseReturn/RecyclePurchaseReturn";
import RecycleSales from "../admin/recycleBin/recycleSales/RecycleSales";
import RecycleSalesReturn from "../admin/recycleBin/recycleSalesReturn/RecycleSalesReturn";
import RecycleSalesReturnDefective from "../admin/recycleBin/recycleSalesReturnDefective/RecycleSalesReturnDefective";
import SalesandSalesAgeReport from "./Bireport/salesAndSalesAgeReport/SalesandSalesAgeReport";
import Warehouse from "./warehouse/Warehouse";
import Inventories from "./warehouse/Inventories";
import AddSalesWarehouse from "./warehouse/AddSalesWarehouse";
import SalesOut from "./warehouse/SalesOut";
import WarehousePurchaseBill from "./warehouse/WarehousePurchaseBill";
import EcomCreditPaymentList from "./e-com sales/EcomCreditPaymentList";
import EcomOnlinePayList from "./e-com sales/EcomOnlinePayList";
import CustomerReport from "./reports/customer wIse report/CustomerReport";
import POTableList from "./PO Credentials/POTableList";
import ReportDashBoard from "./Report/reportDashBoard";
import CustomerDummyReport from "./reports/customer wIse report/CustomerDummyReport";
import SampleBarcode from "./barcode/sample-barcode/SampleBarcode";
import AddNewSales from "./sales/AddNewSales";
import AddNewSalesMobile from "./sales/AddNewSalesMobile";
import UpdateNewSales from "./sales/UpdateNewSales";
import SalesHeaderAdmin from "./layouts/SalesHeaderAdmin";
import ChangeAll from "../admin/user/ChangeAll";
import AddNewSalesBillMobile from "./sales/AddNewSalesBillMobile";
import AvailableStock from "./AvailableStock/AvailableStock";
import BestSellingBrandReport from "./reports/best sellling brand/BestSellingBrandReport";
import FloorGoodsTransfer from "./Floor Goods Transfer/FloorGoodsTransfer";
import Texture from "../../components/admin/Add Details/Texture/Texture";
import AddUser from "./user/AddUser";
import UploaBanner from "./uploadBanner/UploadBanner";
import InventorySalesApp from "./sales/InventorySalesApp";
import SalesListLR from "./Add LR/SalesListLR";
import UploadedLRList from "./Add LR/UploadedLRList";
import AddPurchaseOrder from "./Add Details/Purchase/AddPurchaseOrder";
import PurchaseOrderList from "./Add Details/Purchase/PurchaseOrderList";
import UpdatePurchaseOrderBill from "./Add Details/Purchase/purchaseOrderBill/UpdatePurchaseOrderBill";
import PurchaseOrderGenerateBill from "./Add Details/Purchase/purchaseOrderGenerateBill/PurchaseOrderGenerateBill";
import PurchaseOrderGenerateBillList from "./Add Details/Purchase/purchaseOrderGenerateBill/PurchaseOrderGenerateBillList";
import StockSalesApp from "./sales/StockSalesApp";
import SalePdf from "./sales/SalesListPdf";
import LookUp from "./LookUp/LookUp";
import EcomSalesReturnList from "./e-com sales/ecomSalesReturn/EcomSalesReturnList";
import MergeSalesOrdersBill from "./sales/merge salesOrder Bills/MergeSalesOrdersBill";

// import SalesAgeingReport from "./reports/sales aging report/SalesAgeingReport";
const { Sider, Content } = Layout;

export default class adminDashboard extends Component {
  constructor(props) {
    super(props);
    const Token = localStorage.getItem("token");
    let IsLoggedIn = true;
    if (Token === null) {
      IsLoggedIn = false;
    }
    this.state = { IsLoggedIn, collapsed: false };
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  renderPathName(pathname, decToken) {
    switch (pathname) {
      case "/dagaImpex/changeCategories":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <ChangeAll />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/AddNewSales":
        if (decToken.role === "629346b53e2dcbf2f6d43763") {
          return <AddNewSalesMobile history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/updateNewSales":
        if (decToken.role === "629346b53e2dcbf2f6d43763") {
          return <UpdateNewSales history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/agentlist":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "66e967351d83b294823aa3e7"
        ) {
          return <AgentList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/dealers":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Dealers />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/POList":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <POTableList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/brands":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Brands />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/category":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Category />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/color":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <Colors />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/group":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <Group />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/location":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Location />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/season":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Season />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/section":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <Section />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/texture":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <Texture />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/style":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Style />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/size":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <Size />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/ageGroup":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <AgeGroups />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/floor":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Floors />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchase":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Purchase />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/addPurchase":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <AddPurchase />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/addPurchaseOrder":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <AddPurchaseOrder />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseOrderList":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <PurchaseOrderList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/updatePurchaseOrderList":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <UpdatePurchaseOrderBill />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseOrderGenerateBill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <PurchaseOrderGenerateBill roleId={decToken.role} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseOrderGenerateBillUpdate":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <PurchaseOrderGenerateBillList roleId={decToken.role} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/upDatePurchase":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <UpdatePurchaseBill roleId={decToken.role} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      //Po Credential dg
      case "/dagaImpex/PO":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <PO />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/addPurchaseReturn":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <AddPurchaseReturn history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/updatePurchaseReturn":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <UpdatePurchaseReturn history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseReturns":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <PurchaseReturns />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/purchaseReturnBillList":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <PurchaseReturnBillList history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/generator":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <GenerateBarcode />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/purchaseBill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <PurchaseBill />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/addSales":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242"
        ) {
          return <AddSales history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/updateSales":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d43763"
        ) {
          return <UpdateSales history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/salesOrder":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242"
        ) {
          return (
            <SalesOrders history={this.props.history} roleId={decToken.role} />
          );
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

        case "/dagaImpex/merge-SalesOrders-bills":
          if (
            decToken.role === "6227b38275361cd819e16067" ||
            decToken.role === "6227b3be2fe1c37b839077b8" ||
            decToken.role === "629346953e2dcbf2f6d53242"
          ) {
            return (
              <MergeSalesOrdersBill history={this.props.history} roleId={decToken.role} />
            );
          } else {
            return (
              <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                  <Link to="/dagaImpex">
                    <Button type="primary">Back</Button>
                  </Link>
                }
              />
            );
          }
      case "/dagaImpex/mergeSalesBill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          return <MergeBill history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/availableStock":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <AvailableStock />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/salesApp/inventory":
        if (decToken.role === "629346b53e2dcbf2f6d43763") {
          return <InventorySalesApp />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesApp/stock":
        if (decToken.role === "629346b53e2dcbf2f6d43763") {
          return <StockSalesApp />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/inventory":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Inventory />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/flashSales":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d14565" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <EcomSale />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/ecomProduct":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d14565" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <EcomProductList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/ecomOnlineSales":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346b53e2dcbf2f6d14565" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <EcomOnlineSales />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
        case "/dagaImpex/ecomSalesReturn":
          if (
            decToken.role === "6227b38275361cd819e16067" ||
            decToken.role === "629346b53e2dcbf2f6d14565" ||
            decToken.role === "629346b53e2dcbf2f6d53244"
          ) {
            return <EcomSalesReturnList />;
          } else {
            return (
              <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                  <Link to="/dagaImpex">
                    <Button type="primary">Back</Button>
                  </Link>
                }
              />
            );
          }

      case "/dagaImpex/addSalesReturn":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242"
          //decToken.role === "622a47b0d10a0814f49814aa"
        ) {
          console.log("admin", this.props.history);
          return <AddSalesReturn history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/updateSalesReturn":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242"
        ) {
          console.log("admin", this.props.history);
          return <UpdateSalesReturn history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesReturns":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa"
        ) {
          return <SalesReturns />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/saleReturnBill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "66e96761812e5a81f544bc96" || 
          decToken.role === "66e966f2b3a3d123c7934fd8" 
        ) {
          console.log("admin", this.props.history);
          return <SaleReturnBill history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesReturnsDefective":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa" || 
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          return <SalesReturnsDefected />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/defectedProductBill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "66e96761812e5a81f544bc96" ||
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          console.log("admin", this.props.history);
          return <DefectedProductBill history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/salesList":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d53267" ||
          decToken.role === "629346b53e2dcbf2f6d43763" ||
          decToken.role === "66e966f2b3a3d123c7934fd8" ||
          decToken.role === "66e967351d83b294823aa3e7" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <SalesList history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/salesPdf":
        if (decToken.role === "6227b38275361cd819e16067" || decToken.role === "66e966f2b3a3d123c7934fd8" ) {
          return <SalePdf history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/addUser":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d53267" ||
          decToken.role === "66e967351d83b294823aa3e7"
        ) {
          // return <Add/>
          return <AddUser />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/salesCustomerReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <SalesCustomerReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseBillReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <PurchaseReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/purchaseReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <PurchaseAllReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesPersonReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <SalesPersonReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesPersonComparisionReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <SalesPersonComparisionReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesSummaryReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96" ||
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          return <SalesSummaryReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/bestSellingBrandReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <BestSellingBrandReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/bestSellingItemReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <BestSellingItemReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesReturnReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <SalesReturnReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/customerReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <CustomerReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/customerDummyReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <CustomerDummyReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/dealerPurchaseReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <DealerPurchaseReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/barcodeReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <BarcodeReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/purchaseReturnReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <PurchaseReturnReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/nonMovingItemsReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <NonMovingItemsReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/fastMovingItemsReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <FastMovingItemsReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/agentWiseReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <AgentWiseReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/CreditandDebitReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <CreditandDebitReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/listUsers":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <UsersList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/customers":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53267" ||
          decToken.role === "66e967351d83b294823aa3e7"
        ) {
          return <CustomerList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/employees":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa"
        ) {
          return <EmployeeList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/stockAgeingReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <Stockage />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/topCustomerReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <TopCustomer />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96" ||
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          return <SalesReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/stockReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <StockReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/inactiveCustomerReport":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "66e96761812e5a81f544bc96"
        ) {
          return <InactiveCustomerReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/BIreport/report-1":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <Reportone />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/BIreport/grossprofitreport":
        if (decToken.role === "6227b38275361cd819e16067" || decToken.role === "66e96761812e5a81f544bc96" ) {
          return <GrossprofitReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/BIreport/stockageReport":
        if (decToken.role === "6227b38275361cd819e16067" || decToken.role === "66e96761812e5a81f544bc96") {
          return <StockAgeBiReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/BIreport/sales-and-salesAge-Report":
        if (decToken.role === "6227b38275361cd819e16067" || decToken.role === "66e96761812e5a81f544bc96") {
          return <SalesandSalesAgeReport />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/recycle/purchase":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <RecyclePurchase />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/ecomSaleCreditPayment":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <EcomCreditPaymentList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/ecomSaleOnlinePayment":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <EcomOnlinePayList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/recycle/purchaseReturn":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <RecyclePurchaseReturn />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/recycle/sales":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <RecycleSales />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/recycle/salesReturn":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <RecycleSalesReturn />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/recycle/salesReturnDefective":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <RecycleSalesReturnDefective />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/warehouse":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Warehouse history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/warehouse/inventories":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <Inventories history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/warehouse/sales-out":
        if (
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <SalesOut history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/warehouse/warehouse-purchase-bill":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <WarehousePurchaseBill />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/warehouse/addwarehousesale":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47c4a9618355f53d9449"
        ) {
          return <AddSalesWarehouse />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/updateProfile":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d14565"
        ) {
          return <UpdateProfile />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/deletePurchase":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <DeletePurchase />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/deleteSales":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <DeleteSales />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      // case "/dagaImpex/salesageingReport":
      //   if (
      //     decToken.role === "6227b38275361cd819e16067" ||
      //     decToken.role === "622a47b0d10a0814f49814aa"
      //   ) {
      //     return <SalesAgeingReport/>;
      //   } else {
      //     return (
      //       <Result
      //         status="403"
      //         title="403"
      //         subTitle="Sorry, you are not authorized to access this page."
      //         extra={
      //           <Link to="/dagaImpex">
      //             <Button type="primary">Back</Button>
      //           </Link>
      //         }
      //       />
      //     );
      //   }
      case "/dagaImpex/settleGR":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "629346953e2dcbf2f6d53242"
        ) {
          return <SettleGRAmount />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/openingStock":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <OpeningStock />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/report":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47b0d10a0814f49814aa" ||
          decToken.role === "629346b53e2dcbf2f6d15432" ||
          decToken.role === "629346953e2dcbf2f6d53242" ||
          decToken.role === "629346b53e2dcbf2f6d53244" ||
          decToken.role === "6227b3be2fe1c37b839077b8" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "66e96761812e5a81f544bc96" ||
          decToken.role === "66e966f2b3a3d123c7934fd8"
        ) {
          return <ReportDashBoard />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }

      case "/dagaImpex/sample-barcode":
        if (
          decToken.role === "6227b38275361cd819e16067" ||
          decToken.role === "622a47c4a9618355f53d9449" ||
          decToken.role === "629346b53e2dcbf2f6d53244"
        ) {
          return <SampleBarcode />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/salesBillNew":
        if (decToken.role === "629346b53e2dcbf2f6d43763") {
          return <AddNewSalesBillMobile history={this.props.history} />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/floorGoodsTransfer":
        if (decToken.role === "6227b38275361cd819e16067") {
          console.log("decodeToken", decToken);
          return <FloorGoodsTransfer />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/addLR":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <SalesListLR />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/LRList":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <UploadedLRList />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/stock-verification-details":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <LookUp />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      case "/dagaImpex/bannerUpload":
        if (decToken.role === "6227b38275361cd819e16067") {
          return <UploaBanner />;
        } else {
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          );
        }
      default:
        return (
          console.log("decToken.role", decToken.role),
          (<Dashboard roleId={decToken.role} />)
        );
    }
  }
  render() {
    const { pathname } = this.props.location;
    if (this.state.IsLoggedIn === false) {
      return <Redirect to="/login-register" />;
    }
    const storedToken = localStorage.getItem("token");
    let decToken = "";
    if (storedToken) {
      decToken = jwt_decode(storedToken);
    }
    if (pathname == "/" && decToken?.role == "629346b53e2dcbf2f6d43763") {
      return <Redirect to="/dagaImpex/AddNewSales" />;
    }
    if (
      decToken.role === "622a47e017844801d2c839a4" ||
      decToken.role === "622a47d2a0c03827d4f07294"
    ) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Link to="/">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      );
    }
    return (
      <>
        <MetaTags>
          <title>Daga Impex | Admin</title>
          <meta name="description" content="Admin page of Daga Impex." />
        </MetaTags>
        <Layout className="admin-dash">
          <Sider
            breakpoint="sm"
            collapsedWidth="0"
            theme="light"
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
          >
            <SiderMenu history={this.props.history} user={decToken}></SiderMenu>
          </Sider>
          <Layout className="site-layout">
            {/* <HeaderAdmin
              toggle={this.toggle}
              collapsed={this.state.collapsed}
              user={decToken}
              history={this.props.history}
            /> */}

            {decToken.role === "629346b53e2dcbf2f6d43763" ? (
              <SalesHeaderAdmin
                toggle={this.toggle}
                collapsed={this.state.collapsed}
                user={decToken}
                history={this.props.history}
              />
            ) : (
              <HeaderAdmin
                toggle={this.toggle}
                collapsed={this.state.collapsed}
                user={decToken}
                history={this.props.history}
              />
            )}
            <Content
              style={{
                margin: "20px 10px",
                padding: 15,
                minHeight: 650,
              }}
            >
              {this.renderPathName(pathname, decToken)}
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}
adminDashboard.propTypes = {
  location: PropTypes.object.isRequired,
};
