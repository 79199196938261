import React, { useState } from "react";
import { Row, Modal, Form, Button, Input, Col, Select } from "antd";
import { getDatabydesignandBrandforInventory } from "../../../../redux/actions/purchaseAction";
import { useDispatch, useSelector } from "react-redux";
import { saleBySearchValue } from "../../../../redux/actions/saleAction";
const { Option } = Select;
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];
const CustomerSearch = ({setsearchFrom}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // Initialize with an empty string
  const [searchType, setType] = useState(""); // Initialize with an empty string
  const dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };
  const handleModalCancel = () => {
    setVisible(false);
  };

  // Handle Final Submit
  const onSubmit = () => {
    setsearchFrom("Search")
    setLoading(true);
    let obj = {
      searchValue: searchValue,
      type: searchType,
    };
    dispatch(saleBySearchValue(obj))
      .then((response) => {
        console.log("response", response);
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  // Set Inputs Value
  const onChange = (name, value) => {
    if (name === "serachType") {
      setType(value);
    }
    if (name === "searchValue") {
      setSearchValue(value);
    }
  };

  return (
    <>
      <Button
        className="btn-admin btn-subCategory w-100"
        type="primary"
        onClick={() => showModal()}
      >
        Details Search
      </Button>
      <Row>
        <Col span={24} align="center">
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={() => handleModalCancel()}
              title="Detail Search"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="design and Brand"
                className="add-brand"
                onFinish={onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Search Type!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="custom-select-option"
                    value={searchOptions[0]}
                    style={{ width: "100%" }}
                    onChange={(e) => onChange("serachType", e)}
                    placeholder="Select Search Type"
                  >
                    {searchOptions.map((obj) => {
                      return <Option value={obj}>{obj}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Enter Value"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Something!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Something To Search"
                    name="searchValue"
                    value={searchValue}
                    onChange={(e) => onChange("searchValue", e.target.value)}
                  />
                </Form.Item>
                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={() => handleModalCancel()}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerSearch;
