import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Select, Button, Input, message,Typography } from "antd";
import InventoryList from "./AvailableStockList";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../../redux/actions/brandAction";
import { getCategories } from "../../../redux/actions/categoryAction";
import { getGroups } from "../../../redux/actions/groupAction";
import { getStyles } from "../../../redux/actions/styleAction";
import { getSeasons } from "../../../redux/actions/seasonAction";
import { getSections } from "../../../redux/actions/sectionAction";
import { getSizes } from "../../../redux/actions/sizeAction";
import { getFloors } from "../../../redux/actions/floorAction";
import { getStockReport } from "../../../redux/actions/reportsActions";
import BackButton from "../Report/Button";
const { Option } = Select;
const { Title } = Typography
const AvailableStock = () => {
  const [data, setData] = useState({
    styleName: "",
    styleId: "",
    categoryName: "",
    categoryId: "",
    groupName: [],
    groupId: "",
    seasonName: [],
    seasonId: "",
    sectionName: [],
    sectionId: "",
    brandName: [],
    brandId: "",
    floorName: [],
    floorId: "",
    sizeName: [],
    sizeId: "",
    designNumber: "",
    barcodes: [],
  });

  const dispatch = useDispatch();

  const brands = useSelector((state) => state.brandsData.brands);
  const sections = useSelector((state) => state.sectionData.sections);
  const seasons = useSelector((state) => state.seasonData.seasons);
  const categories = useSelector((state) => state.categoryData.categories);
  const styles = useSelector((state) => state.styleData.styles);
  const sizes = useSelector((state) => state.sizeData.sizes);
  const floors = useSelector((state) => state.floorData.floors);
  const groups = useSelector((state) => state.groupData.groups);
  const stockData = useSelector((state) => state.reportData.stockReport);

  useEffect(()=>{
    console.log("data",data)
  },[data])
  const onChange = (event) => {
    setData({...data, [event.target.name]: event.target.value });
  };

  const handleChangeBrand = (key, value) => {
    let brandName = value.map((obj) => obj.label);
    setData({...data, brandName });
  };

  const handleChangeCategory = (key, value) => {
    setData({...data, categoryId: value.value, categoryName: value.name });
  };

  const handleChangeGroup = (key, value) => {
    let groupName = value.map((obj) => obj.label);
    setData({...data, groupName });
  };

  const handleChangeStyle = (key, value) => {
    setData({...data, styleId: value.value, styleName: value.name });
  };

  const handleChangeSeason = (key, value) => {
    let seasonName = value.map((obj) => obj.label);
    setData({...data, seasonName });
  };

  const handleChangeSection = (key, value) => {
    let sectionName = value.map((obj) => obj.label);
    setData({...data, sectionName });
  };

  const handleChangeSize = (key, value) => {
    let sizeName = value.map((obj) => obj.label);
    setData({...data, sizeName });
  };

  const handleChangeFloor = (key, value) => {
    let floorName = value.map((obj) => obj.label);
    setData({...data, floorName });
  };

  const handleChangeBarcode = (value) => {
    setData({...data, barcodes: value });
  };

  const submitForm = () => {
    let status = true;
    const requestBody = {
      group: data.groupName,
      season: data.seasonName,
      section: data.sectionName,
      Brand: data.brandName,
      floor: data.floorName,
      size: data.sizeName,
      barcode: data.barcodes,
      Category: data.categoryName ? [data.categoryName] : [],
      style: data.styleName ? [data.styleName] : [],
      designNumber: data.designNumber ? [data.designNumber] : [],
      type: "availableStock",
    };
    if (requestBody.barcode && requestBody.barcode.length > 0) {
      for (let index = 0; index < requestBody.barcode.length; index++) {
        if (
          requestBody.barcode[index].length > 9 ||
          requestBody.barcode[index].length < 9
        ) {
          status = false;
        }
      }
    }
    if (status) {
      dispatch(getStockReport(requestBody));
    } else {
      message.error("Barcode must be 9 digit.");
    }
  };

  let brandOptions = brands?.map((brand) => {
    return {
      value: brand.name,
      label: brand.name,
    };
  });

  let categoryOptions = categories.map((category) => {
    return (
      <Option name={category.name} value={category._id}>
        {category.name}
      </Option>
    );
  });
  let groupOptions = groups.map((group, index) => {
    return {
      value: group.name,
      label: group.name,
      key: index,
    };
  });
  let styleOptions = styles.map((style) => {
    return (
      <Option name={style.name} value={style._id}>
        {style.name}
      </Option>
    );
  });
  let seasonOptions = seasons.map((season, index) => {
    return {
      value: season.name,
      label: season.name,
      key: index,
    };
  });
  let sectionOptions = sections.map((section, index) => {
    return {
      value: section.name,
      label: section.name,
      key: index,
    };
  });

  let sizeOptions = sizes.map((size, index) => {
    return {
      value: size.name,
      label: size.name,
      key: index,
    };
  });

  let floorOptions = floors.map((floor, index) => {
    return {
      value: floor.name,
      label: floor.name,
      key: index,
    };
  });
  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Available Stock</div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <BackButton/>
          </Col>
        </Row>
        <div className="card mt-2 final-purchases-list mt-4">
          <div className="card-body">
            <div className="mb-5 mt-2">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form
                    name="stock-report"
                    className="add-Purchase"
                    onFinish={submitForm}
                  >
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Enter Design Number">
                          <Form.Item>
                            <Input
                              placeholder="Enter Design Number"
                              id="designNumber"
                              name="designNumber"
                              value={data.designNumber}
                              onChange={onChange}
                              required
                              maxLength={15}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Category">
                          <Form.Item name="category">
                            <Select
                              // disabled={disabled}
                              name="category"
                              id="category"
                              showSearch
                              placeholder="Select Category"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={handleChangeCategory}
                              onClick={() => {
                                dispatch(getCategories());
                              }}
                            >
                              {categoryOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Style">
                          <Form.Item name="style">
                            <Select
                              name="style"
                              id="style"
                              showSearch
                              placeholder="Select Style"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={handleChangeStyle}
                              onClick={() => {
                                if (data.categoryId != "") {
                                  dispatch(getStyles(data.categoryId));
                                } else {
                                  message.warning("Please Select Category");
                                }
                              }}
                            >
                              {styleOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Group">
                          <Form.Item name="group">
                            <Select
                              name="group"
                              id="group"
                              mode="tags"
                              showSearch
                              placeholder="Select Group"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeGroup}
                              onClick={() => dispatch(getGroups())}
                              options={groupOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Season">
                          <Form.Item name="season">
                            <Select
                              name="season"
                              id="season"
                              mode="tags"
                              showSearch
                              placeholder="Select Season"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeSeason}
                              onClick={() => dispatch(getSeasons())}
                              options={seasonOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Section">
                          <Form.Item name="section">
                            <Select
                              name="section"
                              id="section"
                              mode="tags"
                              showSearch
                              placeholder="Select Section"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeSection}
                              onClick={() => dispatch(getSections())}
                              options={sectionOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Floor">
                          <Form.Item name="floor">
                            <Select
                              name="floor"
                              id="floor"
                              mode="tags"
                              showSearch
                              placeholder="Select Floor"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeFloor}
                              onClick={() => dispatch(getFloors())}
                              options={floorOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Size">
                          <Form.Item>
                            <Select
                              mode="tags"
                              name="size"
                              id="size"
                              showSearch
                              placeholder="Select Size"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeSize}
                              onClick={() => dispatch(getSizes())}
                              options={sizeOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Brand">
                          <Form.Item name="brand">
                            <Select
                              mode="multiple"
                              name="brand"
                              id="brand"
                              showSearch
                              placeholder="Select Brand"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                              onChange={handleChangeBrand}
                              onClick={() => dispatch(getBrands())}
                              options={brandOptions}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Enter Barcode">
                          <Form.Item name="barcode">
                            <Select
                              mode="tags"
                              name="barcode"
                              id="barcode"
                              placeholder="Enter Barcode"
                              optionFilterProp="children"
                              onChange={handleChangeBarcode}
                              options={[]}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          type="submit"
                          className="btn-stock-report-search"
                          block
                          onClick={() => submitForm()}
                        >
                          Search
                        </Button>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          type="primary"
                          onClick={() => window.location.reload(true)}
                          className="close-modal"
                          block
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
            <Row className="mb-4">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={5}>
                    Total Amount:{" "}
                    <span style={{ color: "#52c41a" }}>
                      ₹ {stockData?.data?.totalAmount || 0}
                    </span>
                  </Title>
                </Col>
              </Row>
            <InventoryList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableStock;