import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col, Select } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getInventory, getNegativeInventory } from "../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import { getBrands, getDataByBrandandDesign, clearDataByBrandAndDesign  } from "../../../redux/actions/brandAction.js"
import Search from "../../../util/Search";
import { CSVLink } from "react-csv";
import axios from "axios";

const options = Select

const searchOptions = ["Barcode"]
const { Column } = Table;

class InventoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      selectedBrand: null,
      selectedId: null,
      designNumber: "",
      searchValue: "",
      // testDat:[]
    };
  }
  componentDidMount() {
    this.props.getInventory(1);
    this.props.getNegativeInventory()
    this.props.getBrands()
    // this.getTestData()
  }

  //   getTestData = async()=>{
  //     console.log("check function work")
  // let data = await axios.get("http://34.207.65.154:5000/api/v1/getlatestInventoryReport")
  // this.setState({testDat :data })
  //   }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  render() {
    const brands = this.props?.brands
    const getDataByBrandandDesignData = this.props?.getDataByBrandandDesignData
    const { searchValue } = this.state;
    const { inventory, inventoryBysearch } = this.props
    const search = inventoryBysearch.inventoryDetails || []
    console.log("this.props.inventory", inventory);
    const headers = [
      { label: "Purchase Id", key: "purchaseId" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Bill No", key: "billNo" },
      { label: "MOQ", key: "MOQ" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "WSP", key: "wsp" },
    ];

    return (
      <div>
        {/* {console.log("check negative", this.state.testDat)} */}
        <Row gutter={24} className="mt-4">
          <Col sm={12} md={12} lg={12}>
            <Search title="Inventory" placeholder="Barcode" searchopt={searchOptions} cancelState={this.cancelState} changeFunction={this.onChange} />
          </Col>
          <Col sm={12} md={12} lg={12}>

              <Row gutter={24} className="">
                <Col sm={24} md={8} lg={8} className="inventry-inputs">
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select a Brand"
                    showSearch
                    optionFilterProp="label"
                    value={this.state.selectedBrand?.value || null} // Bind value to state
                    onChange={(value, option) => this.setState({ selectedBrand: option, selectedId: value })}
                  >
                    {brands?.map((brand) => (
                      <Select.Option key={brand._id} value={brand._id} label={brand.name}>
                        {brand.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col sm={24} md={8} lg={8} className="inventry-inputs">
                  <Input
                    placeholder="Enter Design Number"
                    value={this.state.designNumber || ''}
                    onChange={(e) => this.setState({ designNumber: e.target.value })}
                  />
                </Col>

                <Col sm={12} md={4} lg={4}>
                  <Button
                    type="primary"
                    onClick={() => {
                      const { selectedBrand, designNumber, selectedId } = this.state;
                      const brand = selectedBrand?.label;
                      const brandId = selectedId;

                      // Log search params and call the data-fetching function
                      console.log('Search Params:', { brand, brandId, designNumber });
                      this.props.getDataByBrandandDesign({ brand, designNumber });
                    }}
                  >
                    Search
                  </Button>
                </Col>

                <Col sm={12} md={4} lg={4}
                  className="px-0"
                >
                  <Button
                    type="default"
                    style={{}}

                    onClick={() => {
                      // Reset form fields
                      this.setState({ selectedBrand: null, selectedId: null, designNumber: '' });
                      this.props.clearDataByBrandAndDesign();
                    }}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>  
          </Col>
        </Row>
        <div className="card mt-4 final-purchases-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="card-body">
            <Table
              dataSource={getDataByBrandandDesignData && getDataByBrandandDesignData.length ? getDataByBrandandDesignData : searchValue ? search : inventory && inventory.inventoryDetails ? inventory.inventoryDetails : []}
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 100,
                total: getDataByBrandandDesignData && getDataByBrandandDesignData.length ? getDataByBrandandDesignData : searchValue && search.length > 0 ? search.length : inventory?.totalCount,
                onChange: (page) => {
                  this.props.getInventory(page);
                },
              }}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={150}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                {...this.getColumnSearchProps("brand")}
                width={120}
              />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                {...this.getColumnSearchProps("category")}
                width={120}
              />
              <Column
                title="Style"
                dataIndex="style"
                key="style"
                {...this.getColumnSearchProps("style")}
                width={120}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={80}
                {...this.getColumnSearchProps("color")}
              />
              <Column
                title="MOQ"
                dataIndex="MOQ"
                key="MOQ"
                width={80}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              <Column
                title="Stock"
                dataIndex="stock"
                key="stock"
                width={90}
                render={(stock) => (
                  <>
                    {(() => {
                      if (stock <= 15) {
                        return (
                          <Tag color="volcano" key={stock}>
                            {stock}
                          </Tag>
                        );
                      } else {
                        return stock;
                      }
                    })()}
                  </>
                )}
              />
            </Table>
            {(() => {
              if (this.props?.negativeInventory?.data?.length > 0) {
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="save-modal mt-4"
                    block
                  >
                    <CSVLink
                      filename={`NegativeInventoryList.csv`}
                      data={this.props.negativeInventory.data}
                      headers={headers}
                    >
                      Download Negative Inventory List
                    </CSVLink>
                  </Button>
                );
              }
            })()}
          </div>
        </div>
      </div >
    );
  }
}

InventoryList.propTypes = {
  getInventory: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getNegativeInventory: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brandsData.brands,
  getDataByBrandandDesignData: state.brandsData?.ItemsByBrandAndDesign?.inventoryDetails,
  errors: state.errors,
  inventory: state.purchaseData.inventory,
  inventoryBysearch: state.purchaseData.inventoryBysearch,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  negativeInventory: state.purchaseData.negativeInventory
});

export default connect(mapStateToProps, { getInventory, getNegativeInventory, getBrands, getDataByBrandandDesign, clearDataByBrandAndDesign  })(InventoryList);