import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../../redux/actions/brandAction";
import BarcodeReader from "react-barcode-reader";
import LookUpList from "./LookUpList";

const { Option } = Select;

const LookUp = () => {

  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="page-header">Stock Verification Details</div>
          </Col>
        </Row>
        <LookUpList/>
      </div>
    </div>
  );
};

export default LookUp;
