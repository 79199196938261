import React, { Component } from "react";
import { Modal, Table, Button, Space, Input, Checkbox, Row, Col, message, Typography, Spin } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLookupData } from "../../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ArrowLeftOutlined} from "@ant-design/icons";

const { Column } = Table;

class LookupModal extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      productAddedClicked: false,
      selectedProducts: [],
      finalSubmitStatus: false
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps.visible != prevState.visible) {
      if (nextProps.visible) {
        let lookupObj = {
          designNumber: nextProps.designNumber,
          brandName: nextProps.lookupBrandName,
        };
        this.props.getLookupData(lookupObj);
      }

      this.setState({ visible: nextProps.visible });
    }

    return true;
  }
  fillDetails = (text) => {
    this.props.closeLookup(text);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };


  onProductSelect = (e, data) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const existingProduct = this.state.selectedProducts.find(obj => obj._id === data._id);

      if (!existingProduct) {
        this.setState({
          selectedProducts: [...this.state.selectedProducts, { ...data, newQty: 0 }]
        });
      }
    } else {
      this.setState({
        selectedProducts: this.state.selectedProducts.filter(obj => obj._id !== data._id)
      });
    }
  }

  modalCancel = () => {
    this.setState({ productAddedClicked: false, selectedProducts: [], finalSubmitStatus: false })
    this.props.closeLookup()
  }

  nextStep = () => {
    if (this.state.selectedProducts.length > 0) {
      this.setState({ productAddedClicked: true })
    } else {
      message.error("Please Select Product!")
    }
  }


  handleBack=()=>{
    this.setState({productAddedClicked:false ,
      //  selectedProducts : []
      })
  }

  handleQtyChange = (e, data) => {
    const { value } = e.target;
    const { _id } = data;
    const updatedSelectedProducts = this.state.selectedProducts.map(product => {
      if (product._id === _id) {
        return { ...product, newQty: value };
      }
      return product;
    });
    this.setState({ selectedProducts: updatedSelectedProducts });
  }

  handleCheckBlankandDivisibleQty = () => {
    const { selectedProducts } = this.state;
    if (!this.props.data.billNo) {
      message.error("Please Enter Bill No!")
      return false;
    }
    return !selectedProducts.some(product => {
      if (product.newQty <= 0) {
        return true
      }
      if (product.newQty % product.MOQ !== 0) {
        return true
      }
      return false;
    });
  }

  finalSubmit = async () => {
    this.setState({ finalSubmitStatus: true })
    setTimeout(async () => {
      let checkFinalSubmit = this.handleCheckBlankandDivisibleQty()
      if (checkFinalSubmit) {
        for (let index = 0; index < this.state.selectedProducts.length; index++) {
          let response = await this.props.closeLookup({ ...this.state.selectedProducts[index], quantity: this.state.selectedProducts[index].newQty });
          if (response) {
            await this.props.onChange({ target: { name: "quantity", value: Number(this.state.selectedProducts[index].newQty || 0) } }, 0, 0)
            await this.props.showDataOnList()
          }
        }
        this.setState({ finalSubmitStatus: false, productAddedClicked: false, selectedProducts: [] })
      } else {
        this.setState({ finalSubmitStatus: false })
        message.error("Please check the quantity of the product!")
      }
    }, 500);
  }

  handleChangeBill = (e) => {
    this.props.onChange(e, 0, 0)
  }

  render() {
    return (
      <div>
        <Modal
          visible={this.props.visible}
          onOk={this.handleOk}
          onCancel={() => this.state.finalSubmitStatus ? false : this.modalCancel()}
          title="Lookup"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
          maskClosable={false}
        >
          <Row className="mb-4">
            <Col span={2} align="center">
              <Typography.Title level={5}>Bill No</Typography.Title>
            </Col>
            <Col span={8} align="center">
              <Input placeholder="Enter Your Bill Number"
                defaultValue={this.props.data.billNo}
                name="billNo" onChange={(e) => this.handleChangeBill(e)}
              />
            </Col>
            <Col span={14} align="end">
              <Button 
              type="primary"
              // className="save-modal text-white"
              onClick={()=>{
                this.handleBack()
              }}>
                <ArrowLeftOutlined /> Back</Button>
            </Col>
          </Row>
          {!this.state.productAddedClicked ? <>
            <Table
              dataSource={this.props.purchases}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
              className="mb-2"
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />

              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={110}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={140}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                width={120}
                {...this.getColumnSearchProps("brand")}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column title="Color" dataIndex="color" key="color" width={80} />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
              <Column
                title="Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={90}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              {/* <Column
              title="Action"
              key="action"
              width={90}
              render={(text, record) => (
                <Button type="primary" onClick={() => this.fillDetails(text)}>
                  Add
                </Button>
              )}
            /> */}
              <Column
                title="Select"
                key="action"
                width={90}
                render={(text, record) => (
                  <Checkbox
                    onChange={(e) => this.onProductSelect(e, record)}
                    checked={this.state.selectedProducts.some(obj => obj._id === record._id)}
                  />
                )}
              />
            </Table>
            <Row className="mt-4">
              <Col span={24} align="center">
                <Button type="primary" onClick={() => this.nextStep()}>
                  Add
                </Button>
              </Col>
            </Row>
          </> : <>
            <Table
              dataSource={this.state.selectedProducts || []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
              className="mb-2"
              rowClassName={(record, index) => {
                if (record.newQty % record.MOQ != 0) {
                  return "table-row-dark"
                }
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />

              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={110}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={140}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                width={120}
                {...this.getColumnSearchProps("brand")}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column title="Color" dataIndex="color" key="color" width={80} />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
              <Column
                title="Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={90}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              <Column
                title="Qty"
                key="action"
                width={90}
                render={(text, record) => (
                  <Input placeholder="Add Qty" value={text.newQty} type="number" onChange={(e) => this.handleQtyChange(e, text)} />
                )}
              />
            </Table>
            <Row className="mt-4">
              <Col span={24} align="center">
                {this.state.finalSubmitStatus === true ? <Spin /> :
                  <Button type="primary" onClick={() => this.finalSubmit()} loading={this.state.finalSubmitStatus}>
                    Add Products To List
                  </Button>
                }
              </Col>
            </Row>
          </>}

        </Modal>
      </div>
    );
  }
}
LookupModal.propTypes = {
  getLookupData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  purchases: state.purchaseData.purchases,
  errors: state.errors,
});

export default connect(mapStateToProps, { getLookupData })(LookupModal);
