/*
Date              created by               Modifications 
17-05-2024        Ayush                    Create the Page to Show the Product that according to EcomSaleID 

*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsBySalesReturnId } from "../../../../redux/actions/ecomSaleAction";
import { Table, Modal, message, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import gifLoader from "../../../../assets/images/giphy.gif";
const { Column } = Table;

const ViewEcomSalesReturnProduct = (ecomSaleReturnsId) => {
  const dispatch = useDispatch();
  const ecomSaleReturnData = useSelector((state) => state.ecomSaleData.ecomSalesRetutnProductById);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  // to call the api to get the Ecom Sale Data based on EcomSale Id and to Open the Modal
  const showModal = () => {
    dispatch(getProductsBySalesReturnId(ecomSaleReturnsId));
    setVisible(true);
  };

  // to Close the Modal when User click on Close or Cross button call the handleModalCancel function and also Reset the State
  const handleModalCancel = () => {
    dispatch(getProductsBySalesReturnId("reset"));
    setVisible(false);
  };

  return (
    <div>
      <button className="btn" type="button" onClick={showModal}>
        <Tooltip title="View Sales Products">
          <EyeOutlined />
        </Tooltip>
      </button>
      <Modal
        open={
          ecomSaleReturnData && ecomSaleReturnData.data && ecomSaleReturnData.data.length > 0
            ? visible
            : false
        }
        footer={null}
        onCancel={handleModalCancel}
        title="View Sales Return Products"
        style={{ top: 20 }}
        destroyOnClose={true}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={1500}
      >
        {console.log("check my value", ecomSaleReturnData)}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={gifLoader}
              width={"300px"}
              height={"300px"}
              alt="Loading..."
            />
          </div>
        ) : (
          <Table
            dataSource={
              ecomSaleReturnData && ecomSaleReturnData.data && ecomSaleReturnData.data.length > 0
                ? ecomSaleReturnData.data
                : []
            }
            bordered
            size="middle"
            pagination={false}
            scroll={{ y: 240 }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Barcode"
              dataIndex="barcode"
              key="barcode"
              width={120}
              // render={(value, item, index) => item.barcode}
            />
            <Column
              title="Design Number"
              dataIndex="designNumber"
              key="designNumber"
              // render={(value, item, index) => item.designNumber}
              width={180}
            />

            <Column
              title="Brand"
              dataIndex="brand"
              key="brand"
              width={100}
              // render={(value, item, index) => item.brand}
            />
            <Column
              title="Size"
              dataIndex="size"
              key="size"
              // render={(value, item, index) => item.size}
              width={100}
            />
            <Column
              title="Color"
              dataIndex="color"
              key="color"
              width={100}
              // render={(value, item, index) => item.color}
            />
            <Column
              title="Quantity"
              dataIndex="quantity"
              key="quantity"
              // render={(value, item, index) => item.quantity}
              width={180}
            />
            <Column
              title="Price"
              dataIndex="price"
              key="price"
              // render={(value, item, index) => {
              //   let finalPrice =
              //     Number(item?.wsp) -
              //     Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
              //   return finalPrice.toFixed();
              // }}
              width={180}
            />
          </Table>
        )}
      </Modal>
    </div>
  );
};

export default ViewEcomSalesReturnProduct;
