import React from "react";
import {
  Table,
  Tag,
  Row,
  Col,
  Modal,
  Select,
  Input,
  Button,
  Tooltip,
  DatePicker,
  Form,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEcomSalesReturnBillList,
  updateOrderStatus,
  creditPaymentBySearchValue,
  rejectSalesReturn,
} from "../../../../redux/actions/ecomSaleAction";
import moment from "moment";
import ViewEcomSalesReturnProduct from "./ViewEcomSalesReturnProduct";
// import ShowCreditEdit from "./ShowCreditEdit";
// import MergeBillModal from "./Merge Bill Ecom/MergeBillEcom";
// import EcomCreditQuantityEdit from "./EcomCreditQuantityEdit";
// import DownloadSalePDF from "../sales/DownloadSalePDF";
import { message } from "antd";
import {
  FileImageOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import Swal from "sweetalert2";
import EditAndAcceptEcomReturnProduct from "./EditAndAcceptEcomReturnProduct";
const { Column } = Table;
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];

const dateFormat = "YYYY-MM-DD";

const EcomSalesReturnList = () => {
  const dispatch = useDispatch();
  const ecomData = useSelector(
    (state) => state.ecomSaleData.ecomSalesReturnBillList
  );
  const [imageModel, setimageModel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  //   const handlePaginationChange = (page) => {
  //     setPagination({ current: page });
  //   };

  //   const onStatusChange = (e, value) => {
  //     if (value == true) {
  //       Swal.fire({
  //         title: "Warning",
  //         text: "If Order is delivered then it cannot be changed.",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes",
  //       }).then(() => {
  //         dispatch(updateOrderStatus({ ecomsalesId: e.ecomsalesId }));

  //         let billListObj = {
  //           billType: data.billType,
  //           limit: pagination.current,
  //           perPage: 10,
  //         };
  //         dispatch(getEcomSalesReturnBillList(billListObj));
  //       });
  //     }
  //   };

  // Emppty the redux when component close
  useEffect(() => {
    return () => {
      dispatch(getEcomSalesReturnBillList("reset"));
    };
  }, []);

  // Start Date Change
  const onStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  // End Date Change
  const onEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  // Initial Load Api Call
  useEffect(() => {
    let today = moment(new Date()).format("YYYY-MM-DD");
    let reportObj;
    if (today == endDate) {
      reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
    } else {
      const dateMoment = moment(endDate, "YYYY-MM-DD");
      const endTime = dateMoment.endOf("day").format("HH:mm:ss");
      const endDateWithEndTime = `${endDate} ${endTime}`;
      reportObj = {
        startDate: startDate,
        endDate: endDateWithEndTime,
      };
    }
    dispatch(getEcomSalesReturnBillList(reportObj)).then((obj) => {
      console.log("check mohit obj", obj);
    });
  }, []);

  // Reset Function
  const resetFunction = async () => {
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    handleDateRangeSearch();
  };

  // Handle Date Wise Search
  const handleDateRangeSearch = () => {
    if (startDate && endDate) {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let reportObj;
      if (today == endDate) {
        reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
      } else {
        const dateMoment = moment(endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${endDate} ${endTime}`;
        reportObj = {
          startDate: startDate,
          endDate: endDateWithEndTime,
        };
      }
      dispatch(getEcomSalesReturnBillList(reportObj)).then((obj) => {
        console.log("check mohit obj", obj);
      });
    } else {
      message.error("Please Select Start & End Date!");
    }
  };

  const handleReject = async (salesReturnId) => {
    let obj = { ecomSalesReturnId: salesReturnId, status: "Rejected" };
    dispatch(rejectSalesReturn(obj)).then((response) => {
      if (response && response.status == 200) {
        message.success(response?.data?.message);
        handleDateRangeSearch();
      }
    });
  };

  return (
    <>
      {/* <Row className="title-row">
       
        <Col sm={12} md={12} lg={12} className="w-50" align="end"></Col>
      </Row> */}

      <Row gutter={24} className="mt-3">
        <Col sm={12} md={12} lg={12} className="w-50">
          <div className="page-header">E-COM Sales Return</div>
        </Col>

        <Col sm={12} md={12} lg={12} align="end" className="mt-2">
          <Row gutter={24}>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Tooltip title="Select Start Date">
                <Form.Item>
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onStartDateChange}
                    placeholder="Select Start Date"
                    value={moment(startDate, dateFormat)}
                    format={dateFormat}
                    showClose={false}
                    name="startDate"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Tooltip title="Select End Date">
                <Form.Item>
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onEndDateChange}
                    placeholder="Select End Date"
                    value={moment(endDate, dateFormat)}
                    format={dateFormat}
                    name="endDate"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="close-modal"
                  block
                  onClick={handleDateRangeSearch}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Form.Item>
                <Button type="primary" block onClick={resetFunction}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="card mt-2 final-purchases-list">
        <div className="card-body">
          <Table
            dataSource={
              ecomData && ecomData.data && ecomData.data.length > 0
                ? ecomData.data
                : []
            }
            bordered="true"
            size="middle"
            scroll={{ y: 300 }}
            // pagination={{
            //     pageSize: 10,
            //     total: ecomData ? ecomData?.count : 1,
            //     onChange: (page) => {
            //         console.log("page", page);
            //         handlePaginationChange(page)
            //     }
            // }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Created Date"
              dataIndex="date"
              key="date"
              render={(value, item, index) =>
                moment(item.date).format("DD-MM-YYYY HH:mm:ss")
              }
              width={180}
            />
            <Column
              title="E-com Return Id"
              dataIndex="ecomSalesReturnId"
              key="ecomSalesReturnId"
              width={150}
              //   render={(value, item, index) => item.ecomsalesId}
            />
            <Column
              title="Store User Name"
              dataIndex="userId"
              key="userId"
              render={(value, item, index) => item.userId?.name}
              width={180}
            />

            <Column
              title="Net Amount"
              dataIndex="netAmount"
              key="netAmount"
              width={150}
              //   render={(value, item, index) => Number(item.netAmount).toFixed()}
            />

            <Column
              title="Total Amount"
              dataIndex="totalAmount"
              key="totalAmount"
              //   render={(value, item, index) =>
              //     Number(item.totalAmount).toFixed()
              //   }
              width={150}
            />
            <Column
              title="Total Quantity"
              dataIndex="totalQuantity"
              key="totalQuantity"
              width={150}
              //   render={(value, item, index) => item.totalQuantity}
            />
            <Column
              title="Total Boxes"
              dataIndex="totalBoxes"
              key="totalBoxes"
              width={150}
              //   render={(value, item, index) => item.totalBoxes}
            />
            <Column
              title="Reason"
              dataIndex="reason"
              key="reason"
              width={100}
              //   render={(value, item, index) => item.totalBoxes}
            />
            <Column
              title="Remark"
              dataIndex="remark"
              key="remark"
              width={100}
              //   render={(value, item, index) => item.totalBoxes}
            />
            <Column
              title="Action"
              key="action"
              width={350}
              fixed="right"
              render={(text, record) => (
                <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                  <Col sm={8} md={8} lg={8}>
                    <ViewEcomSalesReturnProduct
                      ecomSalesReturnId={text.ecomSalesReturnId}
                    />
                  </Col>
                  <Col sm={8} md={8} lg={8}>
                    <EditAndAcceptEcomReturnProduct
                      ecomSalesReturnId={text.ecomSalesReturnId}
                      ecomsalesId={text.ecomSalesId}
                    />
                  </Col>
                  <Col sm={8} md={8} lg={8}>
                    <Popconfirm
                      title="Reject Sales Return Request!"
                      onConfirm={() => handleReject(text.ecomSalesReturnId)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" className="close-modal">
                        Reject
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              )}
            />
            <Column
              title="Status"
              key="status"
              width={110}
              fixed="right"
              render={(text, record) => (
                <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                  <Col sm={24} md={24} lg={24}>
                    {text?.approveStatus === "Rejected" && (
                      <Tag color="red">{text.approveStatus}</Tag>
                    )}
                    {text?.approveStatus === "Pending" && (
                      <Tag color="orange">{text.approveStatus}</Tag>
                    )}
                    {text?.approveStatus === "Accepted" && (
                      <Tag color="green">{text.approveStatus}</Tag>
                    )}
                  </Col>
                </Row>
              )}
            />
            <Column
              title="Image"
              key="images"
              fixed="right"
              width={150}
              render={(text, record) => {
                return (
                  <>
                    <FileImageOutlined
                      onClick={() => setimageModel(true)}
                      className="brand-image-show-icon cursor-pointer"
                    />
                    <Modal
                      visible={imageModel}
                      title={"Ecom Return Received Image"}
                      footer={null}
                      onCancel={() => setimageModel(false)}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={text?.images?.[0] || ""}
                      />
                    </Modal>
                  </>
                );
              }}
            />
          </Table>{" "}
        </div>
      </div>
    </>
  );
};

export default EcomSalesReturnList;
