import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Table, message, Card } from "antd";
import BarcodeReader from "react-barcode-reader";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { stockDetailsByBarcode } from "../../../redux/actions/purchaseAction";

const { Column } = Table;

const LookUpList = () => {
  const [barcode, setBarcode] = useState(null);
  const [scannedQuant, setScannedQuant] = useState(0);
  const [lastScannedBarcode, setLastScannedBarcode] = useState(null);
  const dispatch = useDispatch();

  const barcodeDetails = useSelector(
    (state) => state.purchaseData.stockDetailsByBarcode
  );

  const handleBarcodeChange = (e) => {
    const inputBarcode = e.target.value;
    setBarcode(inputBarcode);
  };

  const handleSearch = async (value) => {
    if (value.length !== 9) {
      message.error("Invalid Barcode!");
    } else {
      const res = await dispatch(stockDetailsByBarcode({ barcode: value }));
      if (res && res.data) {
        const currentMOQ = res?.data[0]?.MOQ || 0;

        if (lastScannedBarcode === value) {
          setScannedQuant(scannedQuant + currentMOQ);
        } else {
          setScannedQuant(currentMOQ);
        }
      }
      setLastScannedBarcode(value);
    }
  };

  const handleReset = () => {
    dispatch(stockDetailsByBarcode("reset"));
    setScannedQuant(0);
    setBarcode(null);
    setLastScannedBarcode(null);
  };

  const getCardStyle = (scanned, stock) => {
    if (scanned < stock) {
      return {
        background: "linear-gradient(135deg, #fff176 10%, #ffe082 100%)", // Light Yellow gradient
        color: "#000"
      };
    }
    if (scanned > stock) {
      return {
        background: "linear-gradient(135deg, #ffccbc 10%, #ef5350 100%)", // Light Red gradient
        color: "#000"
      };
    }
    return {
      background: "linear-gradient(135deg, #a5d6a7 10%, #4caf50 100%)", // Light Green gradient
      color: "#000"
    };
  };

  return (
    <div>
      <Form>
        <Row span={32} className="mt-4">
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <Form.Item>
              <BarcodeReader
                onScan={(value) => {
                  handleSearch(value);
                }}
                onError={(err) => console.error("Barcode scan error:", err)}
              />
              <Input
                name="barcode"
                placeholder="Enter Barcode or Scan"
                value={barcode}
                onChange={handleBarcodeChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            <Button
              type="primary"
              className="search-button"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(barcode)}
              style={{ marginLeft: "5px" }}
            />
          </Col>
          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            <Button
              type="primary"
              className="reset-button"
              icon={<CloseOutlined />}
              onClick={handleReset}
            />
          </Col>
        </Row>
      </Form>

      <div className="card mt-4 final-purchases-list">
        <div className="card-body">
          <Table
            dataSource={
              barcodeDetails?.data?.length > 0 ? barcodeDetails?.data : []
            }
            bordered
            size="middle"
            pagination={false}
          >
            <Column
              title="Design Number"
              dataIndex="designNumber"
              key="designNumber"
            />
            <Column title="Brand" dataIndex="brand" key="brand" />
            <Column title="Barcode" dataIndex="barcode" key="barcode" />
            <Column title="Stock" dataIndex="stock" key="stock" />
            <Column title="Total Sold" dataIndex="totalSold" key="totalSold" />
          </Table>
        </div>
      </div>

      {barcodeDetails?.data?.length > 0 && (
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Card
              className="mt-4"
              title="Current Stock"
              bordered
              align="center"
              style={getCardStyle(
                scannedQuant,
                barcodeDetails?.data[0]?.stock
              )}
            >
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {barcodeDetails?.data[0]?.stock}
              </div>
            </Card>
          </Col>

          <Col sm={24} md={12}>
            <Card
              className="mt-4"
              title="Quantity As Per Scan"
              bordered
              align="center"
              style={getCardStyle(
                scannedQuant,
                barcodeDetails?.data[0]?.stock
              )}
            >
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {scannedQuant}
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LookUpList;