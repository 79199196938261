import React, { useState } from "react";
import { Modal, Tooltip, Button, Space } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSelector } from "react-redux";

const PreviewBanner = ({ images }) => {
  const [visible, setVisible] = useState(false);
  const [modalSize, setModalSize] = useState("web");
  const bannersList = useSelector((state) => state.purchaseData.bannerList?.data);

  const showModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const handleSizeChange = (size) => {
    setModalSize(size);
  };

  return (
    <>
      <Button className="close-modal" onClick={showModal}>
        <Tooltip title="Preview">Preview</Tooltip>
      </Button>
      <Modal
        open={visible}
        onCancel={handleModalCancel}
        title="Preview"
        style={{ top: 20 }}
        width={modalSize === "web" ? 1500 : 400}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Space style={{ marginBottom: "10px", justifyContent: "center", display: "flex" }}>
          <Button onClick={() => handleSizeChange("web")} type={modalSize === "web" ? "primary" : "default"}>
            For Web
          </Button>
          <Button onClick={() => handleSizeChange("mobile")} type={modalSize === "mobile" ? "primary" : "default"}>
            For Mobile
          </Button>
        </Space>
        <div className="carousel-container">
          <Carousel
            autoPlay
            infiniteLoop
            interval={1500}
          >
            {
              modalSize === "web"
                ?
                bannersList?.oldImages?.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} />
                  </div>
                ))
                :
                bannersList?.mobileBannerImages?.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} />
                  </div>
                ))
            }
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

export default PreviewBanner;
