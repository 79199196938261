import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Upload,
  Tooltip,
  message,
  Switch,
  Tag,
} from "antd";
import {
  PlusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  addProductImage,
  addSingleGlobalEcomImage,
  addMultipleGlobalEcomImage,
} from "../../../redux/actions/purchaseAction";
import {
  getUniquePurchases,
  addEcomSale,
} from "../../../redux/actions/purchaseAction";
import {
  getBrandImages,
  deleteProductImage,
} from "../../../redux/actions/brandAction";
import noImage from "../../../assets/images/noImage.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AddGlobalImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinStatus0: false,
      spinStatus1: false,
      spinStatus2: false,
      spinStatus3: false,
      error0: false,
      error1: false,
      error2: false,
      error3: false,
      loading: false,
      visible: false,
      name: "",
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      image: "",
      errors: {},
      urlPath: [
        { name: "fileList1", filePath: "" },
        { name: "fileList2", filePath: "" },
        { name: "fileList3", filePath: "" },
        { name: "fileList4", filePath: "" },
      ],
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    // this.props.getBrandImages({ barcode: this.props.data.barcode })
    this.setState({
      visible: true,
    });
  };

  submitSingleImage = async (name, fileList) => {
    let temp = this.state.urlPath;
    if (name === "fileList1") {
      this.setState({ spinStatus0: true });
      try {
        // console.log("check insid", this.state.fileList1)
        let response = await this.props.addSingleGlobalEcomImage(
          fileList[0].originFileObj
        );
      

        const index = this.state.urlPath.findIndex(
          (item) => item.name === "fileList1"
        );
        const updatedObject = {
          name: "fileList1",
          filePath: response.data.filePath,
        };
        if (index !== -1) {
          temp[index] = updatedObject;
          this.setState({ urlPath: temp, spinStatus0: false });
        }
      } catch (error) {
        this.setState({ spinStatus0: false });
        // Handle error during upload
        this.setState({
          error0: error.message,
        });
      }
    } else if (name === "fileList2") {
      this.setState({ spinStatus1: true });
      try {
        let response = await this.props.addSingleGlobalEcomImage(
          fileList[0].originFileObj
        );
        const index = this.state.urlPath.findIndex(
          (item) => item.name === "fileList2"
        );
        const updatedObject = {
          name: "fileList2",
          filePath: response.data.filePath,
        };
        if (index !== -1) {
          temp[index] = updatedObject;
          this.setState({ urlPath: temp, spinStatus1: false });
        }
      } catch (error) {
        this.setState({ spinStatus1: false });
        // Handle error during upload
        console.error("Error uploading fileList2:", error);
        this.setState({
          error1: error.message,
        });
      }
    } else if (name === "fileList3") {
      this.setState({ spinStatus2: true });
      try {
        let response = await this.props.addSingleGlobalEcomImage(
          fileList[0].originFileObj
        );
        const index = this.state.urlPath.findIndex(
          (item) => item.name === "fileList3"
        );
        const updatedObject = {
          name: "fileList3",
          filePath: response.data.filePath,
        };
        if (index !== -1) {
          temp[index] = updatedObject;
          this.setState({ urlPath: temp, spinStatus2: false });
        }
      } catch (error) {
        this.setState({ spinStatus2: false });
        // Handle error during upload
        console.error("Error uploading fileList3:", error);
        this.setState({
          error2: error.message,
        });
      }
    } else if (name === "fileList4") {
      this.setState({ spinStatus3: true });
      try {
        let response = await this.props.addSingleGlobalEcomImage(
          fileList[0].originFileObj
        );
        const index = this.state.urlPath.findIndex(
          (item) => item.name === "fileList4"
        );
        const updatedObject = {
          name: "fileList4",
          filePath: response.data.filePath,
        };
        if (index !== -1) {
          temp[index] = updatedObject;
          this.setState({ urlPath: temp, spinStatus3: false });
        }
      } catch (error) {
        this.setState({ spinStatus3: false });
        // Handle error during upload
        console.error("Error uploading fileList4:", error);
        this.setState({
          error3: error.message,
        });
      }
    }
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
    this.setState({
      spinStatus0: false,
      spinStatus1: false,
      spinStatus2: false,
      spinStatus3: false,
      loading: false,
      visible: false,
      name: "",
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      image: "",
      errors: {},
      urlPath: [
        { name: "fileList1", filePath: "" },
        { name: "fileList2", filePath: "" },
        { name: "fileList3", filePath: "" },
        { name: "fileList4", filePath: "" },
      ],
      selectedColor: []
    });
  };

  componentDidMount = () => { };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here

      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDefaultPreview = (url) => {
    this.setState({
      previewImage: url,
      previewVisible: true,
      previewTitle: this.props.data.name,
    });
  };

  handleDelete = async (url, index) => {
    if (index == 0) {
      this.setState({ spinStatus0: true });
    } else if (index == 1) {
      this.setState({ spinStatus1: true });
    } else if (index == 2) {
      this.setState({ spinStatus2: true });
    } else if (index == 3) {
      this.setState({ spinStatus3: true });
    } else {
    }
    let response = await this.props.deleteProductImage({ imageName: url });
    if (response.status == 200) {
      this.setState({
        visible: false,
        spinStatus0: false,
        spinStatus1: false,
        spinStatus2: false,
        spinStatus3: false,
      });
    }
  };

  onSubmit = async (event) => {
    this.setState({ loading: true });
    let filepath = this.state.urlPath.map((obj) => obj.filePath);
    let barcodeArray =
      this.props.barcodeData &&
      this.props.barcodeData.length > 0 &&
      this.props.barcodeData[0].map((obj) => obj.barcode);
    let requestBody = {
      imagePaths: filepath,
      barcodes: barcodeArray,
    };

    if (requestBody.imagePaths[0]) {
      await this.props.addMultipleGlobalEcomImage(requestBody);
      this.props.onImageUploadStatusChange()
      this.handleModalCancel()
      this.setState({ loading: false, visible: false });
    } else {
      this.setState({ loading: false });
      message.warn("Kindly Choose Images!")
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file, fileListName) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }, fileListName) => {
    console.log("check my changes", fileListName, fileList )
    // Update state for each fileList
    if (fileListName === "fileList1") {
      this.setState({ fileList1: fileList });
      this.submitSingleImage("fileList1", fileList);
      // this.props.addSingleGlobalEcomImage(fileList[0].originFileObj)
    } else if (fileListName === "fileList2") {
      this.setState({ fileList2: fileList });
      this.submitSingleImage("fileList2", fileList);
    } else if (fileListName === "fileList3") {
      this.setState({ fileList3: fileList });
      this.submitSingleImage("fileList3", fileList);
    } else if (fileListName === "fileList4") {
      this.setState({ fileList4: fileList });
      this.submitSingleImage("fileList4", fileList);
    }
  };

  beforeUpload = (file, fileList) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false; // Prevent upload
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must be smaller than 2MB!");
        return false; // Prevent upload
    }

    // Create a new FileReader to read the image file
    const reader = new FileReader();
    reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
            // Check the dimensions of the image
            if (img.width !== 275 || img.height !== 325) {
                message.error("Image must be 275px by 325px!");
                if(fileList === "fileList1"){
                    this.setState({fileList1 : []})
                }
                if(fileList === "fileList2"){
                    this.setState({fileList2 : []})
                }
                if(fileList === "fileList3"){
                    this.setState({fileList3 : []})
                }
                if(fileList === "fileList4"){
                    this.setState({fileList4 : []})
                }
                return false; // Prevent upload
            }
            // If all checks pass, you can proceed with the upload
            // You may want to call a function to handle the upload here
        };
    };
    reader.readAsDataURL(file);

    // Return false to prevent the upload until the image dimensions are checked
    return false;
};

  onSaleChange(barcode, text) {
    const newStatus = text.sales;
    if (text.sales == "ON") {
      let saleStatusChange = {
        salesDiscount: 0,
        barcode,
        status: "OFF",
      };

      this.props.addEcomSale(saleStatusChange);
      this.props.getUniquePurchases(1);
    } else {
      let saleStatusChange = {
        salesDiscount: 0,
        barcode,
        status: "ON",
      };

      this.props.addEcomSale(saleStatusChange);
      this.props.getUniquePurchases(1);
    }
  }

  render() {
    const { visible, loading } = this.state;
    const {
      previewVisible,
      previewImage,
      fileList1,
      previewTitle,
      fileList2,
      fileList3,
      fileList4,
    } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload Images</div>
      </div>
    );
    return (
      <>
        <div className="btn">
          <Tooltip title="Add Image">
            <Button onClick={this.showModal} type="primary">
              Upload
            </Button>
          </Tooltip>
        </div>

        <Row>
          <Col span={24}>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Add Images"
              style={{ top: 50 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="add-Images"
                className="add-Images"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="upload"
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
                >
                  {this.props.productsImages &&
                    this.props.productsImages.length == -5 ? (
                    <>
                      <div className="brand-image-show">
                        {this.props.productsImages[0] ? (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={this.props.productsImages[0]}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={noImage}
                            />
                          </>
                        )}
                        <div className="brand-image-show-button">
                          <div
                            onClick={() =>
                              this.handleDefaultPreview(
                                this.props?.productsImages?.[0]
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <EyeOutlined className="brand-image-show-icon" />
                            <p>SHOW</p>
                          </div>
                          <div
                            onClick={() =>
                              this.handleDelete(this.props.productsImages[0], 0)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.spinStatus0 ? (
                              <LoadingOutlined
                                className="brand-image-show-icon"
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            ) : (
                              <DeleteOutlined className="brand-image-show-icon" />
                            )}
                            <p>DELETE</p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="brand-image-show">
                        {this.props.productsImages[1] ? (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={this.props.productsImages[1]}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={noImage}
                            />
                          </>
                        )}
                        <div className="brand-image-show-button">
                          <div
                            onClick={() =>
                              this.handleDefaultPreview(
                                this.props?.productsImages?.[1]
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <EyeOutlined className="brand-image-show-icon" />
                            <p>SHOW</p>
                          </div>
                          <div
                            onClick={() =>
                              this.handleDelete(this.props.productsImages[1], 1)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.spinStatus1 ? (
                              <LoadingOutlined
                                className="brand-image-show-icon"
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            ) : (
                              <DeleteOutlined className="brand-image-show-icon" />
                            )}
                            <p>DELETE</p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="brand-image-show">
                        {this.props.productsImages[2] ? (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={this.props.productsImages[2]}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={noImage}
                            />
                          </>
                        )}
                        <div className="brand-image-show-button">
                          <div
                            onClick={() =>
                              this.handleDefaultPreview(
                                this.props?.productsImages?.[2]
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <EyeOutlined className="brand-image-show-icon" />
                            <p>SHOW</p>
                          </div>
                          <div
                            onClick={() =>
                              this.handleDelete(this.props.productsImages[2], 2)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.spinStatus2 ? (
                              <LoadingOutlined
                                className="brand-image-show-icon"
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            ) : (
                              <DeleteOutlined className="brand-image-show-icon" />
                            )}
                            <p>DELETE</p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="brand-image-show">
                        {this.props.productsImages[3] ? (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={this.props.productsImages[3]}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="example"
                              style={{ width: "180px", height: "150px" }}
                              src={noImage}
                            />
                          </>
                        )}

                        <div className="brand-image-show-button">
                          <div
                            onClick={() =>
                              this.handleDefaultPreview(
                                this.props?.productsImages?.[3]
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <EyeOutlined className="brand-image-show-icon" />
                            <p>SHOW</p>
                          </div>
                          <div
                            onClick={() =>
                              this.handleDelete(this.props.productsImages[3], 3)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.spinStatus3 ? (
                              <LoadingOutlined
                                className="brand-image-show-icon"
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            ) : (
                              <DeleteOutlined className="brand-image-show-icon" />
                            )}
                            <p>DELETE</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col span={24} align="center">
                          <Upload
                            className="hideShow-delete"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".png, .jpg"
                            listType="picture-card"
                            fileList={fileList1}
                            onPreview={this.handlePreview}
                            // onChange={this.handleChange}
                            onChange={(fileList) =>
                              this.handleChange(fileList, "fileList1")
                            }
                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList1" )}
                          >
                            {fileList1.length === 1 ? null : uploadButton}
                          </Upload>
                          {this.state.spinStatus0 ? (
                            <LoadingOutlined
                              className="brand-image-show-icon mt-2 mb-4"
                              style={{ fontSize: 24 }}
                              spin
                            />
                          ) : this.state.urlPath[0]?.filePath ? (
                            <Tag color="success" className="mt-2 mb-4">
                              Successfully Uploaded!
                            </Tag>
                          ) : (
                            <Tag color="error" className="mt-2 mb-4">
                              {this.state.error0
                                ? "Upload Failed"
                                : "Not Uploaded"}
                            </Tag>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} align="center">
                          <Upload
                            className="hideShow-delete"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".png, .jpg"
                            listType="picture-card"
                            fileList={fileList2}
                            onPreview={this.handlePreview}
                            disabled={this.state.urlPath[0].filePath ? false : true}
                            // onChange={this.handleChange}
                            onChange={(fileList) =>
                              this.handleChange(fileList, "fileList2")
                            }
                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList2" )}
                          >
                            {fileList2.length === 1 ? null : uploadButton}
                          </Upload>
                          {this.state.spinStatus1 ? (
                            <LoadingOutlined
                              className="brand-image-show-icon mt-2 mb-4"
                              style={{ fontSize: 24 }}
                              spin
                            />
                          ) : this.state.urlPath[1]?.filePath ? (
                            <Tag color="success" className="mt-2 mb-4">
                              Successfully Uploaded!
                            </Tag>
                          ) : (
                            <Tag color="error" className="mt-2 mb-4">
                              {this.state.error1
                                ? "Upload Failed"
                                : "Not Uploaded"}
                            </Tag>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} align="center">
                          <Upload
                            className="hideShow-delete"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".png, .jpg"
                            listType="picture-card"
                            fileList={fileList3}
                            onPreview={this.handlePreview}
                            disabled={this.state.urlPath[1].filePath ? false : true}
                            // onChange={this.handleChange}
                            onChange={(fileList) =>
                              this.handleChange(fileList, "fileList3")
                            }
                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList3" )}
                          >
                            {fileList3.length === 1 ? null : uploadButton}
                          </Upload>
                          {this.state.spinStatus2 ? (
                            <LoadingOutlined
                              className="brand-image-show-icon mt-2 mb-4"
                              style={{ fontSize: 24 }}
                              spin
                            />
                          ) : this.state.urlPath[2]?.filePath ? (
                            <Tag color="success" className="mt-2 mb-4">
                              Successfully Uploaded!
                            </Tag>
                          ) : (
                            <Tag color="error" className="mt-2 mb-4">
                              {this.state.error2
                                ? "Upload Failed"
                                : "Not Uploaded"}
                            </Tag>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} align="center">
                          <Upload
                            className="hideShow-delete"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".png, .jpg"
                            listType="picture-card"
                            fileList={fileList4}
                            onPreview={this.handlePreview}
                            disabled={this.state.urlPath[2].filePath ? false : true}
                            onChange={(fileList) =>
                              this.handleChange(fileList, "fileList4")
                            }
                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList4" )}
                          >
                            {fileList4.length === 1 ? null : uploadButton}
                          </Upload>
                          {this.state.spinStatus3 ? (
                            <LoadingOutlined
                              className="brand-image-show-icon mt-2 mb-4"
                              style={{ fontSize: 24 }}
                              spin
                            />
                          ) : this.state.urlPath[3]?.filePath ? (
                            <Tag color="success" className="mt-2 mb-4">
                              Successfully Uploaded!
                            </Tag>
                          ) : (
                            <Tag color="error" className="mt-2 mb-4">
                              {this.state.error3
                                ? "Upload Failed"
                                : "Not Uploaded"}
                            </Tag>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}

                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Form.Item>
                {/* <Form.Item className="float-right">
                                {console.log("check mohit props", this.props.details.sales)}
                                <Switch
                                    size="large"
                                    checked={this.props.details.sales == "OFF" ? false : true}
                                    onChange={() => this.onSaleChange(this.props.details.barcode, this.props.details)}
                                />
                            </Form.Item> */}

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={this.handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

AddGlobalImage.propTypes = {
  errors: PropTypes.object.isRequired,
  getUniquePurchases: PropTypes.func.isRequired,
  addEcomSale: PropTypes.func.isRequired,
  addProductImage: PropTypes.func.isRequired,
  getBrandImages: PropTypes.func.isRequired,
  deleteProductImage: PropTypes.func.isRequired,
  addSingleGlobalEcomImage: PropTypes.func.isRequired,
  addMultipleGlobalEcomImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  productsImages: state.brandsData.brandImages,
});

export default connect(mapStateToProps, {
  getUniquePurchases,
  addEcomSale,
  addProductImage,
  getBrandImages,
  deleteProductImage,
  addSingleGlobalEcomImage,
  addMultipleGlobalEcomImage,
})(AddGlobalImage);