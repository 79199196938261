/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
11-08-2023        Mohit                    View Bill for sales return

*/
/* /////////////////////////////////////// */


import { FileDoneOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Modal, Table, Button, Typography, Col, Row, Tooltip } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getsaleReturnBillBysaleReturnId } from "../../../../../redux/actions/saleAction"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Spin } from 'antd';

// import "../../../assets/css/generateBill.css";

class ViewSalesReturnBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            // billDate: "",
            // saleDate: moment(new Date()),
        };
    }

    handleModalCancel = () => {
        // window.location.reload()
        this.props.getsaleReturnBillBysaleReturnId("reset")
        this.setState({ visible: false });
    };
    showModal = () => {
        this.getDetails()
        this.setState({
            visible: true,
        });
    };

    getDetails = async () => {
        await this.props.getsaleReturnBillBysaleReturnId(this.props.text.saleReturnId);
    }

    handlePdfGeneration = () => {
        const element = document.getElementById("pdf-content");
        if (!element) {
            return;
        }

        element.style.width = "400px";
        element.style.margin = "0 auto";

        const opt = {
            margin: [10, 10, 10, 10],
            filename: "bill.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            // css: customPdfStyles
        };


        html2pdf().from(element).set(opt).save().then(() => {
            element.style.width = null;
            element.style.margin = null;
        });
    };



    render() {
        const { visible } = this.state;
        const { billData } = this.props
        const purchaseData = billData.data?.map((obj) => {
            return { saleQty: obj.quantity, ...obj.details };
        });

        let track = {};
        let brands = [];
        let e = 0;
        let f = purchaseData ? purchaseData.length : 0;
        // let f = purchaseData.length;
        while (e < f) {
            if (purchaseData[e].brand in track) {
                track[purchaseData[e].brand].push(e);
            } else {
                track[purchaseData[e].brand] = [e];
                brands.push(purchaseData[e].brand);
            }
            ++e;
        }
        let data = brands?.map((purchaseName) => {
            let indexes = track[purchaseName];
            let insideData = [];
            let ee = 0;
            let ff = indexes.length;
            while (ee < ff) {
                let purchase = purchaseData[indexes[ee]];
                insideData.push(
                    <li>
                        <div class="page-break">
                            <Row className="mt-n2">
                                <Col className="mr-1" style={{ width: "108px" }}>
                                    <p style={{ fontWeight: 800 }}>
                                        {indexes[ee] + 1} || {purchase.designNumber}
                                    </p>
                                </Col>
                                <Col className="mr-1" style={{ width: "80px" }}>
                                    <p style={{ fontWeight: 800 }}>{purchase.size}</p>
                                </Col>
                                <Col className="mr-2" style={{ width: "50px" }}>
                                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                                        {purchase.saleQty}
                                    </p>
                                </Col>
                                <Col className="mr-1" style={{ width: "50px" }}>
                                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                                        {purchase.wsp}
                                    </p>
                                </Col>
                                {/*<Col className="mr-1" style={{ width: "70px" }}>
                <p className="mb-n2" style={{ fontWeight: 800 }}>
                  {purchase.color}
                </p>
        </Col>*/}
                                <Col className="mr-1" style={{ width: "66px" }}>
                                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                                        {Math.round(purchase.saleQty * purchase.wsp)}
                                    </p>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    </li>
                );
                ++ee;
            }
            return (
                <li>
                    <h5>
                        <u>
                            <strong>{purchaseName}</strong>
                        </u>
                    </h5>
                    <ul>{insideData}</ul>
                </li>
            );
        });
        return (
            <>
                <div className="generate-bill">
                    {/* <Button
          type="primary"
          onClick={this.showModal}
          className="close-modal"
          block
        >
          Generate Bill
        </Button> */}
                    <Tooltip title="View Bill">
                        <FileDoneOutlined onClick={this.showModal} />
                    </Tooltip>
                    <ReactToPrint content={() => this.componentRef}>
                        <Modal
                            visible={visible}
                            onOk={this.handleOk}
                            onCancel={this.handleModalCancel}
                            title="Generate Bill"
                            style={{ top: 20 }}
                            okButtonProps={{ hidden: true }}
                            cancelButtonProps={{ hidden: true }}
                            destroyOnClose={true}
                            width={1500}
                        >

                            <div className="card generate-bill-card" id="pdf-content"  >
                                <div className="card-body generate-bill-font" style={{ padding: "0.6rem" }} ref={(el) => (this.componentRef = el)}>
                                    {
                                        billData && billData.data && billData.data.length > 0 && data && data.length > 0 ? <>
                                            <div className="generate-bill-font-test7">
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "center" }}>
                                                        <strong>
                                                            <p
                                                                className="mb-n2 font-weight-bold"
                                                                style={{ width: "380px" }}
                                                            >
                                                                || Jai Shree Krishna ||
                                                            </p>
                                                        </strong>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        span={8}
                                                        className="mr-2"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        <h4 className="mb-n1 mt-2" style={{ width: "380px" }}>
                                                            Credit Note
                                                        </h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p className="mb-n1" style={{ width: "350px" }}>
                                                            <strong>Invoice Id : </strong>{billData?.data?.[0]?.saleReturnId}
                                                        </p>
                                                        <p style={{ width: "350px" }}>
                                                            <strong>Customer :
                                                                {this.props.text.userId.name}
                                                            </strong>
                                                        </p>
                                                        <p style={{ width: "350px" }}>
                                                            <strong>Agent :
                                                            {this.props?.record?.userId?.agentId?.name || ""}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mr-5">
                                                        <p className="mb-n2" style={{ width: "350px" }}>
                                                            <strong>Date :
                                                                {
                                                                    (() => {
                                                                        const momentDate = moment(this.props.record.date);
                                                                        const formattedDate = momentDate.format("DD/MM/YYYY");
                                                                        const formattedTime = momentDate.format("HH:mm:ss");
                                                                        return (
                                                                            <span>{formattedDate} &nbsp;&nbsp; {formattedTime}</span>
                                                                        )
                                                                    })()
                                                                }
                                                            </strong>
                                                        </p>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <strong> <p className="mb-n2">Note : {this.props.record.remark}</p></strong>
                                                    </Col>
                                                </Row>
                                                <div style={{ width: "380px", display: "block", }}>
                                                    <Row className="mt-3">
                                                        <Col className="mr-1" style={{ width: "114px" }}>
                                                            <strong>  <p class="font-weight-bold">Particulars</p></strong>
                                                        </Col>
                                                        <Col className="mr-1" style={{ width: "80px" }}>
                                                            <strong><p class="font-weight-bold">Size</p></strong>
                                                        </Col>
                                                        <Col className="mr-1" style={{ width: "50px" }}>
                                                            <strong>  <p class="font-weight-bold">Qty</p></strong>
                                                        </Col>
                                                        <Col className="mr-1" style={{ width: "50px" }}>
                                                            <strong> <p class="font-weight-bold">Rate</p></strong>
                                                        </Col>
                                                        {/*<Col className="mr-1" style={{ width: "70px" }}>
                    <p class="font-weight-bold">Color</p>
    </Col>*/}
                                                        <Col className="mr-1" style={{ width: "60px" }}>
                                                            <p class="font-weight-bold">Amt</p>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <ul>{data}</ul>
                                                    <hr />
                                                    <Row className="mt-2">
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>Total Qty</p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "center", width: "120px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>
                                                                {this.props.record.totalQuantity}
                                                            </p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >

                                                            <p style={{ fontWeight: 800 }}>
                                                                {/* {this.props.netAmount +
                          this.props.grAmount -
                          Math.round(this.props.taxedAmount) +
                          Math.round(this.props.discountedAmount)} */}
                                                                {parseInt(this.props.record.totalAmount).toFixed()}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>Discount</p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "center", width: "120px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>{this.props?.record?.saleBillData?.discount}%</p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>
                                                                {this.props.record.totalAmount * this.props?.record?.saleBillData?.discount / 100}
                                                            </p>
                                                        </Col>
                                                    </Row> */}

                                                    {/* <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>VAT/CST</p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "center", width: "120px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>{this.props.record.saleBillData.tax}%</p>
                                                        </Col>
                                                        <Col
                                                            className="mr-2"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>{(this.props.record.totalAmount * this.props.record.saleBillData.tax / 100).toFixed()}</p>
                                                        </Col>
                                                    </Row> */}

                                                    {/* <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>P. & F.</p>
                                                        </Col>
                                                        <Col style={{ textAlign: "center", width: "120px" }}>
                                                        </Col>
                                                        <Col
                                                            className="generate-bill"
                                                            style={{ textAlign: "end", width: "120px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>{this.props.record.saleBillData.pAndf}</p>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>Frieght</p>
                                                        </Col>
                                                        <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                                                        </Col>
                                                        <Col
                                                            className="generate-bill"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>00</p>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>GR Amount</p>
                                                        </Col>
                                                        <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                                                        </Col>
                                                        <Col
                                                            className="generate-bill"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >
                                                            <p style={{ fontWeight: 800 }}>{this.props.record.grAmountUsed}</p>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                                                            <p>Net Amount</p>
                                                        </Col>
                                                        <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                                                        </Col>
                                                        <Col
                                                            className="generate-bill"
                                                            style={{ textAlign: "end", width: "110px" }}
                                                        >
                                                            {
                                                                <p style={{ fontWeight: 800 }}>{parseInt(this.props.record.totalAmount).toFixed()}</p>


                                                                // (()=>{
                                                                //     let total = parseInt(this.props.record.totalAmount)
                                                                //     let dicountAmt = total * this.props.record.saleBillData.discount / 100
                                                                //     let taxAmt = total * this.props.record.saleBillData.tax / 100
                                                                //     let netAmt = total - dicountAmt + taxAmt
                                                                //     return <p style={{ fontWeight: 800 }}>{netAmt.toFixed()}</p>
                                                                // })()
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="mx-2">
                                                    <Row>
                                                        <Col style={{ width: "175px", textAlign: "left" }}>
                                                            <p className="mb-n2">E.D.E.</p>
                                                        </Col>
                                                        <Col style={{ textAlign: "end", width: "175px" }}>
                                                            <p className="mb-n2">Authorised Signatory</p>
                                                        </Col>
                                                    </Row>
                                                    <p className="mb-n2">Terms & Cond.</p>
                                                    <p className="mb-n2">
                                                        1. Goods once sold will not be taken back
                                                    </p>
                                                    <p>2. No Claim No Guarantee</p>
                                                </div>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "center" }}>
                                                        <p className="font-weight-bold" style={{ width: "350px" }}>
                                                            || Thank You Visit Again ||
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </> : <>
                                            <div className="spin-ViewBill">
                                                <Spin />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <Row className="generate-pdf-download-row">
                                <Col>
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <Button
                                                style={{ marginLeft: "45%" }}
                                                type="primary"
                                                className="close-modal mt-4"
                                                // block
                                                onClick={() => {
                                                    // this.handlePdfGeneration()
                                                    handlePrint();
                                                    // this.props.clearState(false)
                                                    // this.props.createSales({}, {}, "blank");
                                                    // this.props.createSales(false);
                                                }}
                                            >
                                                Generate Bill
                                            </Button>
                                        )}
                                    </PrintContextConsumer>
                                </Col>
                                <Col style={{ marginLeft: "1rem" }}>
                                    <Button
                                        style={{ marginLeft: "45%" }}
                                        type="primary"
                                        className="save-modal mt-4"
                                        // block
                                        onClick={() => {
                                            this.handlePdfGeneration()
                                        }}
                                    >
                                        Download PDF
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                    </ReactToPrint>

                </div>
            </>
        );
    }
}


ViewSalesReturnBill.propTypes = {
    getsaleReturnBillBysaleReturnId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    invoiceId: state.saleData.sale.invoiceId,
    salesOrders: state.saleData.salesOrders,
    billData: state.saleData.saleReturnBillSaleReturnId
});

export default connect(mapStateToProps, {
    getsaleReturnBillBysaleReturnId
})(ViewSalesReturnBill);
