import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  DatePicker,
  Input,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import {
  ReloadOutlined,
  StopOutlined,
  PlusOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import BarcodeReader from "react-barcode-reader";
import {
  getActiveandInactiveCustomer,
  getCustomers,
} from "../../../../redux/actions/customerAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandBySearch } from "../../../../redux/actions/brandAction";
import {
  getPurchaseByBarcodeForSaleApplication,
  getdesignAndBrandComboByBarcode,
} from "../../../../redux/actions/purchaseAction";
import moment from "moment/moment";
import {
  createSales,
  getSaleDetailsByinvoiceIds,
} from "../../../../redux/actions/saleAction";
import gifLoader from "../../../../assets/images/giphy.gif";
import barcodeSound from "../../../../assets/audio/Barcode-scanner-beep-sound.mp3";
import ShowSalesDetails from "../ShowSalesDetails";

// =============Mohit New Logic Imports Start===================
import { useHistory, useLocation } from "react-router-dom";

// =============Mohit New Logic Imports End===================

const { Option } = Select;
const currentDate = moment().format("YYYY-MM-DD");
const currentTime = moment().format("HH:mm:ss");
const storedToken = localStorage.getItem("token");

const MergeSalesOrdersBill = () => {
  // =============Mohit New Logic Satets and hooks Start===================
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const allBillData = useSelector(
    (state) => state.saleData.salesDetailsbyInvoiceId
  );

  // =============Mohit New Logic Satets and hooks End===================

  const formRef = useRef();
  const InactiveCustomer = useSelector(
    (state) => state.customerData.activeandInactiveCustomer
  );
  const [barcode, setBarcode] = useState("");
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [barcodeLoading, setBarcodeLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [audio, setAudio] = useState(null);
  const [isCustomerSelect, setIsCustomerSelect] = useState(false);
  const [isSalesPersonSelect, setIsSalesPersonSelect] = useState(false);
  const [isbarcodeEnter, setIsbarcodeEnter] = useState(false);
  const SalePersonName = useSelector((state) => state.customerData.salesPerson);
  const brandNameSearch = useSelector(
    (state) => state.brandsData.brandBysearch
  );
  const [stopStream, setStopStream] = useState(false);
  const [isBarcodeScanner, setIsBarcodeScanner] = useState(true);
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState({
    myPurchaseData: [],
    invoiceNumber: "",
    saleDate: currentDate,
    saleTime: currentTime,
    saleBillDate: moment(
      `${currentDate} ${currentTime}`,
      "YYYY-MM-DD HH:mm:ss"
    ).toDate(),
    customerName: "",
    customerId: "",
    agentId: "",
    agent: "",
    salesPersonName: "",
    lrNumber: "",
    lrDate: "",
    remark: "",
    transport: "",
    grAmount: 0,
    barcode: "",
    designNumber: "",
    boxes: "",
    totalBoxes: 0,
    totalQuantity: 0,
    totalAmount: 0,
    brand: "",
    brandId: "",
    selectedData: "",
    salesPersonId: "",
    saleQty: 0,
    discount: 0,
    tax: 5,
    pf: 0,
    labour: 0,
    billedGR: false,
    netAmount: 0,
    finalDiscAmt: 0,
    taxAmt: 0,
    taxedAmount: 0,
    discountAmt: 0,
    diabledStatus: false,
    responseData: [],
  });
  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const onError = (error) => {
    console.error("Barcode scanner error:", error);
  };
  const onUpdateScreen = async (err, result, isScanner) => {
    if (data.salesPersonName && data.customerName) {
      if (result) {
        setData((prevData) => ({
          ...prevData,
          barcode: result.text,
          disabledStatus: true,
        }));

        {
          isScanner != true && setIsBarcodeScanner(false);
        }
        let barcode = result.text;
        if (barcode.length === 9) {
          message.success("Barcode Scan Successfully");
          playSound();
          {
            isScanner != true && setBarcodeLoading(true);
          }
          // const response = await dispatch(
          //   getdesignAndBrandComboByBarcode({ barcode: barcode })
          // );
          const response = await dispatch(
            getPurchaseByBarcodeForSaleApplication({ barcode: Number(barcode) })
          );
          if (response && response?.status == 200 && response?.data) {
            setData((prevData) => ({
              ...prevData,
              barcode: result.text,
              designNumber: response?.data?.designNumber,
              brand: response?.data?.brand,
              brandId: response?.data?.brandId,
              // responseData: response?.data?.data,
            }));

            formRef.current.setFieldsValue({
              brand: response?.data?.brand,
            });
            setModalStatus(true);
            await handleModalBarcode(result.text, response?.data);
          } else {
            setData((prevData) => ({
              ...prevData,
              responseData: [],
              barcode: "",
              designNumber: "",
              brand: "",
              brandId: "",
            }));
            {
              isScanner != true && setIsBarcodeScanner(false);
            }
            // message.success("No data Found");

            formRef.current.setFieldsValue({
              brand: undefined,
            });
          }
          isScanner != true && setBarcodeLoading(false);
        } else {
          message.error("Barcode Not Matched");
        }
      }
      setData((prevData) => ({
        ...prevData,
        disabledStatus: false,
        barcode: "",
        designNumber: "",
        brand: "",
        brandId: "",
      }));
      formRef.current.setFieldsValue({
        brand: undefined,
        // barcode :
      });
    } else {
      message.warn("Please Select Manditory Details!");
      setIsSalesPersonSelect(true);
      setIsCustomerSelect(true);
    }
  };

  const handleGrUsedCheck = (e) => {
    if (e.target.checked === false) {
      const discountAmt = data.totalAmount * (data.discount / 100);
      // const finalDiscAmt = prevData.totalAmount - discountAmt;
      let finalDiscAmt = data.totalAmount - discountAmt;
      const taxAmt = finalDiscAmt * (data.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = data.pf + data.labour + taxedAmount;

      setData((prevData) => ({
        ...prevData,
        billedGR: e.target.checked,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount,
      }));
    } else {
      const discountAmt =
        (data.totalAmount - data.grAmount) * (data.discount / 100);
      const finalDiscAmt = data.totalAmount - discountAmt - data.grAmount;
      const taxAmt = finalDiscAmt * (data.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = data.pf + data.labour + taxedAmount;

      setData((prevData) => ({
        ...prevData,
        billedGR: e.target.checked,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount,
      }));
    }
  };

  // Play Sound
  const playSound = () => {
    if (audio) {
      audio.play();
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
      }, 1000); // play for 1 second
    }
  };

  // done
  const handleModalStatus = async () => {
    setAddProductLoading(true);
    let barcodeMatchIndex = data?.myPurchaseData?.findIndex(
      (text) => Number(data.barcode) === Number(text.barcode)
    );
    let matchBarcode = data?.responseData?.find(
      (text) => Number(data?.barcode) === Number(text?.barcode)
    );
    const response = await dispatch(
      getPurchaseByBarcodeForSaleApplication({ barcode: data?.barcode })
    );
    if (
      response &&
      response?.status === 200 &&
      response?.data &&
      response?.data?.totalPurchaseValue > 0
    ) {
      if (barcodeMatchIndex !== -1) {
        if (
          response?.data?.totalPurchaseValue >
          data?.myPurchaseData[barcodeMatchIndex]?.saleQty
        ) {
          setData((prevData) => {
            const updatedMyPurchaseData = prevData?.myPurchaseData?.map(
              (item, index) =>
                Number(item?.barcode) === Number(matchBarcode?.barcode)
                  ? {
                      ...item,
                      // saleQty: item?.saleQty + matchBarcode?.MOQ,
                      // boxes: (item?.saleQty + matchBarcode?.MOQ) / item?.MOQ,
                      // finalAmount: (item?.saleQty + matchBarcode?.MOQ) * item?.wsp,
                      saleQty:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.saleQty
                          : item?.saleQty + matchBarcode?.MOQ,
                      boxes:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.boxes
                          : (item.saleQty + matchBarcode.MOQ) / item.MOQ,
                      finalAmount:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.finalAmount
                          : (item.saleQty + matchBarcode.MOQ) * item.wsp,
                      salesPersonId: data?.salesPersonId,
                      salesPersonName: data?.salesPersonName,
                      updateQty: true,
                    }
                  : { ...item, updateQty: false }
            );

            const totalQuantity =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalQuantity
                : prevData.totalQuantity + matchBarcode.MOQ;
            const totalBoxes =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalBoxes
                : prevData.totalBoxes + 1;
            const totalAmount =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalAmount
                : prevData.totalAmount +
                  prevData.myPurchaseData[barcodeMatchIndex].MOQ *
                    prevData.myPurchaseData[barcodeMatchIndex].wsp;

            return {
              ...prevData,
              myPurchaseData: updatedMyPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
              diabledStatus: false,
              // brand : "",
              // designNumber : "",
              // barcode : "",
              // brandId : ""
            };
          });
          // formRef.current.setFieldsValue({
          // brand : undefined
          // })
          message.success("Barcode Added Successfully!");
        } else {
          message.error("Out Of Stock");
        }
      } else {
        setData((prevData) => {
          const updatedMyPurchaseData = [
            ...prevData.myPurchaseData,
            {
              ...matchBarcode,
              saleQty: matchBarcode?.MOQ,
              boxes: matchBarcode?.MOQ / matchBarcode?.MOQ,
              finalAmount: matchBarcode?.MOQ * matchBarcode?.wsp,
              salesPersonId: data?.salesPersonId,
              salesPersonName: data?.salesPersonName,
              updateQty: true,
            },
          ];

          const totalQuantity = prevData.totalQuantity + matchBarcode?.MOQ;
          const totalBoxes =
            prevData.totalBoxes + matchBarcode?.MOQ / matchBarcode?.MOQ;
          const totalAmount =
            prevData.totalAmount + matchBarcode?.MOQ * matchBarcode?.wsp;

          const newPurchaseData = updatedMyPurchaseData?.map((obj) => {
            if (obj.barcode == data.barcode) {
              return { ...obj, updateQty: true };
            } else {
              return { ...obj, updateQty: false };
            }
          });
          return {
            ...prevData,
            myPurchaseData: newPurchaseData,
            totalQuantity: totalQuantity,
            totalBoxes: totalBoxes,
            totalAmount: totalAmount,
            diabledStatus: false,
            // brand : "",
            // designNumber : "",
            // barcode : "",
            // brandId : ""
          };
        });
        // formRef.current.setFieldsValue({
        // brand : undefined
        // })
        message.success("Barcode Added Successfully!");
      }
      setData((prevData) => {
        let discountAmt; // const finalDiscAmt = prevData.totalAmount - discountAmt;
        let finalDiscAmt;
        if (prevData?.billedGR === false) {
          discountAmt = prevData?.totalAmount * (prevData?.discount / 100);
          finalDiscAmt = prevData?.totalAmount - discountAmt;
        } else {
          discountAmt =
            (prevData.totalAmount - prevData.grAmount) *
            (prevData.discount / 100);
          finalDiscAmt =
            prevData?.totalAmount - discountAmt - prevData?.grAmount;
        }
        const taxAmt = finalDiscAmt * (prevData.tax / 100);
        const taxedAmount = finalDiscAmt + taxAmt;
        const NetAmount = prevData.pf + prevData.labour + taxedAmount;

        return {
          ...prevData,
          discountAmt: discountAmt,
          finalDiscAmt: finalDiscAmt,
          taxAmt: taxAmt,
          taxedAmount: taxedAmount,
          netAmount: NetAmount,
        };
      });
      playSound();
      setData((prevData) => {
        return {
          ...prevData,
          barcode: "",
          designNumber: "",
          brand: "",
          brandId: "",
        };
      });
      formRef.current.setFieldsValue({
        brand: undefined,
        // barcode :
      });
    } else {
      playSound();
      message.error("Out of Stock");
    }
    setModalStatus(true);
    setAddProductLoading(false);
  };

  const handleModalBarcode = async (barcode, result) => {
    let barcodeMatchIndex = data?.myPurchaseData?.findIndex(
      (text) => Number(barcode) == Number(text.barcode)
    );
    let finData = data.myPurchaseData?.find((obj) => obj.barcode == barcode);
    if (finData && finData.saleQty >= result?.totalPurchaseValue) {
      message.error("Maximum Qty Reached!");
    } else {
      // Check if the response is valid and has stock
      if (result.totalPurchaseValue > 0) {
        if (barcodeMatchIndex !== -1 && barcodeMatchIndex !== undefined) {
          setData((prevData) => {
            const updatedMyPurchaseData = prevData?.myPurchaseData?.map(
              (item, index) =>
                Number(item?.barcode) === Number(result?.barcode)
                  ? {
                      ...item,
                      // saleQty: item?.saleQty + matchBarcode?.MOQ,
                      // boxes: (item?.saleQty + matchBarcode?.MOQ) / item?.MOQ,
                      // finalAmount: (item?.saleQty + matchBarcode?.MOQ) * item?.wsp,
                      saleQty:
                        result?.totalPurchaseValue == item?.saleQty
                          ? item?.saleQty
                          : item?.saleQty + result?.MOQ,
                      boxes:
                        result?.totalPurchaseValue == item?.saleQty
                          ? item?.boxes
                          : (item.saleQty + result.MOQ) / item.MOQ,
                      finalAmount:
                        result?.totalPurchaseValue == item?.saleQty
                          ? item?.finalAmount
                          : (item.saleQty + result.MOQ) * item.wsp,
                      salesPersonId: data?.salesPersonId,
                      salesPersonName: data?.salesPersonName,
                      updateQty: true,
                    }
                  : { ...item, updateQty: false }
            );

            const totalQuantity = prevData.totalQuantity + result.MOQ;
            const totalBoxes = prevData.totalBoxes + 1;
            const totalAmount = prevData.totalAmount + result.MOQ * result.wsp;

            return {
              ...prevData,
              myPurchaseData: updatedMyPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
              disabledStatus: false,
            };
          });

          formRef.current.setFieldsValue({
            brand: undefined,
          });
        } else {
          setData((prevData) => {
            const updatedMyPurchaseData = [
              ...prevData.myPurchaseData,
              {
                ...result,
                saleQty: result?.MOQ,
                boxes: result?.MOQ / result?.MOQ,
                finalAmount: result?.MOQ * result?.wsp,
                salesPersonId: data?.salesPersonId,
                salesPersonName: data?.salesPersonName,
                updateQty: true,
              },
            ];

            const totalQuantity = prevData.totalQuantity + result?.MOQ;
            const totalBoxes = prevData.totalBoxes + 1;
            const totalAmount =
              prevData.totalAmount + result?.MOQ * result?.wsp;

            const newPurchaseData = updatedMyPurchaseData?.map((obj) => {
              if (obj.barcode == data.barcode) {
                return { ...obj, updateQty: true };
              } else {
                return { ...obj, updateQty: false };
              }
            });
            return {
              ...prevData,
              myPurchaseData: newPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
              disabledStatus: false,
            };
          });
        }

        // Update additional calculations based on myPurchaseData changes
        setData((prevData) => {
          // const discountAmt = prevData?.totalAmount * (prevData?.discount / 100);
          let finalDiscAmt;
          let discountAmt;
          if (prevData?.billedGR === false) {
            discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

            finalDiscAmt = prevData?.totalAmount - discountAmt;
          } else {
            discountAmt =
              (prevData.totalAmount - prevData.grAmount) *
              (prevData.discount / 100);
            finalDiscAmt =
              prevData?.totalAmount - discountAmt - prevData?.grAmount;
          }
          const taxAmt = finalDiscAmt * (prevData.tax / 100);
          const taxedAmount = finalDiscAmt + taxAmt;
          const NetAmount = prevData.pf + prevData.labour + taxedAmount;

          return {
            ...prevData,
            discountAmt: discountAmt,
            finalDiscAmt: finalDiscAmt,
            taxAmt: taxAmt,
            taxedAmount: taxedAmount,
            netAmount: NetAmount,
          };
        });
      } else {
        setData((prevData) => ({
          ...prevData,
          brand: "",
          designNumber: "",
          barcode: "",
          brandId: "",
        }));
        formRef.current.setFieldsValue({
          brand: undefined,
        });

        // No stock found in the response
        message.error("Out of Stock!");
      }
    }
    setData((prevData) => {
      return {
        ...prevData,
        barcode: "",
        designNumber: "",
        brand: "",
        brandId: "",
      };
    });
    formRef.current.setFieldsValue({
      brand: undefined,
      // barcode :
    });
  };

  const onAddingBoxes = async (record) => {
    let findBarcode = data.myPurchaseData.find(
      (data) => data.barcode === record.barcode
    );

    if (findBarcode !== undefined) {
      const response = await dispatch(
        getPurchaseByBarcodeForSaleApplication({ barcode: record.barcode })
      );
      if (response && response.status === 200 && response.data) {
        if (
          response?.data?.totalPurchaseValue > 0 &&
          response?.data?.totalPurchaseValue > findBarcode?.saleQty
        ) {
          let barcodeMatchIndex = data?.myPurchaseData?.findIndex(
            (text) => record?.barcode === text?.barcode
          );

          setData((prevData) => {
            const updatedMyPurchaseData = prevData?.myPurchaseData?.map(
              (item, index) =>
                item?.barcode === record?.barcode
                  ? {
                      ...item,
                      // saleQty: response?.data?.totalPurchaseValue == item?.saleQty ? item?.saleQty : item?.saleQty + record?.MOQ ,
                      // boxes: (item?.saleQty + record?.MOQ) / item?.MOQ,
                      // finalAmount: (item?.saleQty + record?.MOQ) * item?.wsp,
                      saleQty:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.saleQty
                          : item?.saleQty + record?.MOQ,
                      boxes:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.boxes
                          : (item.saleQty + record.MOQ) / item.MOQ,
                      finalAmount:
                        response?.data?.totalPurchaseValue == item?.saleQty
                          ? item?.finalAmount
                          : (item.saleQty + record.MOQ) * item.wsp,
                      salesPersonId: data?.salesPersonId,
                      salesPersonName: data?.salesPersonName,
                    }
                  : item
            );

            const totalQuantity =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalQuantity
                : prevData.totalQuantity + record.MOQ;
            const totalBoxes =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalBoxes
                : prevData.totalBoxes + 1;
            const totalAmount =
              response?.data?.totalPurchaseValue ==
              prevData.myPurchaseData[barcodeMatchIndex].saleQty
                ? prevData.totalAmount
                : prevData.totalAmount +
                  prevData.myPurchaseData[barcodeMatchIndex].MOQ *
                    prevData.myPurchaseData[barcodeMatchIndex].wsp;

            return {
              ...prevData,
              myPurchaseData: updatedMyPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
            };
          });

          setData((prevData) => {
            // const discountAmt = prevData.totalAmount * (prevData.discount / 100);
            let discountAmt;
            let finalDiscAmt;
            if (prevData?.billedGR === false) {
              discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

              finalDiscAmt = prevData?.totalAmount - discountAmt;
            } else {
              discountAmt =
                (prevData.totalAmount - prevData.grAmount) *
                (prevData.discount / 100);
              finalDiscAmt =
                prevData?.totalAmount - discountAmt - prevData?.grAmount;
            }
            const taxAmt = finalDiscAmt * (prevData.tax / 100);
            const taxedAmount = finalDiscAmt + taxAmt;
            const NetAmount = prevData.pf + prevData.labour + taxedAmount;
            return {
              ...prevData,
              discountAmt: discountAmt,
              finalDiscAmt: finalDiscAmt,
              taxAmt: taxAmt,
              taxedAmount: taxedAmount,
              netAmount: NetAmount,
            };
          });
        } else {
          message.error("Max Quantity Reached");
        }
      }
    }
  };

  const onDeleteClick = (record) => {
    const filteredPurchaseData = data?.myPurchaseData?.filter(
      (item) => item?.barcode !== record?.barcode
    );
    const deletedRecord = data?.myPurchaseData?.find(
      (item) => item?.barcode === record?.barcode
    );

    if (deletedRecord) {
      setData((prevData) => {
        const updatedData = {
          ...prevData,
          myPurchaseData: filteredPurchaseData,
          totalBoxes: prevData.totalBoxes - deletedRecord?.boxes,
          totalQuantity: prevData.totalQuantity - deletedRecord?.saleQty,
          totalAmount:
            prevData.totalAmount - deletedRecord?.saleQty * deletedRecord?.wsp,
        };

        return recalculateAmounts(updatedData);
      });
    }
  };

  const recalculateAmounts = (prevData) => {
    // const discountAmt = prevData.totalAmount * (prevData.discount / 100);
    // const finalDiscAmt = prevData.totalAmount - discountAmt;
    // let finalDiscAmt
    // if (prevData.billedGR === false) {
    // finalDiscAmt = prevData.totalAmount - discountAmt;
    // }
    // else {
    // finalDiscAmt = prevData.totalAmount - discountAmt - prevData.grAmount;
    // }
    let discountAmt;
    let finalDiscAmt;
    if (prevData?.billedGR === false) {
      discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

      finalDiscAmt = prevData?.totalAmount - discountAmt;
    } else {
      discountAmt =
        (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
      finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
    }
    const taxAmt = finalDiscAmt * (prevData.tax / 100);
    const taxedAmount = finalDiscAmt + taxAmt;
    const NetAmount = prevData.pf + prevData.labour + taxedAmount;

    return {
      ...prevData,
      discountAmt: discountAmt,
      finalDiscAmt: finalDiscAmt,
      taxAmt: taxAmt,
      taxedAmount: taxedAmount,
      netAmount: NetAmount,
    };
  };

  const onRemoveBoxes = (record) => {
    let barcodeMatchIndex = data?.myPurchaseData?.findIndex(
      (text) => record?.barcode === text?.barcode
    );

    setData((prevData) => {
      const updatedMyPurchaseData = prevData?.myPurchaseData?.map(
        (item, index) =>
          item?.barcode === record?.barcode
            ? {
                ...item,
                saleQty:
                  record?.MOQ < item?.saleQty
                    ? item?.saleQty - record?.MOQ
                    : record?.MOQ,
                boxes:
                  record?.MOQ < item.saleQty
                    ? (item?.saleQty - record?.MOQ) / item?.MOQ
                    : record?.MOQ / item?.MOQ,
                finalAmount:
                  record?.MOQ < item?.saleQty
                    ? (item?.saleQty - record?.MOQ) * item?.wsp
                    : item?.saleQty * item?.wsp,
              }
            : item
      );

      if (record.MOQ < record.saleQty) {
        const totalQuantity = prevData.totalQuantity - record.MOQ;
        const totalBoxes = prevData.totalBoxes - 1;
        const totalAmount =
          prevData.totalAmount -
          prevData.myPurchaseData[barcodeMatchIndex].MOQ *
            prevData.myPurchaseData[barcodeMatchIndex].wsp;

        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
        };
      } else {
        const totalQuantity = prevData.totalQuantity;
        const totalBoxes = prevData.totalBoxes;
        const totalAmount = prevData.totalAmount;

        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
        };
      }
    });

    setData((prevData) => {
      let discountAmt;
      let finalDiscAmt;
      if (prevData?.billedGR === false) {
        discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

        finalDiscAmt = prevData?.totalAmount - discountAmt;
      } else {
        discountAmt =
          (prevData.totalAmount - prevData.grAmount) *
          (prevData.discount / 100);
        finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
      }
      //const finalDiscAmt = prevData.totalAmount - discountAmt;
      const taxAmt = finalDiscAmt * (prevData.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = prevData.pf + prevData.labour + taxedAmount;

      return {
        ...prevData,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount,
      };
    });
  };

  const handleTaxChange = (e) => {
    const tax = e.target.value;
    // let discountAmt = data.totalAmount * (data.discount / 100)
    // let finalDiscAmt = data.totalAmount - discountAmt
    let discountAmt;
    let finalDiscAmt;
    if (data?.billedGR === false) {
      discountAmt = data?.totalAmount * (data?.discount / 100);

      finalDiscAmt = data?.totalAmount - discountAmt;
    } else {
      discountAmt = (data.totalAmount - data.grAmount) * (data.discount / 100);
      finalDiscAmt = data?.totalAmount - discountAmt - data?.grAmount;
    }
    let taxAmt = finalDiscAmt * (tax / 100);
    let taxedAmount = finalDiscAmt + taxAmt;
    let NetAmount = data.pf + data.labour + taxedAmount;

    setData((prevData) => ({
      ...prevData,
      tax: tax,
    }));

    if (data?.myPurchaseData?.length > 0) {
      setData((prevData) => ({
        ...prevData,
        tax: tax,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount,
      }));
    }
  };

  const handlePFChange = (e) => {
    let PF = Number(e.target.value);
    let NetAmount = Number(data.netAmount) - Number(data.pf) + PF;
    setData((prevData) => ({
      ...prevData,
      pf: PF,
    }));
    if (data?.myPurchaseData?.length > 0) {
      setData((prevData) => ({
        ...prevData,
        netAmount: NetAmount,
      }));
    }
  };

  const handleLabourAmtChange = (e) => {
    let labourAmt = Number(e.target.value);
    let NetAmount = Number(data.netAmount) - Number(data.labour) + labourAmt;
    setData((prevData) => ({
      ...prevData,
      labour: labourAmt,
    }));

    if (data?.myPurchaseData?.length > 0) {
      setData((prevData) => ({
        ...prevData,
        netAmount: NetAmount,
      }));
    }
  };

  const handlediscountChange = (e) => {
    let discount = e.target.value;
    // let discountAmt = data.totalAmount * (discount / 100)
    // let finalDiscAmt = data.totalAmount - discountAmt
    let discountAmt;
    let finalDiscAmt;
    if (data?.billedGR === false) {
      discountAmt = data?.totalAmount * (discount / 100);

      finalDiscAmt = data?.totalAmount - discountAmt;
    } else {
      discountAmt = (data.totalAmount - data.grAmount) * (discount / 100);
      finalDiscAmt = data?.totalAmount - discountAmt - data?.grAmount;
    }
    let taxAmt = finalDiscAmt * (data.tax / 100);
    let taxedAmount = finalDiscAmt + taxAmt;
    let NetAmount = data.pf + data.labour + taxedAmount;

    setData((prevData) => ({
      ...prevData,
      discount: e.target.value,
    }));

    if (data?.myPurchaseData?.length > 0) {
      setData((prevData) => ({
        ...prevData,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        discount: e.target.value,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        netAmount: NetAmount,
      }));
    }
  };

  const handleSaleOrder = async () => {
    let sales = [];
    data.myPurchaseData.map((value) => {
      sales.push({
        salesPersonName: value.salesPersonName,
        salesPersonId: value.salesPersonId,
        quantity: value.saleQty,
        MOQ: value.MOQ,
        barcode: value.barcode,
      });
    });
    if (data?.myPurchaseData?.length > 0) {
      let obj = {
        totalQuantity: Number(data.totalQuantity),
        salesOrder: true,
        remark: data.remark,
        pAndf: Number(data.pf),
        labour: Number(data.labour),
        tax: Number(data.tax),
        discount: Number(data.discount),
        userId: data.customerId,
        netAmount: Number(data.netAmount),
        totalAmount: Number(data.totalAmount),
        totalBoxes: Number(data.totalBoxes),
        date: data.saleBillDate,
        isGrAmount: data.billedGR,
        sales: sales,
        srAmount: Number(data.grAmount),
      };
      let response = await dispatch(createSales(obj, history));
      if (response) {
        setData((prevData) => ({
          ...prevData,
          invoiceNumber: response.invoiceId,
          diabledStatus: true,
          myPurchaseData: [],
          saleDate: currentDate,
          saleTime: currentTime,
          saleBillDate: moment(
            `${currentDate} ${currentTime}`,
            "DD/MM/YYYY HH:mm:ss"
          ).toDate(),
          customerName: "",
          customerId: "",
          agentId: "",
          agent: "",
          lrNumber: "",
          lrDate: "",
          remark: "",
          transport: "",
          grAmount: 0,
          barcode: "",
          designNumber: "",
          boxes: 0,
          totalBoxes: 0,
          totalQuantity: 0,
          totalAmount: 0,
          brand: "",
          brandId: "",

          selectedData: "",
          salesPersonId: "",
          salesPersonName: "",
          saleQty: 0,
          discount: 0,
          tax: 5,
          pf: 0,
          h: 0,
          billedGR: false,
          netAmount: 0,
          finalDiscAmt: 0,
          taxAmt: 0,
          taxedAmount: 0,
          discountAmt: 0,
          responseData: [],
        }));

        formRef.current.setFieldsValue({
          salePerson: undefined,
          customer: undefined,
        });
      }
      setModalStatus(false);
    }
  };

  const handleSubmit = async (isSaleOrder) => {
    setLoading(true);
    let sales = [];
    data.myPurchaseData.map((value) => {
      sales.push({
        salesPersonName: value.salesPersonName,
        salesPersonId: value.salesPersonId,
        quantity: value.saleQty,
        MOQ: value.MOQ,
        barcode: value.barcode,
      });
    });
    if (data?.myPurchaseData?.length > 0) {
      let obj = {
        totalQuantity: Number(data.totalQuantity),
        salesOrder: isSaleOrder,
        remark: data.remark,
        pAndf: Number(data.pf),
        labour: Number(data.labour),
        tax: Number(data.tax),
        discount: Number(data.discount),
        userId: data.customerId,
        netAmount: Number(data.netAmount),
        totalAmount: Number(data.totalAmount),
        totalBoxes: Number(data.totalBoxes),
        date: data.saleBillDate,
        isGrAmount: data.billedGR,
        sales: sales,
        srAmount: Number(data.grAmount),
      };

      let response = await dispatch(createSales(obj, history, "", true));

      if (response) {
        setLoading(false);
        setData((prevData) => ({
          ...prevData,
          invoiceNumber: response.invoiceId,
          // diabledStatus: true,
          // myPurchaseData: [],
          // saleDate: currentDate,
          // saleTime: currentTime,
          // saleBillDate: moment(
          //   `${currentDate} ${currentTime}`,
          //   "YYYY-MM-DD HH:mm:ss"
          // ),
          // customerName: "",
          // customerId: "",
          // agentId: "",
          // agent: "",
          // lrNumber: "",
          // lrDate: "",
          // remark: "",
          // transport: "",
          // grAmount: 0,
          // barcode: "",
          // designNumber: "",
          // boxes: 0,
          // totalBoxes: 0,
          // totalQuantity: 0,
          // totalAmount: 0,
          // brand: "",
          // brandId: "",

          // selectedData: "",
          // salesPersonId: "",
          // salesPersonName: "",
          // saleQty: 0,
          // discount: 0,
          // tax: 5,
          // pf: 0,
          // h: 0,
          // billedGR: false,
          // netAmount: 0,
          // finalDiscAmt: 0,
          // taxAmt: 0,
          // taxedAmount: 0,
          // discountAmt: 0,
          // responseData: [],
        }));

        // formRef.current.setFieldsValue({
        //   salePerson: undefined,
        //   customer: undefined,
        //   brand: undefined
        // });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  //Done
  const handleChangeCustomer = (value, option) => {
    const discount = option?.name[3]?.userDiscount
      ? option?.name[3]?.userDiscount
      : 0;
    console.log("discount", discount);
    if (option) {
      setLoading(true);
      const getMatchCustomer = InactiveCustomer?.data?.find(
        (data) => option.value === data._id.toString()
      );
      if (getMatchCustomer) {
        if (getMatchCustomer.grAmount > 0) {
          // const discountAmt = (data.totalAmount - data.grAmount) * (data.discount / 100);
          // const finalDiscAmt = data.totalAmount - discountAmt - getMatchCustomer.grAmount;
          const discountAmt =
            (data.totalAmount - getMatchCustomer.grAmount) * (discount / 100);
          const finalDiscAmt =
            data?.totalAmount - discountAmt - getMatchCustomer?.grAmount;
          const taxAmt = finalDiscAmt * (data.tax / 100);
          const taxedAmount = finalDiscAmt + taxAmt;
          const NetAmount = data.pf + data.labour + taxedAmount;
          setIsCustomerSelect(true);
          setData((prevData) => ({
            ...prevData,
            customerId: option.value,
            customerName: option.children,
            agentId: getMatchCustomer?.agentId?._id,
            agent: getMatchCustomer?.agentId?.name,
            grAmount: getMatchCustomer?.grAmount,
            billedGR: true,
            discountAmt: discountAmt,
            taxAmt: taxAmt,
            taxedAmount: taxedAmount,
            netAmount: NetAmount,
            finalDiscAmt: finalDiscAmt,
            discount: discount,
          }));
        } else {
          const discountAmt = data.totalAmount * (discount / 100);
          const finalDiscAmt = data.totalAmount - discountAmt;
          const taxAmt = finalDiscAmt * (data.tax / 100);
          const taxedAmount = finalDiscAmt + taxAmt;
          const NetAmount = data.pf + data.labour + taxedAmount;

          setData((prevData) => ({
            ...prevData,
            customerId: option.value,
            customerName: option.children,
            agentId: getMatchCustomer?.agentId?._id,
            agent: getMatchCustomer?.agentId?.name,
            grAmount: getMatchCustomer?.grAmount,
            billedGR: false,
            discountAmt: discountAmt,
            taxAmt: taxAmt,
            taxedAmount: taxedAmount,
            netAmount: NetAmount,
            finalDiscAmt: finalDiscAmt,
            discount: discount,
          }));
        }
        setIsCustomerSelect(false);
      }
      setLoading(false);
    }
  };

  const handleBrandChange = (value, option) => {
    const brandName = option.children;
    const brandId = option.value;
    setData((prevData) => ({
      ...prevData,
      brand: brandName,
      brandId: brandId,
    }));
  };

  const handleSalePerson = (value) => {
    if (value) {
      setLoading(true);
      const matchedSalesPerson = SalePersonName?.find(
        (person) => person._id === value
      );
      setData((prevData) => ({
        ...prevData,
        salesPersonId: value,
        salesPersonName: matchedSalesPerson ? matchedSalesPerson.name : "",
      }));
      setIsSalesPersonSelect(false);
      setLoading(false);
    }
  };

  //all done
  const handleBarcodeChange = async (e) => {
    setAddProductLoading(true);
    let barcode = e.target.value;
    setData((prevData) => ({
      ...prevData,
      barcode: barcode,
    }));
    setIsbarcodeEnter(false);
    if (barcode.length === 9) {
      const response = await dispatch(
        getdesignAndBrandComboByBarcode({ barcode: e.target.value })
      );
      if (
        response &&
        response?.status == 200 &&
        response?.data &&
        response?.data?.data.length > 0
      ) {
        setData((prevData) => ({
          ...prevData,
          designNumber: response?.data?.data[0].designNumber,
          brand: response?.data?.data[0].brand,
          brandId: response?.data?.data[0].brandId,
          responseData: response?.data?.data,
        }));

        formRef.current.setFieldsValue({
          brand: response?.data?.data[0].brand,
        });
      } else {
        setData((prevData) => ({
          ...prevData,
          designNumber: "",
          brand: "",
          brandId: "",
          responseData: [],
        }));

        formRef.current.setFieldsValue({
          brand: undefined,
        });
      }
    }
    setAddProductLoading(false);
  };

  const handleDatePicker = (date, dateString, field) => {
    setData((prevData) => ({
      ...prevData,
      [field]: dateString,
    }));
  };

  const handleSaleDateChange = (date, dateString) => {
    let currentTime = moment().format("HH:mm:ss");
    let d = moment(
      dateString + " " + currentTime,
      "DD/MM/YYYY HH:mm:ss"
    ).toDate();

    setData((prevData) => ({
      ...prevData,
      saleDate: dateString,
      saleTime: currentTime,
      saleBillDate: d,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleScan = (data) => {
    // setData((prevData) => ({
    //     ...prevData,
    //     barcode: data,
    //   }));

    onUpdateScreen("", { text: data }, true);
    // console.log("scandata",data)
  };
  const handleScan2 = (data) => {
    // console.log("")
    // setData((prevData) => ({
    //     ...prevData,
    //     barcode: data,
    //   }));
    onUpdateScreen("", { text: data });
    // console.log("scandata",data)
  };

  const getDisabledStatus = (data, addProductLoading) => {
    return !!(data.disabledStatus || data?.invoiceNumber || addProductLoading);
  };

  useEffect(() => {
    if (barcode) {
      onUpdateScreen("", { text: barcode });
    }
  }, [barcode]);

  const onNewScanResult = (decodedText, decodedResult) => {
    if (decodedText && decodedText.length === 9) {
      onUpdateScreen("", { text: decodedText });
    } else {
      message.error("Barcode Not Matched!");
    }
  };

  //   ===================Mohit New Logic Start=====================

  // Useeffect for call first time api call of bills
  useEffect(() => {
    if (location && location.state && location?.state?.invoiceIds?.length > 0) {
      getAllBillData(location?.state.invoiceIds);
    }
  }, [location]);

  // Function to call api for all invoice Ids
  const getAllBillData = async (invoiceIds) => {
    console.log("check line", invoiceIds);
    setLoading(true);
    let obj = { invoiceId: invoiceIds };
    try {
      await dispatch(getSaleDetailsByinvoiceIds(obj)).then((response) => {
        console.log("check outside if", response);

        if (response && response.data && response.data.data.length > 0) {
          console.log("check inside if");
          let responseData = response.data.data || [];
          let newData = [];
          console.log("check inside if", responseData.length);

          for (
            let indexMain = 0;
            indexMain < responseData.length;
            indexMain++
          ) {
            console.log("check loop count", responseData[indexMain]);
            let insideloopData = [];
            for (
              let index = 0;
              index < responseData[indexMain].salesDetails.length;
              index++
            ) {
              console.log("check loop count main");
              insideloopData.push({
                ...responseData[indexMain].salesDetails[index].purchaseDetails,
                saleQty: responseData[indexMain].salesDetails[index].quantity,
                boxes: Number(
                  responseData[indexMain].salesDetails[index].quantity /
                    responseData[indexMain].salesDetails[index].MOQ
                ),
                salesPersonId:
                  responseData[indexMain].salesDetails[index].salesPersonId
                    ?._id,
                salesPersonName:
                  responseData[indexMain].salesDetails[index].salesPersonId
                    ?.name,
              });
            }
            newData = [...newData, ...insideloopData];
          }
          setData((prevData) => ({
            ...prevData,
            myPurchaseData: newData,
          }));
        }
      });
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  //   ===================Mohit New Logic End=====================

  return (
    <div className="add-Sales">
      {console.log("check mybarcodeee", data.myPurchaseData)}
      {/* <button onClick={()=>handleScan2("100111715")}>test</button> */}
      <div className="container-fluid">
        <Row className="title-row">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="page-header">Merge Sales Order Bills</div>
          </Col>
          <Col span={12} align="end">
            <Tooltip title="Reload">
              <ReloadOutlined
                className="reload-icon-sales"
                onClick={() => window.location.reload(true)}
              />
            </Tooltip>
          </Col>
        </Row>
        <hr />

        {loading ? (
          <>
            {" "}
            <div className="d-flex justify-content-center align-items-center">
              <img src={gifLoader} width={"300px"} height={"300px"} />
            </div>{" "}
          </>
        ) : (
          <div className="card mt-4" style={{ border: 0 }}>
            <div className="card-body">
              <Form
                onFinish={handleModalStatus}
                ref={formRef}
                layout="vertical"
              >
                {/* ================================================|| Currently Working ||=================================================== */}
                <Row gutter={16}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Select Customer">
                      <Form.Item
                        name="customer"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Customer",
                          },
                        ]}
                        hasFeedback={!isCustomerSelect ? false : true}
                        validateStatus={!isCustomerSelect ? "" : "error"}
                        help={!isCustomerSelect ? "" : "Please Select Customer"}
                        label="Select Customer"
                      >
                        <Select
                          disabled={data && data.invoiceNumber ? true : false}
                          showSearch
                          placeholder="Select Customer"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onClick={() => {
                            dispatch(getActiveandInactiveCustomer());
                          }}
                          onChange={handleChangeCustomer}
                          loading={loading}
                        >
                          {InactiveCustomer?.data?.map((customer) => (
                            // <Option key={customer._id} value={customer._id}>
                            //   {customer.name}
                            // </Option>
                            <Option
                              name={[
                                customer.transport,
                                customer.agentId,
                                customer.grAmount,
                                customer,
                              ]}
                              value={customer._id}
                            >
                              {customer.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Item label="Agent Name">
                      <Input
                        id="agent"
                        name="agentName"
                        placeholder="Agent Name"
                        value={data?.agent}
                        defaultValue={data?.agent}
                        // onChange={handleChangeCustomer}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Item name="transport" label="Transport">
                      <Input
                        name="transport"
                        disabled
                        placeholder="Transport"
                        value={data?.transport}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Select Sale Person">
                      <Form.Item
                        label="Select Sales Person"
                        name="salePerson"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Person",
                          },
                        ]}
                        hasFeedback={!isSalesPersonSelect ? false : true}
                        validateStatus={!isSalesPersonSelect ? "" : "error"}
                        help={
                          !isSalesPersonSelect ? "" : "Please Select Person"
                        }
                      >
                        <Select
                          name="salePerson"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          placeholder="Select Sale Person"
                          onChange={handleSalePerson}
                          onClick={() => {
                            dispatch(getCustomers("SALES"));
                          }}
                          loading={loading}
                        >
                          {SalePersonName?.map((saleperson) => (
                            <Option key={saleperson._id} value={saleperson._id}>
                              {saleperson.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Enter Barcode">
                      <Form.Item
                        name="barcode"
                        rules={[
                          {
                            required: data.barcode.length === 0 ? true : false,
                            message: "Please Enter Barcode",
                          },
                        ]}
                        hasFeedback={!isbarcodeEnter ? false : true}
                        validateStatus={!isbarcodeEnter ? "" : "error"}
                        help={!isbarcodeEnter ? "" : "Please Enter Barcode"}
                        label="Enter Barcode"
                      >
                        <BarcodeReader
                          //   onError={handleError}
                          onScan={handleScan}
                        />
                        <Input
                          disabled={data && data.invoiceNumber ? true : false}
                          name="barcode"
                          placeholder="Enter Barcode"
                          value={data.barcode}
                          maxLength={9}
                          onChange={handleBarcodeChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Select Brand">
                      <Form.Item name="brand" label="Select Brand">
                        <Select
                          showSearch
                          disabled={data && data.invoiceNumber ? true : false}
                          placeholder="Select Brand"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={handleBrandChange}
                          onClick={() => {
                            dispatch(getBrandBySearch());
                          }}
                        >
                          {brandNameSearch?.data?.map((brand) => (
                            <Option
                              key={brand._id}
                              name={brand._id}
                              value={brand._id}
                            >
                              {brand.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Enter Design Number">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Design Number",
                          },
                        ]}
                        label="Enter Design Number"
                      >
                        <Input
                          disabled={data && data.invoiceNumber ? true : false}
                          name="designNumber"
                          value={data.designNumber}
                          placeholder="Enter Design Number"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Tooltip title="Enter Remark">
                      <Form.Item label="Enter Remark">
                        <Input
                          name="remark"
                          placeholder="Enter Remark"
                          value={data.remark}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={16} align="center">
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Button
                      style={{
                        // width: "50%",
                        marginTop: "10px",
                        height: "32px",
                        background: "#005C9E",
                        borderColor: "#005C9E",
                      }}
                      type="danger"
                      icon={<PlusOutlined style={{ marginTop: "5px" }} />}
                      disabled={getDisabledStatus(data, addProductLoading)}
                      htmlType="submit"
                      className="save-button"
                      onClick={() => {
                        if (data.salesPersonName === "") {
                          setIsSalesPersonSelect(true);
                        }
                        if (data.customerName === "") {
                          setIsCustomerSelect(true);
                        }
                        if (data.barcode === "") {
                          setIsbarcodeEnter(true);
                        }
                      }}
                      onSubmit={handleModalStatus}
                    >
                      Add Product
                    </Button>
                  </Col>
                </Row>

                <div className="my-4">
                  <h4>Products</h4>
                  <Row gutter={16} className="mt-2 mb-4">
                    <ShowSalesDetails
                      myPurchaseData={data.myPurchaseData}
                      deleteSale={onDeleteClick}
                      onAddBoxes={onAddingBoxes}
                      onRemoveBoxes={onRemoveBoxes}
                    />
                  </Row>
                  <Row gutter={16}>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Tooltip title="Total Boxes">
                        <Form.Item label="Total Boxes">
                          <Input
                            type="number"
                            placeholder="Total Boxes"
                            name="totalBoxes"
                            value={data.totalBoxes}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Tooltip title="Total Quantity">
                        <Form.Item label="Total Quantity">
                          <Input
                            placeholder="Total Quantity"
                            type="number"
                            name="totalQuantity"
                            value={data.totalQuantity}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5}>
                      <Tooltip title="GR Amount">
                        <Form.Item label="GR Amount">
                          <Input
                            placeholder="GR Amount"
                            type="number"
                            name="grAmount"
                            value={data?.grAmount.toFixed(2)}
                            defaultValue={data?.grAmount.toFixed(2)}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="GR Amount Used Check">
                        <Form.Item style={{ marginBottom: "-18px" }}>
                          <Checkbox
                            onChange={handleGrUsedCheck}
                            key={data.billedGR}
                            defaultChecked={data.billedGR}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Tooltip title="Total Amount">
                        <Form.Item label="Total Amount">
                          <Input
                            placeholder="Total Amount"
                            type="number"
                            name="totalAmount"
                            value={data?.totalAmount.toFixed(2)}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Enter Discount %">
                        <Form.Item label="Enter Discount %">
                          <Input
                            placeholder="Enter Discount %"
                            name="discount"
                            type="number"
                            // formatter={(value) => `${value}%`}
                            value={data.discount}
                            // defaultValue={data.discount}
                            onChange={handlediscountChange}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Discount Amount">
                        <Form.Item label="Discount Amount">
                          <Input
                            type="number"
                            placeholder="Discount Amount"
                            name="DiscountAmt"
                            value={data?.discountAmt.toFixed(2)}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Total Discounted Amount">
                        <Form.Item label="Total Discounted Amount">
                          <Input
                            type="number"
                            placeholder="Total Discounted Amount"
                            name="finalDiscAmt"
                            value={data?.finalDiscAmt.toFixed(2)}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Enter Tax %">
                        <Form.Item label="Enter Tax %">
                          <Input
                            disabled={data && data.invoiceNumber ? true : false}
                            placeholder="Enter Tax %"
                            name="tax"
                            value={data?.tax}
                            type="number"
                            defaultValue={data?.tax}
                            onChange={handleTaxChange}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Taxed Amount">
                        <Form.Item label="Taxed Amount">
                          <Input
                            type="number"
                            placeholder="Taxed Amount"
                            name="finalAmt"
                            value={data?.taxedAmount.toFixed(2)}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Final Amount">
                        <Form.Item label="Final Amount">
                          <Input
                            type="number"
                            placeholder="Final Amount"
                            name="FnlAmt"
                            disabled
                            value={data?.netAmount.toFixed(2)}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="P&F Amount">
                        <Form.Item label="P&F Amount">
                          <Input
                            type="number"
                            placeholder="P&F Amount"
                            name="P&F"
                            defaultValue={data?.pf.toFixed()}
                            onChange={(e) => {
                              handlePFChange(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Labour Amount">
                        <Form.Item label="Labour Amount">
                          <Input
                            type="number"
                            placeholder="Labour Amount"
                            name="labour"
                            defaultValue={data?.labour.toFixed()}
                            onChange={(e) => {
                              handleLabourAmtChange(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={8} sm={8} md={8} lg={8}>
                      <Tooltip title="Net Amount">
                        <Form.Item label="Net Amount">
                          <Input
                            type="number"
                            placeholder="Net Amount"
                            name="NetAmt"
                            disabled
                            value={data?.netAmount.toFixed(2)}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>

                <Row
                  gutter={16}
                  style={{ marginTop: "15px" }}
                  align="center"
                  justify="center"
                >
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Form.Item>
                      {data.netAmount == "" ||
                      data.customerId == "" ||
                      data.netAmount <= 0 ? (
                        <Button
                          disabled
                          size="large"
                          block
                          type="primary"
                          // className="save-button"
                          style={{ color: "#fff" }}
                          // onClick={handleSubmit}
                        >
                          Sale Order <StopOutlined />
                        </Button>
                      ) : data && data.invoiceNumber ? (
                        <>
                          <Button
                            size="large"
                            block
                            type="primary"
                            className="save-button"
                            style={{ color: "#fff" }}
                            // onClick={() => window.location.reload(true)}
                          >
                            Sale Order <StopOutlined />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            disabled={data.diabledStatus}
                            size="large"
                            block
                            type="primary"
                            className="save-button"
                            style={{ color: "#fff" }}
                            onClick={() => handleSubmit(true)}
                          >
                            Sale Order
                          </Button>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Form.Item>
                      {data.netAmount == "" ||
                      data.customerId == "" ||
                      data.netAmount <= 0 ? (
                        <Button
                          disabled
                          size="large"
                          block
                          type="primary"
                          // className="save-button"
                          style={{ color: "#fff" }}
                          // onClick={handleSubmit}
                        >
                          Save Bill <StopOutlined />
                        </Button>
                      ) : data && data.invoiceNumber ? (
                        <>
                          <Button
                            size="large"
                            block
                            type="primary"
                            className="save-button"
                            style={{ color: "#fff" }}
                            onClick={() => window.location.reload(true)}
                          >
                            Create New Bill
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            disabled={data.diabledStatus}
                            size="large"
                            block
                            type="primary"
                            className="save-button"
                            style={{ color: "#fff" }}
                            onClick={() => handleSubmit(false)}
                          >
                            Save Bill
                          </Button>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={24} lg={6}>
                    <Form.Item>
                      {data.netAmount == "" ||
                        data.customerId == "" ||
                        data.netAmount <= 0 ? (
                        <Button
                          disabled
                          size="large"
                          block
                          className="close-modal"
                          style={{}}
                          onClick={handleSaleOrder}
                        >
                          Create Sale Order <StopOutlined />
                        </Button>
                      ) : (
                        <Button
                          disabled={data.diabledStatus}
                          size="large"
                          block
                          className="close-modal"
                          style={{}}
                          onClick={handleSaleOrder}
                        >
                          Create Sale Order
                        </Button>
                      )}
                    </Form.Item>
                  </Col> */}
                </Row>
                <Row
                  gutter={16}
                  style={{ marginTop: "15px" }}
                  align="center"
                  justify="center"
                >
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Form.Item>
                      {data.invoiceNumber ? (
                        console.log("check")
                      ) : (
                        // <ViewGenerateBill
                        //   invoiceId={data.invoiceNumber}
                        //   buttonType="Add"
                        //   mobileStatus={true}
                        // />
                        <Button
                          className="generate-button"
                          block
                          disabled
                          size="large"
                          // type="primary"
                          style={{ color: "#fff", background: "#fa5d25" }}
                        >
                          Generate Bill <StopOutlined />
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MergeSalesOrdersBill;
