import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ecomReturnAcceptedByAdmin,
  getProductsBySalesReturnId,
} from "../../../../redux/actions/ecomSaleAction";
import {
  Table,
  Modal,
  Tooltip,
  Button,
  message,
  Checkbox,
  Select,
  Row,
  Input,
  Col,
  Form,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import gifLoader from "../../../../assets/images/giphy.gif";

const { Column } = Table;
const { Option } = Select;

const EditAndAcceptEcomReturnProduct = ({
  ecomsalesId,
  ecomSalesReturnId,
  orderStatus,
  tax,
}) => {
  const dispatch = useDispatch();
  const ecomSaleReturnData = useSelector(
    (state) => state.ecomSaleData.ecomSalesRetutnProductById
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [maxQuantities, setMaxQuantities] = useState({});
  const [moqs, setMoqs] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const [netAmount, setNetAmount] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [returnReason, setReturnReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const returnReasons = [
    { label: "Damaged Item" },
    { label: "Wrong Item Sent" },
    { label: "Not Satisfied with Product" },
    { label: "Other" },
  ];

  useEffect(() => {
    if (ecomSaleReturnData && ecomSaleReturnData.data) {
      const updatedQuantities = {};
      const updatedMaxQuantities = {};
      const updatedMoqs = {};
      let calculatedTotalAmount = 0;

      ecomSaleReturnData.data.forEach((item) => {
        updatedQuantities[item._id] = item.quantity;
        updatedMaxQuantities[item._id] = item.maxQuantity || item.quantity;
        updatedMoqs[item._id] = item.MOQ || 1;

        const itemPrice =
          Number(item?.wsp) -
          Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
        const itemTotal = updatedQuantities[item._id] * itemPrice;
        calculatedTotalAmount += itemTotal;
      });
      const calTaxedAmount =
        (calculatedTotalAmount.toFixed() * Number(tax)) / 100;
      let calNetAmount = calTaxedAmount + calculatedTotalAmount;
      if (calculatedTotalAmount < 10000 && calculatedTotalAmount > 0) {
        calNetAmount += 100;
      }

      setQuantities(updatedQuantities);
      setMaxQuantities(updatedMaxQuantities);
      setMoqs(updatedMoqs);
      setTotalAmount(calculatedTotalAmount.toFixed());
      setTaxAmount(calTaxedAmount.toFixed());
      setNetAmount(calNetAmount.toFixed());
    }
  }, [ecomSaleReturnData]);

  const showModal = () => {
    console.log("check mohit hellotest", ecomSalesReturnId);
    dispatch(
      getProductsBySalesReturnId({ ecomSalesReturnId: ecomSalesReturnId })
    );
    setVisible(true);
  };

  const handleModalCancel = () => {
    dispatch(getProductsBySalesReturnId("reset"));
    setSelectedItems([]);
    setVisible(false);
  };

  const onAddingQuantity = (item) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[item._id] || item.quantity;
      const maxQuantity = maxQuantities[item._id] || item.quantity;
      const moq = moqs[item._id] || Number(item.MOQ);

      if (currentQuantity < maxQuantity) {
        const newQuantity = Math.min(currentQuantity + moq, maxQuantity);
        const selectedProduct = selectedItems.find((selctItem) => {
          if (selctItem._id === item._id) {
            selctItem.quantity = newQuantity;
          }
        });

        const itemPrice =
          Number(item?.wsp) -
          Number(item?.wsp) * (Number(item?.salesDiscount) / 100);

        const updatedTotalAmount = Math.round(
          Number(totalAmount) + itemPrice * moq
        );
        const updatedTaxAmount = Math.round((updatedTotalAmount * 5) / 100);
        const updatedNetAmount = Math.round(
          updatedTotalAmount + updatedTaxAmount
        );

        setTotalAmount(updatedTotalAmount);
        setTaxAmount(updatedTaxAmount);
        setNetAmount(updatedNetAmount);

        return {
          ...prevQuantities,
          [item._id]: newQuantity,
        };
      }
      message.warning("Maximum Quantity Reached");
      return prevQuantities;
    });
  };

  const onRemoveQuantity = (item) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[item._id] || item.quantity;
      const moq = moqs[item._id] || Number(item.MOQ);

      if (currentQuantity > 0) {
        const newQuantity = Math.max(currentQuantity - moq, 0);
        const selectedProduct = selectedItems.find((selctItem) => {
          if (selctItem._id === item._id) {
            selctItem.quantity = newQuantity;
          }
        });

        const itemPrice =
          Number(item?.wsp) -
          Number(item?.wsp) * (Number(item?.salesDiscount) / 100);

        const updatedTotalAmount = Math.max(
          Number(totalAmount) - itemPrice * moq,
          0
        );
        const updatedTaxAmount = Math.max((updatedTotalAmount * 5) / 100, 0);
        const updatedNetAmount = Math.max(
          updatedTotalAmount + updatedTaxAmount,
          0
        );

        setTotalAmount(updatedTotalAmount.toFixed());
        setTaxAmount(updatedTaxAmount.toFixed());
        setNetAmount(updatedNetAmount.toFixed());

        return {
          ...prevQuantities,
          [item._id]: newQuantity,
        };
      }
      message.warning("Minimum Quantity Reached");
      return prevQuantities;
    });
  };

  const preparedData =
    ecomSaleReturnData && ecomSaleReturnData.data
      ? ecomSaleReturnData.data.map((item) => ({
          ...item,
          quantity:
            quantities[item._id] !== undefined
              ? quantities[item._id]
              : item.quantity,
        }))
      : [];

  // console.log("kkkkkkkk", preparedData);

  const totalQuantity = preparedData.reduce((sum, item) => {
    return sum + (quantities[item._id] || 0);
  }, 0);

  const totalBoxes = preparedData.reduce((sum, item) => {
    return sum + (item.totalBox || 0);
  }, 0);

  const submitQuantity = async () => {
    setButtonLoading(true);
    const reqBody = {
      ecomsalesId: ecomsalesId,
      returnItems: selectedItems,
      ecomSalesReturnId : ecomSalesReturnId
    };
    await dispatch(ecomReturnAcceptedByAdmin(reqBody))
      .then((response) => {
        console.log("check my response", response)
        message.success("Return Accepted Successfully!");
      })
      .catch((error) => {
        message.error("Something Went Wrong");
        console.error("Error approving order:", error);
      });
    setTimeout(() => {
      setButtonLoading(false);
      handleModalCancel();
      window.location.reload();
    }, 1000);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (
        prevSelectedItems.some((selectedItem) => selectedItem._id === item._id)
      ) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem._id !== item._id
        );
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  // Handle Remark
  const handleRemarkChange = (e) => {
    setCustomReason(e);
  };

  // Handle Reason for return
  const handleReasonChange = (e) => {
    setReturnReason(e);
  };
  return (
    <div>
      {console.log("selectedItems", ecomSaleReturnData)}
      <Button disabled={orderStatus == 0} type="primary" onClick={showModal}>
        <Tooltip title="Edit Product Quantity">Accept</Tooltip>
      </Button>
      <Modal
        open={
          ecomSaleReturnData &&
          ecomSaleReturnData.data &&
          ecomSaleReturnData.data.length > 0
            ? visible
            : false
        }
        footer={null}
        onCancel={handleModalCancel}
        title="Accept Ecom Sales Return"
        style={{ top: 20 }}
        destroyOnClose={true}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={1500}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={gifLoader}
              width={"300px"}
              height={"300px"}
              alt="Loading..."
            />
          </div>
        ) : (
          <>
            <div className="credit-payment-edit py-2">
              {/* <div className="">
                <span
                  className=""
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  Total Amount:
                </span>
                <span
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="fw-semibold mr-3"
                >
                  {" "}
                  {totalAmount}
                </span>
              </div>
              <div className="">
                <span
                  className=""
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  Tax:
                </span>
                <span
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="fw-semibold mr-3"
                >
                  {" "}
                  {taxAmount}
                </span>
              </div>
              <div className="">
                <span
                  className=""
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  Net Amount:
                </span>
                <span
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="fw-semibold text-success mr-3"
                >
                  {" "}
                  &#8377;{netAmount}
                </span>
              </div> */}
            </div>
            <Table
              dataSource={preparedData}
              bordered
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="Select"
                key="select"
                render={(text, item) => (
                  <Checkbox
                    disabled={item?.quantity == 0}
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem?._id === item?._id
                    )}
                    onChange={() => handleCheckboxChange(item)}
                  />
                )}
                width={80}
              />
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={120}
                render={(value) => value}
              />
              <Column
                title="Design Number"
                dataIndex="designNumber"
                key="designNumber"
                render={(value) => value}
                width={180}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                width={100}
                render={(value) => value}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                render={(value) => value}
                width={100}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={100}
                render={(value) => value}
              />
              <Column
                title="Quantity"
                dataIndex="quantity"
                key="quantity"
                render={(value, item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="sales-btn"
                      type="button"
                      onClick={() => onRemoveQuantity(item)}
                    >
                      <Tooltip title="Remove Quantity">
                        <MinusCircleOutlined />
                      </Tooltip>
                    </button>
                    <span style={{ margin: "0 10px" }}>
                      {quantities[item._id]}
                    </span>
                    <button
                      className="sales-btn"
                      type="button"
                      onClick={() => onAddingQuantity(item)}
                    >
                      <Tooltip title="Add Quantity">
                        <PlusCircleOutlined />
                      </Tooltip>
                    </button>
                  </div>
                )}
                width={120}
              />
              {/* <Column
                title="Price"
                key="wsp"
                render={(value, item) => {
                  const finalPrice =
                    Number(item?.wsp) -
                    Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
                  return finalPrice.toFixed();
                }}
                width={120}
              /> */}
            </Table>
            <div className="d-flex justify-content-center mt-4">
              <Button
                className=""
                loading={buttonLoading}
                type="primary"
                htmlType="submit"
                onClick={submitQuantity}
                disabled={selectedItems.length > 0 ? false : true}
              >
                Accept Return
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default EditAndAcceptEcomReturnProduct;
