import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import "./App.css";
import "antd/dist/antd.css";
import adminDashboard from "./components/admin/adminDashboard";
import ScanBarcode from "./components/admin/barcode/ScanBarcode";
// import { createBrowserHistory } from "history";
// export const history = createBrowserHistory();
import history from "./history";
import BrandsCloumn from "./components/brands/BrandsCloumn";
import BlogStandard from "./components/brands/BlogStandard";
import ServerOff500 from "./components/ServerOff500";
import { getFullCart } from "./redux/actions/cartActions";
import { useDispatch } from "react-redux";
import Warehouse from "./components/admin/warehouse/Warehouse";
import WarehouseList from "./components/admin/warehouse/WarehouseList";
import jwt_decode from "jwt-decode";

const Token = localStorage.getItem("token");
// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/Myorders"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
// const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const SalesLoginRegister = lazy(() =>
  import("./pages/other/SalesLoginRegister")
);

const App = (props) => {
  const dispatch = useDispatch();
  const [userData, setuserData] = useState();

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
    // dispatch(getFullCart())
  });

  useEffect(() => {
    if (Token) {
      let decToken = jwt_decode(Token);
      setuserData(decToken);
    }
  }, [Token]);

  return (
    <ToastProvider>
      <BreadcrumbsProvider>
        <Router history={history}>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process?.env?.PUBLIC_URL + "/"}
                  // component={HomeFashion}
                  component={
                    userData?.role != "629346b53e2dcbf2f6d43763"
                      ? HomeFashion
                      : adminDashboard
                  }
                />
                {/* Shop pages */}
                <Route
                  path={process?.env?.PUBLIC_URL + "/shop-grid-standard"}
                  component={ShopGridStandard}
                />
                {/* Shop product pages */}
                <Route
                  path={process?.env?.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/my-orders"}
                  component={MyOrders}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                {/**<Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                /> */}
                <Route
                  path={process?.env?.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route exact path={"/barcode"} component={ScanBarcode} />
                <Route exact path={"/dagaImpex"} component={adminDashboard} />
                <Route
                  exact
                  path={"/dagaImpex/dealers"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/brands"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/category"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/color"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/group"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/location"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/season"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/section"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/style"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/size"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/ageGroup"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/floor"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchase"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addPurchase"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addPurchaseOrder"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseOrderList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updatePurchaseOrderList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseOrderGenerateBill"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseOrderGenerateBillUpdate"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/upDatePurchase"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/generator"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseBill"}
                  component={adminDashboard}
                />{" "}
                <Route
                  exact
                  path={"/dagaImpex/addSales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesOrder"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/merge-SalesOrders-bills"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/mergeSalesBill"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/inventory"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/availableStock"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/flashSales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/ecomProduct"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/ecomOnlineSales"}
                  component={adminDashboard}
                />
                  <Route
                  exact
                  path={"/dagaImpex/ecomSalesReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/ecomSaleCreditPayment"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/ecomSaleOnlinePayment"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/agentlist"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addSalesReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updateSalesReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addUser"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesReturns"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/saleReturnBill"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesPdf"}
                  component={adminDashboard}
                />

                <Route
                  exact
                  path={"/dagaImpex/salesReturnsDefective"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/defectedProductBill"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addPurchaseReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updatePurchaseReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseReturns"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseReturnBillList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/PO"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesCustomerReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/listUsers"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/customers"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/employees"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/settleGR"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseBillReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesSummaryReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/bestSellingItemReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesReturnReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/dealerPurchaseReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/barcodeReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/purchaseReturnReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/fastMovingItemsReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/nonMovingItemsReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesPersonReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesPersonComparisionReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/stockAgeingReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/agentWiseReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/topCustomerReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/stockReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/customerReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/customerDummyReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/inactiveCustomerReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/CreditandDebitReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updateProfile"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/deletePurchase"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/deleteSales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updateSales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/openingStock"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/BIreport/report-1"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/BIreport/grossprofitreport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/BIreport/stockageReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/BIreport/sales-and-salesAge-Report"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/recycle/purchase"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/recycle/purchaseReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/recycle/sales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/recycle/salesReturn"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/recycle/salesReturnDefective"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/warehouse"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/warehouse/inventories"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/warehouse/sales-out"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/warehouse/warehouse-purchase-bill"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/warehouse/addwarehousesale"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/POList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/report"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/sample-barcode"}
                  component={adminDashboard}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process?.env?.PUBLIC_URL + "/salesPerson/login"}
                  component={SalesLoginRegister}
                />
                <Route
                  exact
                  path={"/dagaImpex/AddNewSales"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/updateNewSales"}
                  component={adminDashboard}
                />
                <Route path={"/dagaBrands"} component={BlogStandard} />
                <Route
                  exact
                  path={"/dagaImpex/changeCategories"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesBillNew"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/bestSellingBrandReport"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/floorGoodsTransfer"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/texture"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/bannerUpload"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/salesApp/inventory"}
                  component={adminDashboard}
                />
                 <Route
                  exact
                  path={"/dagaImpex/salesApp/stock"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/addLR"}
                  component={adminDashboard}
                />
                  <Route
                  exact
                  path={"/dagaImpex/LRList"}
                  component={adminDashboard}
                />
                <Route
                  exact
                  path={"/dagaImpex/stock-verification-details"}
                  component={adminDashboard}
                />

                <Route exact component={NotFound} />
                <Route exact path="/500" component={ServerOff500} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
