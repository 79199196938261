/* /////////////////////////////////////// */
/*
Date              created by               Modifications
 
18-07-2023        Mohit                    showDataOnList update start from line 736              

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  Row,
  Col,
  message,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tooltip,
  Spin,
} from "antd";
import {
  HomeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  StopOutlined,
  PercentageOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getDealers } from "../../../../redux/actions/dealerAction";
import { getBrandBySearch } from "../../../../redux/actions/brandAction";
import { getCategories } from "../../../../redux/actions/categoryAction";
import { getGroups } from "../../../../redux/actions/groupAction";
import { getStyles } from "../../../../redux/actions/styleAction";
import { getSeasons } from "../../../../redux/actions/seasonAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import { getLocations } from "../../../../redux/actions/locationAction";
import { getSizes } from "../../../../redux/actions/sizeAction";
import { getColors } from "../../../../redux/actions/colorAction";
import { getAgeGroups } from "../../../../redux/actions/ageGroupAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import { getTexture } from "../../../../redux/actions/textureAction";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ShowPurchaseDetails from "./ShowPurchaseDetails";
import { addPurchase } from "../../../../redux/actions/purchaseAction";
import { getLookupData } from "../../../../redux/actions/purchaseAction";
import { others } from "../../../../util/constants";
import { uuid } from "uuidv4";
import LookupModal from "./LookupModal";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCustomers } from "../../../../redux/actions/customerAction";

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
let purchase = {};

class AddPurchase extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      purchasePersonId: "",
      purchasePersonName: "",
      isWarehouse: false,
      locationDis: false,
      data: {
        salesTax : 5,
        loading: false,
        values: {},
        dealerName: "",
        dealerId: "",
        billNo: "",
        billDate: moment(new Date()).format("DD/MM/YYYY"),
        gst: "",
        markup: "",
        brandName: "",
        brandId: "",
        categoryName: "",
        categoryId: "",
        groupName: "",
        groupId: "",
        styleName: "",
        styleId: "",
        seasonName: "",
        seasonId: "",
        sectionName: "",
        sectionId: "",
        locationName: "airport road",
        locationId: "6410742794ee79805682e477",
        MOQ: "",
        others: others,
        HSNCode: "",
        designNumber: "",
        sizeName: "",
        sizeId: "",
        colorName: "",
        colorId: "",
        purchasePrice: "",
        wsp: "",
        quantity: "",
        totalBox: "",
        discount: 0,
        tax: 5,
        barcode: "",
        totalQuantity: "",
        netAmount: 0,
        finalDiscount: 0,
        totalAmount: 0,
        errors: {},
        myPurchaseData: [],
        discountedAmount: 0,
        taxedAmount: 0,
        finalAmount: 0,
        floorName: "",
        floorId: "",
        textureName: "",
        textureId: "",
        ageGroupId: "",
        ageGroupName: "",
      },
      visible: false,
      spinLoad: false,
    };
    this.onChange = this.onChange.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }

  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault();
    }
  };

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  componentDidMount() {
    this.props.getLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      // //Perform some operation here
      // let data = this.state.data;
      // data.errors = this.props.errors;
      // this.setState({ data });
      message.error(this.props.errors.message);
    }
  }

  handleWheel = (event) => {
    event.preventDefault();
  };

  handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  onChange(event, key, temp) {
    var wsp = Number(this.state.data.others);
    var totalBox = 0;
    var finalAmount = 0;
    var netAmount = 0;
    if (event.target.name == "MOQ") {
      let data = this.state.data;
      data[key] = event.target.value;
      this.setState({ data });

      return;
    }
    if (
      this.state.data.totalAmount != undefined &&
      event.target.name == "finalDiscount"
    ) {
      finalAmount =
        this.state.data.totalAmount -
        (this.state.data.totalAmount * Number(event.target.value)) / 100;
      let data = this.state.data;
      data[event.target.name] = event.target.value;
      data[key] = Math.round(finalAmount);
      data["discountedAmount"] = Math.round(
        this.state.data.totalAmount - finalAmount
      );
      if (this.state.data.tax) {
        var netAmount = Math.round(
          finalAmount + (finalAmount * Number(this.state.data.tax)) / 100
        );
        data["netAmount"] = Math.round(netAmount);
        data["taxedAmount"] = Math.round(netAmount - finalAmount);
      }
      this.setState({ data });

      return true;
    }
    if (
      this.state.data.finalAmount != undefined &&
      event.target.name == "tax"
    ) {
      netAmount = Math.round(
        this.state.data.finalAmount +
          (this.state.data.finalAmount * Number(event.target.value)) / 100
      );
      let data = this.state.data;
      data[event.target.name] = event.target.value;
      data[key] = Math.round(netAmount);
      data["taxedAmount"] = Math.round(netAmount - this.state.data.finalAmount);
      this.setState({ data });

      return true;
    }
    if (
      this.state.data.markup != undefined &&
      event.target.name == "purchasePrice"
    ) {
      let name = "purchasePrice" + temp;
      let wsp = this.state.data.others;
      let v = event.target.value;
      wsp =
        Number(wsp) +
        Number(v) +
        (Number(event.target.value) * Number(this.state.data.markup)) / 100;

      let data = this.state.data;



      data[name] = event.target.value;
      data[key] = Math.round(wsp);
      this.setState({ data });

      return true;
    }
    if (
      this.state.data["MOQ" + key] != undefined &&
      event.target.name == "quantity" + key
    ) {
      totalBox =
        Number(event.target.value) / Number(this.state.data["MOQ" + key]);
      let name = "boxes" + temp;
      let data = this.state.data;
      data[event.target.name] = event.target.value;
      data[name] = Math.round(totalBox);
      this.setState({ data });
      return true;
    }

    if (event.target.name == "markup") {
      let data = this.state.data;
      let wspCount = 0;
      for (const key in data) {
        if (key.startsWith("wsp")) {
          wspCount++;
        }
      }
      for (let i = 0; i <= wspCount - 2; i++) {
        let purchasePrice = data[`purchasePrice${i}`];
        let total = (purchasePrice * event.target.value) / 100;
        let final =
          Number(purchasePrice) +
          Number(total) +
          Number(this.state.data.others);
        data[`wsp${i}`] = final;
      }
      this.setState({ data });
    }

    // if (event.target.name == "salesTax" && event.target.value > 0) {
    //   let data = this.state.data;
    //   data[event.target.name] = event.target.value;
    // }else{
    //   message.warn("Sales Tax Must Be Greater Then 0")
    // }


    let data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data });
    if (event.target.name == "designNumber") {
      this.showLookupDesignNumber(event.target.value);
    }
  }
  onDateChange = (date, dateString) => {
    let data = this.state.data;
    let date_ = dateString;
    // data["billDate"] = dateString;
    data["billDate"] = date_;
    this.setState({ data });
  };
  handleChangeDealer = (key, value) => {
    let data = this.state.data;
    data["dealerId"] = value.value;
    data["dealerName"] = value.name[0];
    data["gst"] = value.name[1];
    this.setState({ data });
    this.debouncedGetData("dealer");
  };
  handleChangeBrand = (key, value) => {
    let data = this.state.data;
    data["brandId"] = value.value;
    data["brandName"] = value.name[0];
    data["markup"] = value.name[1];
    this.setState({ data });
    this.debouncedGetData("brand");
  };
  handleChangeCategory = (key, value) => {
    let data = this.state.data;
    data["categoryId"] = value.value;
    data["categoryName"] = value.name;
    data["styleId"] = "";
    data["styleName"] = "";
    this.setState({ data });
    this.formRef.current.setFieldsValue({ style: undefined });
    this.debouncedGetData("category");
  };
  handleChangeGroup = (key, value) => {
    let data = this.state.data;
    data["groupId"] = value.value;
    data["groupName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("group");
  };
  handleChangeStyle = (key, value) => {
    let data = this.state.data;
    data["styleId"] = value.value;
    data["styleName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("style");
  };
  handleChangeSeason = (key, value) => {
    let data = this.state.data;
    data["seasonId"] = value.value;
    data["seasonName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("season");
  };
  handleChangeSection = (key, value) => {
    let data = this.state.data;
    data["sectionId"] = value.value;
    data["sectionName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("section");
  };
  handleChangeLocation = (key, value) => {
    let data = this.state.data;
    data["locationId"] = value.value;
    data["locationName"] = value.name;

    // Find the selected location from state
    const selectedLocation = this.props.locations?.find(
      (location) => location._id === value.value
    );

    if (selectedLocation && selectedLocation?.locationType !== undefined) {
      const isWarehouse = selectedLocation.locationType !== 0;
      this.setState(
        {
          isWarehouse: isWarehouse,
        },
        () => {
          console.log("Updated isWarehouse:", this.state.isWarehouse);
        }
      );
    }
    this.setState({ data });

    this.debouncedGetData("location");
  };

  handleChangeSize = (key, value) => {
    let data = this.state.data;
    data["sizeId"] = value.value;
    data["sizeName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("size");
  };
  handleChangeColor = (key, value) => {
    let data = this.state.data;
    data["colorId"] = value.value;
    data["colorName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("color");
  };
  handleChangeFloor = (key, value) => {
    let data = this.state.data;
    data["floorId"] = value.value;
    data["floorName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("floor");
  };

  handleChangeTexture = (key, value) => {
    let data=this.state.data;
    let textureNames=[];
    textureNames=value?.map((obj)=> ({
      name:obj.name,
      id:obj.value,
    })
  ) 
    data["textureName"]=textureNames
    let textureIds=[];
    textureIds=value?.map((obj)=>obj.value);
    data["textureId"]=textureIds;
    this.setState({data});
  };
  handleChangeAgeGroup = (key, value) => {
    let data = this.state.data;
    data["ageGroupId"] = value.value;
    data["ageGroupName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("ageGroup");
  };
  handleChangePurchaser = (key, value) => {
    let data = this.state.data;
    // data["purchasePersonId"] = value.value;
    // data["purchasePersonName"] = value.name;
    this.setState({
      purchasePersonId: value.value,
      purchasePersonName: value.name,
    });
    this.debouncedGetData("purchaser");
  };
  getData = (name, value) => {
    if (name == "dealer") this.props.getDealers();
    if (name == "brand") this.props.getBrandBySearch(value);
    if (name == "category") this.props.getCategories();
    if (name == "group") this.props.getGroups();
    if (name == "style") {
      if (this.state.data.categoryId != "") {
        this.props.getStyles(this.state.data.categoryId);
      } else {
        message.warning("Please Select Category");
      }
    }
    if (name == "season") this.props.getSeasons();
    if (name == "section") this.props.getSections();
    if (name == "location") this.props.getLocations();
    if (name == "size") this.props.getSizes();
    if (name == "color") this.props.getColors();
    if (name == "floor") this.props.getFloors();
    if (name == "texture") this.props.getTexture();
    if (name == "ageGroup") this.props.getAgeGroups();
    if (name == "purchaser") this.props.getCustomers("PURCHASER");
  };

  addPurchase = (indexdecimal) => {
    this.setState({ spinLoad: true });
    let userId = localStorage.getItem("userId");
    if (indexdecimal < 0) {
      let purchases;
      let quantity,
        totalAmount,
        netAmount,
        discount,
        tax,
        date,
        dealerName,
        billNo;

      this.setState({ totalQuantity: 0 });
      quantity = this.state.data.totalQuantity;
      totalAmount = this.state.data.totalAmount;
      netAmount = Math.round(this.state.data.netAmount);
      discount = this.state.data.finalDiscount;
      tax = this.state.data.tax;
      date = moment(this.state.data.billDate, "DD/MM/YYYY")._d;
      // date = this.state.data.billDate;
      dealerName = this.state.data.dealerName;
      billNo = this.state.data.billNo;

      purchases = this.state.data.myPurchaseData.map((data) => {
        let {
          barcode,
          dealerId,
          billNo,
          designNumber,
          billDate,
          brandId,
          categoryId,
          groupId,
          styleId,
          seasonId,
          floorId,
          ageGroupId,
          sectionId,
          locationId,
          HSNCode,
          MOQ,
          size,
          purchasePrice,
          color,
          quantity,
          others,
          discount,
          wsp,
          gst,
          dealerName,
          brandName,
          markup,
          categoryName,
          groupName,
          styleName,
          floorName,
          ageGroupName,
          seasonName,
          sectionName,
          purchasePersonId,
          purchasePersonName,
          textureId,
          textureName,
          salesTax,
        } = data;
        purchasePrice = data.price;
        let purchaseObj = {
          barcode,
          dealerId,
          billNo,
          designNumber: designNumber.trim(),
          billDate,
          brandId,
          categoryId,
          floorId,
          ageGroupId,
          groupId,
          styleId,
          seasonId,
          sectionId,
          locationId,
          HSNCode,
          MOQ,
          size,
          purchasePrice,
          color,
          quantity,
          others,
          discount,
          wsp,
          gst,
          dealerName,
          brandName,
          markup,
          categoryName,
          groupName,
          styleName,
          floorName,
          ageGroupName,
          seasonName,
          sectionName,
          purchasePersonId,
          purchasePersonName,
          isWarehouse: this.state.isWarehouse,
          textureId: textureId || "",
          textureName: textureName || "",
          salesTax,
        };
        return purchaseObj;
      });

      let finalObj = {
        purchases,
        quantity,
        totalAmount,
        netAmount,
        discount,
        tax,
        date,
        dealerName,
        billNo,
        userId,
        dealerId: this.state.data.dealerId,
        purchasePersonId: this.state.purchasePersonId,
        purchasePersonName: this.state.purchasePersonName,
        isWarehouse: this.state.isWarehouse,
      };
      this.props
        .addPurchase(finalObj)
        .then((response) => {
          if (response.status === 200) {
            message.success(response.data);
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong");
        })
        .finally(() => {
          setTimeout(() => {
            window.location.reload(0);
            this.setState({ spinLoad: false });
          }, 1000);
          // Code to run regardless of success or failure
        });
      window.scrollTo(0, 0);
    } else {
      message.warn("Please Add Quantity in Multiple of MOQ");
      setTimeout(() => {
        this.setState({ spinLoad: false });
      }, 1000);
    }
  };
  showLookup = async (key, value) => {
    if (this.state.data.designNumber) {
      let lookupObj = {
        designNumber: this.state.data.designNumber,
        brandName: value.name[0],
      };
      await this.props.getLookupData(lookupObj);
      if (this.props.purchases.length) {
        this.setState({
          visible: true,
          lookupBrandName: value.name[0],
        });
      }
    }
  };
  showLookupDesignNumber = async (value) => {
    if (value) {
      let lookupObj = {
        designNumber: value,
        brandName: this.state.data.brandName,
      };
      await this.props.getLookupData(lookupObj);
      if (this.props.purchases.length) {
        this.setState({
          visible: true,
          lookupBrandName: this.state.data.brandName,
        });
      }
    }
  };

  checkLocation = (lookupData) => {
    const locationId = lookupData?.locationId;

    const matchingLocation = this.props.locations?.find(
      (loc) => loc._id === locationId
    );

    if (matchingLocation) {
      if (matchingLocation?.locationType == 1) {
        this.setState({ isWarehouse: true });
      } else {
        this.setState({ isWarehouse: false });
      }
    }
  };

  closeLookup = (lookupData) => {
    if (this.state.data?.myPurchaseData?.length == 0) {
      this.checkLocation(lookupData);
    }
    if (lookupData) {
      let billNo = this.state.data.billNo;
      let barcode = lookupData.barcode;
      let gst = lookupData.gst;
      let HSNCode = lookupData.HSNCode;
      let discount = lookupData.discount;
      let purchasePrice = lookupData.purchasePrice;
      let dealerName = lookupData.dealerName;
      let dealerId = lookupData.dealerId;
      let categoryName = lookupData.category;
      let categoryId = lookupData.categoryId;
      let seasonName = lookupData.season;
      let seasonId = lookupData.seasonId;
      let sectionName = lookupData.section;
      let sectionId = lookupData.sectionId;
      let locationName = lookupData.location;
      let locationId = lookupData.locationId;
      let styleName = lookupData.style;
      let styleId = lookupData.styleId;
      let groupId = lookupData.groupId;
      let groupName = lookupData.group;
      let floorName = lookupData.floorName;
      let floorId = lookupData.floorId;
      let ageGroupName = lookupData.ageGroupName;
      let ageGroupId = lookupData.ageGroupId;
      let quantity = lookupData.quantity;
      let MOQ = lookupData.MOQ;
      let wsp = lookupData.wsp;
      let markup = lookupData.markup;
      let textureName = lookupData.textureName;
      let textureId = lookupData.textureId;
      let salesTax = lookupData.salesTax
      let data = this.state.data;
      data["billNo"] = this.state.data.billNo;
      data["markup"] = markup;
      data["barcode"] = barcode;
      data["gst"] = gst;
      data["HSNCode"] = HSNCode;
      data["discount"] = discount;
      data["purchasePrice"] = purchasePrice;
      if (
        // this.state.data?.myPurchaseData?.length == 0 &&
        // !this.state.data.dealerName
        this.state.data?.myPurchaseData?.length == 0
      ) {
        data["dealerName"] = dealerName;
        data["dealerId"] = dealerId;
      }
      data["categoryName"] = categoryName;
      data["categoryId"] = categoryId;
      data["styleName"] = styleName;
      data["styleId"] = styleId;
      data["groupName"] = groupName;
      data["groupId"] = groupId;
      data["sectionName"] = sectionName;
      data["sectionId"] = sectionId;
      data["floorName"] = floorName;
      data["floorId"] = floorId;
      data["ageGroupName"] = ageGroupName;
      data["ageGroupId"] = ageGroupId;
      data["seasonName"] = seasonName;
      data["seasonId"] = seasonId;
      data["locationName"] = locationName;
      data["locationId"] = locationId;
      data["quantity"] = quantity;
      data["textureName"] = textureName;
      data["textureId"] = textureId;
      data["salesTax"] = salesTax;
      let moqs = document.getElementsByName("MOQ");
      data["MOQ" + moqs[0].id] = MOQ;
      data["wsp" + moqs[0].id] = wsp;
      let purchasePrices = document.getElementsByName("purchasePrice");
      data["purchasePrice" + purchasePrices[0].id] = purchasePrice;

      data["donedone"] = true;

      let size = lookupData.size;
      let color = lookupData.color;
      let users = [{ size, colors: [{ color }] }];
      this.setState({
        purchasePersonId: lookupData.purchasePersonId,
        purchasePersonName: lookupData.purchasePersonName,
      });
      if (this.state.data?.myPurchaseData?.length == 0) {
        this.formRef.current.setFieldsValue({
          dealerName: lookupData.dealerName,
        });
      }
      this.formRef.current.setFieldsValue({
        category: lookupData.category,
        style: lookupData.style,
        group: lookupData.group,
        season: lookupData.season,
        section: lookupData.section,
        location: lookupData.location,
        floor: lookupData.floorName,
        ageGroup: lookupData.ageGroupName,
        brand: lookupData.brand,
        users,
        purchasePersonName: lookupData.purchasePersonName,
      });

      const fields = this.formRef.current.getFieldsValue();
      this.setState({ visible: false, data });
      return true;
    } else {
      this.setState({ visible: false });
    }
  };
  showDataOnList = () => {
    let existingobjIndex = this.state.data?.myPurchaseData?.findIndex(
      (obj) =>
        obj.barcode &&
        obj.barcode.toString().length > 7 &&
        obj.barcode == this.state.data.barcode
    );
    if (existingobjIndex >= 0) {
      const existingObj = this.state.data.myPurchaseData[existingobjIndex];

      let finalQty =
        Number(existingObj.quantity) + Number(this.state.data.quantity);
      let calculatedPrice = existingObj.price * finalQty;
      let finalcalculatedAmount = Number(
        calculatedPrice - (calculatedPrice * this.state.data.discount) / 100
      );

      const updatedObj = {
        ...existingObj,
        quantity: finalQty,
        amount: finalcalculatedAmount,
      };
      const updatedListData = [...this.state.data.myPurchaseData];
      updatedListData[existingobjIndex] = updatedObj;
      const currentState = this.state.data;
      this.setState({
        data: {
          ...currentState,
          myPurchaseData: updatedListData,
        },
      });

      let sizes = document.getElementsByName("size");
      let prices = document.getElementsByName("purchasePrice");
      let moqs = document.getElementsByName("MOQ");
      let e = 0;
      let f = sizes.length;
      let tempMyPurchaseData = [];
      let totalQuantity = Number(this.state.data.totalQuantity);
      let totalAmount = Number(this.state.data.totalAmount);

      while (e < f) {
        let id = prices[e].id;
        let barcode;
        if (e == 0) {
          barcode = this.state.data.barcode;
        }
        let colors = document.getElementsByName("defaultColor" + id);
        let quantity = document.getElementsByName("quantity" + id);
        let wsp = document.getElementById("wsp" + id);
        let leftBound = 0;
        let rightBound = colors.length;
        while (leftBound < rightBound) {
          purchase = {};
          purchase.barcode = barcode;
          purchase.uniqueId = uuid();
          purchase.dealerName = this.state.data.dealerName;
          purchase.dealerId = this.state.data.dealerId;
          purchase.brandName = this.state.data.brandName;
          purchase.brandId = this.state.data.brandId;
          purchase.categoryName = this.state.data.categoryName;
          purchase.categoryId = this.state.data.categoryId;
          purchase.groupName = this.state.data.groupName;
          purchase.groupId = this.state.data.groupId;
          purchase.styleName = this.state.data.styleName;
          purchase.styleId = this.state.data.styleId;
          purchase.floorName = this.state.data.floorName;
          purchase.floorId = this.state.data.floorId;
          purchase.ageGroupName = this.state.data.ageGroupName;
          purchase.ageGroupId = this.state.data.ageGroupId;
          purchase.sectionName = this.state.data.sectionName;
          purchase.discount = this.state.data.discount;
          purchase.sectionId = this.state.data.sectionId;
          purchase.gst = this.state.data.gst;
          purchase.seasonName = this.state.data.seasonName;
          purchase.seasonId = this.state.data.seasonId;
          purchase.locationName = this.state.data.locationName;
          purchase.locationId = this.state.data.locationId;
          purchase.markup = this.state.data.markup;
          purchase.designNumber = this.state.data.designNumber;
          purchase.billNo = this.state.data.billNo;
          purchase.MOQ = moqs[e].value;
          purchase.HSNCode = this.state.data.HSNCode;
          purchase.price = prices[e].value;
          purchase.wsp = wsp.value;
          purchase.color = colors[leftBound].innerText;
          purchase.others = this.state.data.others;
          purchase.billDate = this.state.data.billDate;
          purchase.sizeId = this.state.data.sizeId;
          purchase.quantity = quantity[leftBound].value;
          purchase.purchasePersonId = this.state.purchasePersonId;
          purchase.purchasePersonName = this.state.purchasePersonName;
          purchase.textureName = this.state.data.textureName;
          purchase.textureId = this.state.data.textureId;
          purchase.salesTax = this.state.data.salesTax;
          totalQuantity += Number(purchase.quantity);
          purchase.size = sizes[e].innerText;
          let calculatedPrice = purchase.price * purchase.quantity;
          purchase.amount = Number(
            calculatedPrice - (calculatedPrice * this.state.data.discount) / 100
          );
          totalAmount += purchase.amount;
          tempMyPurchaseData.push(purchase);
          ++leftBound;
        }
        ++e;
      }

      let data = this.state.data;

      let finalAmount =
        totalAmount -
        (totalAmount * Number(this.state.data.finalDiscount)) / 100;
      data["discountedAmount"] = totalAmount - finalAmount;
      if (this.state.data.tax) {
        var netAmount =
          finalAmount + (finalAmount * Number(this.state.data.tax)) / 100;
        data["netAmount"] = netAmount;
        data["taxedAmount"] = netAmount - finalAmount;
      }
      data["finalAmount"] = finalAmount;
      data["totalQuantity"] = totalQuantity;
      data["totalAmount"] = totalAmount;

      data.myPurchaseData = [...this.state.data.myPurchaseData];

      // let newData = [];
      // for (let index = 0; index < data.myPurchaseData.length; index++) {
      //   if (data.myPurchaseData[index].wsp >= 1000) {
      //     newData.push({ ...data.myPurchaseData[index], salesTax: 12 });
      //   } else {
      //     newData.push({ ...data.myPurchaseData[index], salesTax: 5 });
      //   }
      // }

      this.setState({ data });

      let temp = Object.assign(
        {
          myPurchaseData: data.myPurchaseData,
          totalQuantity: data.totalQuantity,
          netAmount: data.netAmount,
          tax: data.tax,
          totalAmount: data.totalAmount,
          finalAmount: data.finalAmount,
          finalDiscount: data.finalDiscount,
          others: data.others,
          billNo: data.billNo,
          gst: data.gst,
          discountedAmount: data.discountedAmount,
          taxedAmount: data.taxedAmount,
          // =======================
          markup: data.markup,
          brandName: data.brandName,
          brandId: data.brandId,
          dealerId: data.dealerId,
          dealerName: data.dealerName,
          locationId: data.locationId,
          locationName: data.locationName,
          billDate: data.billDate,
          HSNCode: data.HSNCode,
          seasonName: data.seasonName,
          seasonId: data.seasonId,
          sectionName: data.sectionName,
          sectionId: data.sectionId,
          floorName: data.floorName,
          floorId: data.floorId,
          ageGroupId: data.ageGroupId,
          ageGroupName: data.ageGroupName,
          styleName: data.styleName,
          styleId: data.styleId,
          textureName: data.textureName,
          textureId: data.textureId,
          salesTax : data.salesTax
        },
        {}
      );

      this.setState({ data: temp });
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          seasonName: data.seasonName,
          seasonId: data.seasonId,
          sectionName: data.sectionName,
          sectionId: data.sectionId,
          floorName: data.floorName,
          floorId: data.floorId,
          ageGroupId: data.ageGroupId,
          ageGroupName: data.ageGroupName,
          categoryName: data.categoryName,
          categoryId: data.categoryId,
          groupName: data.groupName,
          groupId: data.groupId,
          textureName: data.textureName,
          textureId: data.textureId,
          salesTax : data.salesTax,
          discount: 0,
          barcode: "",
        },
      }));

      this.formRef.current.setFieldsValue({
        category: data.categoryName,
        style: data.styleName,
        group: data.groupName,
        season: data.seasonName,
        section: data.sectionName,
        floor: data.floorName,
        ageGroup: data.ageGroupName,
        location: data.locationName,
        brand: data.brandName,

        users: [
          { size: undefined, colors: [undefined] }, // undefined will render the placeholder
        ],
      });
    } else {
      let sizes = document.getElementsByName("size");
      let prices = document.getElementsByName("purchasePrice");
      let moqs = document.getElementsByName("MOQ");
      let e = 0;
      let f = sizes.length;
      let tempMyPurchaseData = [];
      let totalQuantity = Number(this.state.data.totalQuantity);
      let totalAmount = Number(this.state.data.totalAmount);

      while (e < f) {
        let id = prices[e].id;
        let barcode;
        if (e == 0) {
          barcode = this.state.data.barcode;
        }
        let colors = document.getElementsByName("defaultColor" + id);
        let quantity = document.getElementsByName("quantity" + id);
        let wsp = document.getElementById("wsp" + id);
        let leftBound = 0;
        let rightBound = colors.length;
        while (leftBound < rightBound) {
          purchase = {};
          purchase.barcode = barcode;
          purchase.uniqueId = uuid();
          purchase.dealerName = this.state.data.dealerName;
          purchase.dealerId = this.state.data.dealerId;
          purchase.brandName = this.state.data.brandName;
          purchase.brandId = this.state.data.brandId;
          purchase.categoryName = this.state.data.categoryName;
          purchase.categoryId = this.state.data.categoryId;
          purchase.groupName = this.state.data.groupName;
          purchase.groupId = this.state.data.groupId;
          purchase.styleName = this.state.data.styleName;
          purchase.styleId = this.state.data.styleId;
          purchase.floorName = this.state.data.floorName;
          purchase.floorId = this.state.data.floorId;
          purchase.ageGroupName = this.state.data.ageGroupName;
          purchase.ageGroupId = this.state.data.ageGroupId;
          purchase.sectionName = this.state.data.sectionName;
          purchase.discount = this.state.data.discount;
          purchase.sectionId = this.state.data.sectionId;
          purchase.gst = this.state.data.gst;
          purchase.seasonName = this.state.data.seasonName;
          purchase.seasonId = this.state.data.seasonId;
          purchase.locationName = this.state.data.locationName;
          purchase.locationId = this.state.data.locationId;
          purchase.markup = this.state.data.markup;
          purchase.designNumber = this.state.data.designNumber;
          purchase.billNo = this.state.data.billNo;
          purchase.MOQ = moqs[e].value;
          purchase.HSNCode = this.state.data.HSNCode;
          purchase.price = prices[e].value;
          purchase.wsp = wsp.value;
          purchase.color = colors[leftBound].innerText;
          purchase.others = this.state.data.others;
          purchase.billDate = this.state.data.billDate;
          purchase.purchasePersonId = this.state.purchasePersonId;
          purchase.purchasePersonName = this.state.purchasePersonName;
          purchase.sizeId = this.state.data.sizeId;
          purchase.quantity =
            quantity[leftBound].value || this.state.data.quantity;
          totalQuantity += Number(purchase.quantity);
          purchase.size = sizes[e].innerText;
          let calculatedPrice = purchase.price * purchase.quantity;
          purchase.textureName = this.state.data.textureName;
          purchase.textureId = this.state.data.textureId;
          purchase.salesTax = this.state.data.salesTax;

          
          purchase.amount = Number(
            calculatedPrice - (calculatedPrice * this.state.data.discount) / 100
          );
          totalAmount += purchase.amount;
          tempMyPurchaseData.push(purchase);
          ++leftBound;
        }
        ++e;
      }
      let data = this.state.data;

      let finalAmount =
        totalAmount -
        (totalAmount * Number(this.state.data.finalDiscount)) / 100;
      data["discountedAmount"] = totalAmount - finalAmount;
      if (this.state.data.tax) {
        var netAmount =
          finalAmount + (finalAmount * Number(this.state.data.tax)) / 100;
        data["netAmount"] = netAmount;
        data["taxedAmount"] = netAmount - finalAmount;
      }
      data["finalAmount"] = finalAmount;
      data["totalQuantity"] = totalQuantity;
      data["totalAmount"] = totalAmount;

      data.myPurchaseData = [
        ...this.state.data.myPurchaseData,
        ...tempMyPurchaseData,
      ];

      // let newData = [];
      // for (let index = 0; index < data.myPurchaseData.length; index++) {
      //   if (data.myPurchaseData[index].wsp >= 1000) {
      //     newData.push({ ...data.myPurchaseData[index], salesTax: 12 });
      //   } else {
      //     newData.push({ ...data.myPurchaseData[index], salesTax: 5 });
      //   }
      // }
      // data.myPurchaseData = newData;

      this.setState({ data });

      let temp = Object.assign(
        {
          myPurchaseData: data.myPurchaseData,
          totalQuantity: data.totalQuantity,
          netAmount: data.netAmount,
          tax: data.tax,
          totalAmount: data.totalAmount,
          finalAmount: data.finalAmount,
          finalDiscount: data.finalDiscount,
          others: data.others,
          billNo: data.billNo,
          gst: data.gst,
          discountedAmount: data.discountedAmount,
          taxedAmount: data.taxedAmount,
          // =======================
          markup: data.markup,
          brandName: data.brandName,
          brandId: data.brandId,
          dealerId: data.dealerId,
          dealerName: data.dealerName,
          locationId: data.locationId,
          locationName: data.locationName,
          billDate: data.billDate,
          HSNCode: data.HSNCode,
          seasonName: data.seasonName,
          seasonId: data.seasonId,
          sectionName: data.sectionName,
          sectionId: data.sectionId,
          floorName: data.floorName,
          floorId: data.floorId,
          ageGroupId: data.ageGroupId,
          ageGroupName: data.ageGroupName,
          styleName: data.styleName,
          styleId: data.styleId,
          designNumber: data.designNumber,
          textureName: data.textureName,
          textureId: data.textureId,
          salesTax : data.salesTax
        },
        {}
      );
      this.setState({ data: temp });
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          seasonName: data.seasonName,
          seasonId: data.seasonId,
          sectionName: data.sectionName,
          sectionId: data.sectionId,
          floorName: data.floorName,
          floorId: data.floorId,
          ageGroupId: data.ageGroupId,
          ageGroupName: data.ageGroupName,
          categoryName: data.categoryName,
          categoryId: data.categoryId,
          groupName: data.groupName,
          groupId: data.groupId,
          textureName: data.textureName,
          textureId: data.textureId,
          salesTax : data.salesTax,
          discount: 0,
          barcode: "",
        },
      }));

      // let elements = document.getElementsByName("done_dana_done");
      // e = 0;
      // f = elements.length;
      // console.log(elements);
      // while (e < f) {
      //   elements[0].remove();
      //   ++e;
      // }

      this.formRef.current.setFieldsValue({
        category: data.categoryName,
        style: data.styleName,
        group: data.groupName,
        season: data.seasonName,
        section: data.sectionName,
        floor: data.floorName,
        ageGroup: data.ageGroupName,
        location: data.locationName,
        brand: data.brandName,
        users: [
          { size: undefined, colors: [undefined] }, // undefined will render the placeholder
        ],
      });
    }
  };

  updatePurchase = (lookupData) => {
    this.deletePurchase(lookupData.uniqueId);

    let billNo = lookupData.billNo;
    let barcode = lookupData.barcode;
    let designNumber = lookupData.designNumber;
    let gst = lookupData.gst;
    let HSNCode = lookupData.HSNCode;
    let discount = lookupData.discount;
    let price = lookupData.price;
    let dealerName = lookupData.dealerName;
    let dealerId = lookupData.dealerId;
    let categoryName = lookupData.categoryName;
    let categoryId = lookupData.categoryId;
    let seasonName = lookupData.seasonName;
    let seasonId = lookupData.seasonId;
    let sectionName = lookupData.sectionName;
    let sectionId = lookupData.sectionId;
    let floorName = lookupData.floorName;
    let floorId = lookupData.floorId;
    let ageGroupName = lookupData.ageGroupName;
    let ageGroupId = lookupData.ageGroupId;
    let locationName = lookupData.locationName;
    let locationId = lookupData.locationId;
    let styleName = lookupData.styleName;
    let styleId = lookupData.styleId;
    let groupId = lookupData.groupId;
    let groupName = lookupData.groupName;
    let quantity = lookupData.quantity;
    let MOQ = lookupData.MOQ;
    let wsp = lookupData.wsp;
    let brandName = lookupData.brandName;
    let brandId = lookupData.brandId;
    let markup = lookupData.markup;
    let purchasePersonId = lookupData.purchasePersonId;
    let purchasePersonName = lookupData.purchasePersonName;
    let data = this.state.data;
    let textureName = lookupData.textureName;
    let textureId = lookupData.textureId;
    let salesTax = lookupData.salesTax
    if (barcode) {
      data["donedone"] = true;
    }
    data["purchasePersonId"] = purchasePersonId;
    data["purchasePersonName"] = purchasePersonName;
    data["markup"] = markup;
    data["billNo"] = billNo;
    data["barcode"] = barcode;
    data["gst"] = gst;
    data["HSNCode"] = HSNCode;
    data["discount"] = discount;
    data["purchasePrice"] = price;
    data["dealerName"] = dealerName;
    data["dealerId"] = dealerId;
    data["categoryName"] = categoryName;
    data["categoryId"] = categoryId;
    data["styleName"] = styleName;
    data["styleId"] = styleId;
    data["groupName"] = groupName;
    data["groupId"] = groupId;
    data["sectionName"] = sectionName;
    data["sectionId"] = sectionId;
    data["floorName"] = floorName;
    data["floorId"] = floorId;
    data["ageGroupName"] = ageGroupName;
    data["ageGroupId"] = ageGroupId;
    data["seasonName"] = seasonName;
    data["seasonId"] = seasonId;
    data["locationName"] = locationName;
    data["locationId"] = locationId;
    data["quantity"] = quantity;
    data["designNumber"] = designNumber;
    data["brandName"] = brandName;
    data["brandId"] = brandId;
    let moqs = document.getElementsByName("MOQ");
    data["MOQ" + moqs[0].id] = MOQ;
    data["wsp" + moqs[0].id] = wsp;
    let purchasePrices = document.getElementsByName("purchasePrice");
    data["purchasePrice" + purchasePrices[0].id] = price;
    data["boxes" + moqs[0].id + " " + 0] = Number(quantity) / Number(MOQ);
    data["textureName"] = textureName;
    data["textureId"] = textureId;
    data["salesTax"] = salesTax
    let size = lookupData.size;
    let color = lookupData.color;
    let users = [{ size, colors: [{ color, quantity }] }];

    this.formRef.current.setFieldsValue({
      dealerName: lookupData.dealerName,
      category: lookupData.categoryName,
      style: lookupData.styleName,
      group: lookupData.groupName,
      season: lookupData.seasonName,
      section: lookupData.sectionName,
      floor: lookupData.floorName,
      ageGroup: lookupData.ageGroupName,
      location: lookupData.locationName,
      brand: lookupData.brandName,
      purchasePersonName: lookupData.purchasePersonName,
      users,
    });
    this.setState({ visible: false, data, locationDis: true });
    window.scrollTo(0, 0);
  };

  deletePurchase = (id) => {
    let toDelete;
    let purchases = this.state.data.myPurchaseData.filter((purchase) => {
      if (purchase.uniqueId != id) return purchase;
      toDelete = purchase;
    });
    let data = this.state.data;
    data.myPurchaseData = purchases;
    let updatedQuantity = this.state.data.totalQuantity - toDelete.quantity;

    let calculatedPrice = toDelete.price * toDelete.quantity;
    let updatedTotalAmount = Number(
      calculatedPrice - (calculatedPrice * toDelete.discount) / 100
    );
    data.totalAmount = data.totalAmount - updatedTotalAmount;
    data.totalQuantity = updatedQuantity;

    if (this.state.data.finalDiscount) {
      let finalAmount =
        data.totalAmount -
        (data.totalAmount * this.state.data.finalDiscount) / 100;
      data.finalAmount = finalAmount;
    }
    if (this.state.data.tax && data.finalAmount) {
      let netAmount =
        data.finalAmount + (data.finalAmount * this.state.data.tax) / 100;
      data.netAmount = netAmount;
    }

    this.setState(data);
  };

  disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };
  render() {
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={[dealer.name, dealer.gst]} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });
    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={[brand.name, brand.markup]} value={brand._id}>
          {brand.name}
        </Option>
      );
    });
    let categoryOptions = this.props.categories.map((category) => {
      return (
        <Option name={category.name} value={category._id}>
          {category.name}
        </Option>
      );
    });
    let groupOptions = this.props.groups.map((group) => {
      return (
        <Option name={group.name} value={group._id}>
          {group.name}
        </Option>
      );
    });
    let styleOptions = this.props.styles.map((style) => {
      return (
        <Option name={style.name} value={style._id}>
          {style.name}
        </Option>
      );
    });
    let seasonOptions = this.props.seasons.map((season) => {
      return (
        <Option name={season.name} value={season._id}>
          {season.name}
        </Option>
      );
    });
    let sectionOptions = this.props.sections.map((section) => {
      return (
        <Option name={section.name} value={section._id}>
          {section.name}
        </Option>
      );
    });
    let locationOptions = this.props.locations.map((location) => {
      return (
        <Option name={location.name} value={location._id}>
          {location.name}
        </Option>
      );
    });
    let sizeOptions = this.props.sizes.map((size) => {
      return (
        <Option name={size.name} value={size._id} key={size._id}>
          {size.name}
        </Option>
      );
    });
    let colorOptions = this.props.colors.map((color) => {
      return (
        <Option name={color.name} value={color._id}>
          {color.name}
        </Option>
      );
    });

    let floorOptions = this.props.floors.map((floor) => {
      return (
        <Option name={floor.name} value={floor._id}>
          {floor.name}
        </Option>
      );
    });

    let textureOptions = this.props.textures?.map((texture) => {
      return (
        <Option name={texture.name} value={texture._id}>
          {texture.name}
        </Option>
      );
    });

    let ageGroupOptions = this.props.ageGroups.map((ageGroup) => {
      return (
        <Option name={ageGroup.name} value={ageGroup._id}>
          {ageGroup.name}
        </Option>
      );
    });
    let purchaseOptions = this.props.purchaserList.map((purchase) => {
      return (
        <Option name={purchase.name} value={purchase._id}>
          {purchase.name}
        </Option>
      );
    });
    const { loading } = this.state.data;
    const initialValues = {
      users: [
        { size: undefined, colors: [undefined] }, // undefined will render the placeholder
      ],
    };

    var disabled = false;
    if (this.state.data.donedone) {
      disabled = true;
    }

    return (
      <div className="add-purchase ">
        {this.state.spinLoad ? (
          <Spin indicator={antIcon} />
        ) : (
          <div className="container-fluid">
            <Row className="title-row">
              <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                <div className="page-header">Add Purchase</div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button
                  type="primary"
                  className="save-modal text-white"
                  onClick={() =>
                    this.props.history.push("/dagaImpex/purchaseBill")
                  }
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </Col>
            </Row>
            <div className="card mt-4">
              <div className="card-body">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                      ref={this.formRef}
                      name="add-Purchase"
                      className="add-Purchase"
                      onFinish={this.showDataOnList}
                      initialValues={initialValues}
                    >
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Dealer">
                            <Form.Item
                              name="dealerName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Dealer Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={
                                  this.state?.data?.myPurchaseData?.length > 0
                                    ? true
                                    : false || disabled
                                }
                                showSearch
                                placeholder="Select Dealer"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeDealer}
                                onClick={() => this.debouncedGetData("dealer")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("dealer")
                                }
                              >
                                {dealerOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Enter Bill Number">
                            <Form.Item
                              // name="billNo"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Bill Number !",
                                },
                              ]}
                            >
                              <Input
                                disabled={
                                  this.state.data.myPurchaseData.length > 0
                                    ? true
                                    : false
                                }
                                placeholder="Enter Bill Number"
                                name="billNo"
                                value={this.state.data.billNo}
                                onChange={this.onChange}
                                required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Bill Date">
                            <Form.Item name="billDate">
                              <DatePicker
                                disabled={disabled}
                                onChange={this.onDateChange}
                                placeholder="Select Bill Date"
                                defaultValue={moment()}
                                format={dateFormat}
                                disabledDate={this.disabledDate}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="GST">
                            <Form.Item>
                              <Input
                                id="gst"
                                placeholder="GST Number"
                                name="gst"
                                value={this.state.data.gst}
                                onChange={this.onChange}
                                disabled
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>

                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Enter Design Number">
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Design Number !",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Design Number"
                                id="designNumber"
                                name="designNumber"
                                value={this.state.data.designNumber}
                                onChange={this.onChange}
                                required
                                maxLength={15}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Brand">
                            <Form.Item
                              name="brand"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please Select Brand Name !",
                              //   },
                              // ]}
                            >
                              <Select
                                name="brand"
                                id="brand"
                                showSearch
                                placeholder="Select Brand"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeBrand}
                                onSelect={this.showLookup}
                                onClick={() => this.debouncedGetData("brand")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("brand")
                                }
                              >
                                {brandOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <LookupModal
                          visible={this.state.visible}
                          designNumber={this.state.data.designNumber}
                          lookupBrandName={this.state.lookupBrandName}
                          closeLookup={this.closeLookup}
                          showDataOnList={this.showDataOnList}
                          onChange={this.onChange}
                          data={this.state.data}
                        />
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Enter Markup">
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Markup !",
                                },
                              ]}
                            >
                              <Input
                                disabled={disabled}
                                placeholder="Enter Markup"
                                name="markup"
                                id="markup"
                                value={this.state.data.markup}
                                onChange={this.onChange}
                                required
                                type="number"
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Category">
                            <Form.Item
                              name="category"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Category Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeCategory}
                                onClick={() =>
                                  this.debouncedGetData("category")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("category")
                                }
                              >
                                {categoryOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Style">
                            <Form.Item
                              name="style"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Style Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                // name="style1"
                                id="style1"
                                showSearch
                                placeholder="Select Style"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeStyle}
                                onClick={() => {
                                  if (this.state.data.categoryId != "") {
                                    this.debouncedGetData("style");
                                  } else {
                                    message.warning("Please Select Category");
                                  }
                                }}
                                // onInputKeyDown={() =>
                                //   this.debouncedGetData("style")
                                // }
                              >
                                {styleOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Group">
                            <Form.Item
                              name="group"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Group Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Group"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeGroup}
                                onClick={() => this.debouncedGetData("group")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("group")
                                }
                              >
                                {groupOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Season">
                            <Form.Item
                              name="season"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Season Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Season"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSeason}
                                onClick={() => this.debouncedGetData("season")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("season")
                                }
                              >
                                {seasonOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Section">
                            <Form.Item
                              name="section"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Section Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Section"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSection}
                                onClick={() => this.debouncedGetData("section")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("section")
                                }
                              >
                                {sectionOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row gutter={[6, 0]}>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Enter HSNCode">
                            <Form.Item>
                              <Input
                                disabled={disabled}
                                placeholder="Enter HSN Code"
                                name="HSNCode"
                                value={this.state.data.HSNCode}
                                onChange={this.onChange}
                                required
                                type="number"
                                // onKeyDown={this.handleKeypress}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                }}
                                onBlur={(e) => {
                                  e.target.removeEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                  this.handleBlur(e);
                                }}
                                min="0"
                                step="1"
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Locaton">
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Location Name !",
                                },
                              ]}
                            >
                              <Select
                                //defaultValue="214, Tilak Path "
                                disabled={
                                  this.state.data?.myPurchaseData?.length > 0 ||
                                  disabled ||
                                  this.state.locationDis
                                }
                                showSearch
                                placeholder="Select Location"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeLocation}
                                onClick={() =>
                                  this.debouncedGetData("location")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("location")
                                }
                              >
                                {locationOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Tooltip title="Enter Others">
                            <Form.Item
                              name="others"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please Enter Others !",
                              //   },
                              // ]}
                            >
                              <Input
                                disabled={disabled}
                                type="number"
                                placeholder="Enter Others"
                                name="others"
                                defaultValue={1}
                                value={this.state.data.others}
                                onChange={this.onChange}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                }}
                                onBlur={(e) => {
                                  e.target.removeEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                  this.handleBlur(e);
                                }}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Tooltip title="Enter Discount">
                            <Form.Item>
                              <Input
                                addonBefore="%"
                                disabled={disabled}
                                type="number"
                                // suffix="%"
                                placeholder="Enter Discount (%)"
                                name="discount"
                                value={this.state.data.discount}
                                onChange={this.onChange}
                                required
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                }}
                                onBlur={(e) => {
                                  e.target.removeEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                  this.handleBlur(e);
                                }}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Tooltip title="Enter Sale Tax">
                            <Form.Item
                            //  name="salesTax"
                            //  rules={[
                            //    {
                            //      required: true,
                            //      message: "Please Enter Sales Tax !",
                            //    },
                            //  ]}
                            
                            >
                              <Input
                                addonBefore="%"
                                disabled={disabled}
                                type="number"
                                min={1}
                                // suffix="%"
                                placeholder="Enter Sale Tax (%)"
                                name="salesTax"
                                value={this.state.data.salesTax}
                                onChange={this.onChange}
                                required
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                }}
                                onBlur={(e) => {
                                  e.target.removeEventListener(
                                    "wheel",
                                    this.preventScroll
                                  );
                                  this.handleBlur(e);
                                }}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row gutter={[6, 0]}>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Floor">
                            <Form.Item
                              name="floor"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Floor Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Floor"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeFloor}
                                onClick={() => this.debouncedGetData("floor")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("floor")
                                }
                              >
                                {floorOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Texture">
                            <Form.Item
                              name="texture"
                              // rules={[
                              //   {
                              //     // required: true,
                              //     message: "Please Select Texture !",
                              //   },
                              // ]}
                            >
                              <Select
                              mode={"multiple"}
                                disabled={disabled}
                                showSearch
                                placeholder="Select Texture"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeTexture}
                                onClick={() => this.debouncedGetData("texture")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("texture")
                                }
                              >
                                {textureOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Age Group">
                            <Form.Item
                              name="ageGroup"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Age Group Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                showSearch
                                placeholder="Select Age Group"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeAgeGroup}
                                onClick={() =>
                                  this.debouncedGetData("ageGroup")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("ageGroup")
                                }
                              >
                                {ageGroupOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Purchaser Name">
                            <Form.Item
                              name="purchasePersonName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Purchaser Name !",
                                },
                              ]}
                            >
                              <Select
                                // disabled={this.state.data.myPurchaseData.length > 0 ? true : false}
                                showSearch
                                placeholder="Select Purchaser Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangePurchaser}
                                onClick={() =>
                                  this.debouncedGetData("purchaser")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("purchaser")
                                }
                              >
                                {purchaseOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Form.List name="users" id="users">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field) => (
                              <span name="done_dana_done">
                                <Row gutter={[8, 0]} key={field.key}>
                                  <Col xs={2} sm={2} md={1} lg={1} xl={1}>
                                    {(() => {
                                      if (field.key == 0) {
                                        return <CheckOutlined />;
                                      } else {
                                        return (
                                          <MinusCircleOutlined
                                            onClick={() => {
                                              remove(field.name);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </Col>
                                  <Col xs={11} sm={11} md={6} lg={5} xl={5}>
                                    <Tooltip title="Select Size">
                                      <Form.Item
                                        {...field}
                                        fieldKey={[field.fieldKey, "size"]}
                                        name={[field.name, "size"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please Select Size !",
                                          },
                                        ]}
                                      >
                                        <Select
                                          disabled={disabled}
                                          // id="467396"
                                          name="size"
                                          showSearch
                                          placeholder="Select Size"
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          filterSort={(optionA, optionB) =>
                                            optionA.children
                                              .toLowerCase()
                                              .localeCompare(
                                                optionB.children.toLowerCase()
                                              )
                                          }
                                          onChange={this.handleChangeSize}
                                          onClick={() =>
                                            this.debouncedGetData("size")
                                          }
                                          onInputKeyDown={() =>
                                            this.debouncedGetData("size")
                                          }
                                        >
                                          {sizeOptions}
                                        </Select>
                                      </Form.Item>
                                    </Tooltip>
                                  </Col>
                                  <Col xs={11} sm={11} md={6} lg={6} xl={6}>
                                    <Tooltip title="Enter Purchase Price">
                                      <Form.Item
                                        // {...field}
                                        // fieldKey={[field.fieldKey, "purchasePrice"]}
                                        // name={[field.name, "purchasePrice"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please Enter Purchase Price !",
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={disabled}
                                          placeholder="Price"
                                          name="purchasePrice"
                                          id={field.key}
                                          onFocus={(e) => {
                                            e.target.addEventListener(
                                              "wheel",
                                              this.preventScroll
                                            );
                                          }}
                                          onBlur={(e) => {
                                            e.target.removeEventListener(
                                              "wheel",
                                              this.preventScroll
                                            );
                                            this.handleBlur(e);
                                          }}
                                          value={
                                            this.state.data[
                                              "purchasePrice" + field.key
                                            ]
                                          }
                                          onChange={(e) =>
                                            this.onChange(
                                              e,
                                              "wsp" + field.key,
                                              field.key
                                            )
                                          }
                                          type="number"
                                          min="0"
                                          step={0.1}
                                          required
                                        />
                                      </Form.Item>
                                    </Tooltip>
                                  </Col>
                                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Tooltip title="Enter MOQ">
                                      <Form.Item
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please Enter MOQ !",
                                          },
                                        ]}
                                      >
                                        <div className="no-scroll-input-moq">
                                          <Input
                                            disabled={disabled}
                                            id={field.key}
                                            type="number"
                                            placeholder="Enter MOQ"
                                            name="MOQ"
                                            onFocus={(e) => {
                                              e.target.addEventListener(
                                                "wheel",
                                                this.preventScroll
                                              );
                                            }}
                                            onBlur={(e) => {
                                              e.target.removeEventListener(
                                                "wheel",
                                                this.preventScroll
                                              );
                                              this.handleBlur(e);
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            value={
                                              this.state.data["MOQ" + field.key]
                                            }
                                            onChange={(e) =>
                                              this.onChange(
                                                e,
                                                "MOQ" + field.key
                                              )
                                            }
                                            min="1"
                                            step="1"
                                            required
                                          />
                                        </div>
                                      </Form.Item>
                                    </Tooltip>
                                  </Col>
                                  <Col xs={12} sm={12} md={5} lg={6} xl={6}>
                                    <Tooltip title="WSP">
                                      <Form.Item>
                                        <Input
                                          placeholder="WSP"
                                          name={"wsp" + field.key}
                                          id={"wsp" + field.key}
                                          value={
                                            this.state.data["wsp" + field.key]
                                          }
                                          onChange={this.onChange}
                                          disabled
                                        />
                                      </Form.Item>
                                    </Tooltip>
                                  </Col>
                                </Row>
                                <Form.List name={[field.name, "colors"]}>
                                  {(colors, { add, remove }) => {
                                    return (
                                      <span>
                                        {colors.map((color) => (
                                          <Row
                                            gutter={[8, 0]}
                                            key={color.key}
                                            style={{
                                              marginLeft: "35px",
                                            }}
                                          >
                                            <Col
                                              xs={2}
                                              sm={2}
                                              md={1}
                                              lg={1}
                                              xl={1}
                                            >
                                              {(() => {
                                                if (color.key == 0) {
                                                  return <CheckOutlined />;
                                                } else {
                                                  return (
                                                    <MinusCircleOutlined
                                                      onClick={() => {
                                                        remove(color.name);
                                                      }}
                                                    />
                                                  );
                                                }
                                              })()}
                                            </Col>
                                            <Col
                                              xs={12}
                                              sm={12}
                                              md={8}
                                              lg={8}
                                              xl={8}
                                            >
                                              {" "}
                                              <Tooltip title="Select Color">
                                                <Form.Item
                                                  {...color}
                                                  fieldKey={[
                                                    color.fieldKey,
                                                    "color",
                                                  ]}
                                                  name={[color.name, "color"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please Select Color !",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    disabled={disabled}
                                                    id={color.key}
                                                    showSearch
                                                    placeholder="Select Color"
                                                    optionFilterProp="children"
                                                    name={
                                                      "defaultColor" + field.key
                                                    }
                                                    filterOption={(
                                                      input,
                                                      option
                                                    ) =>
                                                      option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                          input.toLowerCase()
                                                        ) >= 0
                                                    }
                                                    filterSort={(
                                                      optionA,
                                                      optionB
                                                    ) =>
                                                      optionA.children
                                                        .toLowerCase()
                                                        .localeCompare(
                                                          optionB.children.toLowerCase()
                                                        )
                                                    }
                                                    onChange={
                                                      this.handleChangeColor
                                                    }
                                                    onClick={() =>
                                                      this.debouncedGetData(
                                                        "color"
                                                      )
                                                    }
                                                    onInputKeyDown={() =>
                                                      this.debouncedGetData(
                                                        "color"
                                                      )
                                                    }
                                                  >
                                                    {colorOptions}
                                                  </Select>
                                                </Form.Item>
                                              </Tooltip>
                                            </Col>
                                            <Col
                                              xs={10}
                                              sm={10}
                                              md={8}
                                              lg={8}
                                              xl={8}
                                            >
                                              {" "}
                                              <Tooltip title="Enter Quantity">
                                                <Form.Item
                                                  {...color}
                                                  fieldKey={[
                                                    color.fieldKey,
                                                    "quantity",
                                                  ]}
                                                  name={[
                                                    color.name,
                                                    "quantity",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please Enter Quantity !",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    placeholder="Quantity"
                                                    onFocus={(e) => {
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        this.preventScroll
                                                      );
                                                    }}
                                                    onBlur={(e) => {
                                                      e.target.removeEventListener(
                                                        "wheel",
                                                        this.preventScroll
                                                      );
                                                      this.handleBlur(e);
                                                    }}
                                                    name={
                                                      "quantity" + field.key
                                                    }
                                                    id="quantity"
                                                    value={
                                                      this.state.data.quantity
                                                    }
                                                    onChange={(e) =>
                                                      this.onChange(
                                                        e,
                                                        field.key,
                                                        "" +
                                                          field.key +
                                                          " " +
                                                          color.key
                                                      )
                                                    }
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                  />
                                                </Form.Item>
                                              </Tooltip>
                                            </Col>
                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={7}
                                              lg={7}
                                              xl={7}
                                            >
                                              <Tooltip title="Boxes">
                                                <Form.Item>
                                                  <Input
                                                    type="number"
                                                    placeholder="Boxes"
                                                    name={"boxes" + color.key}
                                                    id={"boxes" + color.key}
                                                    value={
                                                      this.state.data[
                                                        "boxes" +
                                                          field.key +
                                                          " " +
                                                          color.key
                                                      ]
                                                    }
                                                    onChange={this.onChange}
                                                    disabled
                                                  />
                                                </Form.Item>
                                              </Tooltip>
                                            </Col>
                                          </Row>
                                        ))}

                                        <Form.Item>
                                          <Button
                                            // type="dashed"
                                            disabled={disabled}
                                            danger
                                            onClick={() => {
                                              add();
                                            }}
                                            // block
                                            className="add-Purchase-btn-hover"
                                            style={{ marginLeft: "43.5%" }}
                                          >
                                            {/**<PlusOutlined /> */} Add Color
                                          </Button>
                                        </Form.Item>
                                      </span>
                                    );
                                  }}
                                </Form.List>
                              </span>
                            ))}
                            <Form.Item>
                              <Button
                                // type="dashed"
                                disabled={disabled}
                                danger
                                onClick={() => {
                                  add();
                                }}
                                block
                                className="add-Purchase-btn-hover"
                                // icon={<PlusOutlined />}
                              >
                                Add Size
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              // onClick={this.showDataOnList}
                              className="close-modal addItemList-focus"
                              loading={loading}
                              block
                            >
                              Add Items To List
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ShowPurchaseDetails
                      myPurchaseData={this.state.data.myPurchaseData}
                      deletePurchase={this.deletePurchase}
                      updatePurchase={this.updatePurchase}
                    />
                  </Col>
                </Row>

                <Row gutter={[8, 0]} className="mt-3">
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Total Item Quantity">
                      <Form.Item>
                        <Input
                          placeholder="Total Item Quantity"
                          name="totalQuantity"
                          value={this.state.data.totalQuantity}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Total Amount">
                      <Form.Item>
                        <Input
                          placeholder="Total Amount"
                          name="totalAmount"
                          value={this.state.data.totalAmount}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Total Discount">
                      <Form.Item>
                        <Input
                          type="number"
                          addonBefore="%"
                          placeholder="Total Discount"
                          name="finalDiscount"
                          value={this.state.data.finalDiscount}
                          onFocus={(e) => {
                            e.target.addEventListener(
                              "wheel",
                              this.preventScroll
                            );
                          }}
                          onBlur={(e) => {
                            e.target.removeEventListener(
                              "wheel",
                              this.preventScroll
                            );
                            this.handleBlur(e);
                          }}
                          onChange={(e) => this.onChange(e, "finalAmount")}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Discounted Amount">
                      <Form.Item>
                        <Input
                          placeholder="Discounted Amount"
                          name="discountedAmount"
                          value={this.state.data.discountedAmount}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>

                <Row gutter={[8, 0]}>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Final Amount">
                      <Form.Item>
                        <Input
                          placeholder="Final Amount"
                          name="finalAmount"
                          value={Number(this.state.data.finalAmount).toFixed()}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Enter Tax">
                      <Form.Item>
                        <Input
                          type="number"
                          addonBefore="%"
                          placeholder="Enter Tax"
                          onFocus={(e) => {
                            e.target.addEventListener(
                              "wheel",
                              this.preventScroll
                            );
                          }}
                          onBlur={(e) => {
                            e.target.removeEventListener(
                              "wheel",
                              this.preventScroll
                            );
                            this.handleBlur(e);
                          }}
                          name="tax"
                          value={this.state.data.tax}
                          onChange={(e) => this.onChange(e, "netAmount")}
                          min="0"
                          step="1"
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>{" "}
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Taxed Amount">
                      <Form.Item>
                        <Input
                          placeholder="Taxed Amount"
                          name="taxedAmount"
                          value={Number(this.state.data.taxedAmount).toFixed()}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Tooltip title="Net Amount">
                      <Form.Item>
                        <Input
                          placeholder="Net Amount"
                          name="netAmount"
                          value={Number(this.state.data.netAmount).toFixed()}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item>
                      {(() => {
                        if (this.state.data.netAmount == "") {
                          return (
                            <Button
                              type="primary"
                              className="ok-modal mt-3 text-white"
                              disabled
                              block
                            >
                              Save <StopOutlined />
                            </Button>
                          );
                        } else {
                          let indexdecimal =
                            this.state.data.myPurchaseData.findIndex(
                              (obj) => obj.quantity % obj.MOQ != 0
                            );
                          return (
                            <Button
                              type="primary"
                              className="ok-modal mt-3"
                              loading={loading}
                              disabled={this.state.spinLoad}
                              onClick={() => this.addPurchase(indexdecimal)}
                              block
                            >
                              Save
                            </Button>
                          );
                        }
                      })()}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
AddPurchase.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  getSeasons: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  getFloors: PropTypes.func.isRequired,
  getTexture: PropTypes.func.isRequired,
  getAgeGroups: PropTypes.func.isRequired,
  addPurchase: PropTypes.func.isRequired,
  getLookupData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getCustomers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dealers: state.dealersData.dealers,
  sections: state.sectionData.sections,
  textures: state.textureData.textures.data,
  seasons: state.seasonData.seasons,
  locations: state.locationData.locations,
  groups: state.groupData.groups,
  colors: state.colorData.colors,
  categories: state.categoryData.categories,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  styles: state.styleData.styles,
  sizes: state.sizeData.sizes,
  floors: state.floorData.floors,
  ageGroups: state.ageGroupData.ageGroups,
  purchases: state.purchaseData.purchases,
  purchaserList: state.customerData.salesPerson,
});

export default withRouter(
  connect(mapStateToProps, {
    getDealers,
    getBrandBySearch,
    getCategories,
    getGroups,
    getStyles,
    getSeasons,
    getSections,
    getLocations,
    getSizes,
    getColors,
    getAgeGroups,
    getFloors,
    getTexture,
    addPurchase,
    getLookupData,
    getCustomers,
  })(AddPurchase)
);
