import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import { Tooltip, Switch } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "antd";
import {
  fetchAllProductsSidebarList,
  fetchProducts,
} from "../../redux/actions/productActions";
import { useDispatch } from "react-redux";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const [scroll, setScroll] = useState(0);
  const location = useLocation();
  const [headerTop, setHeaderTop] = useState(0);
  const ecomSales = localStorage.getItem("ecomSales");
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };



  return (
    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${
        headerPositionClass ? headerPositionClass : ""
      }`}
    >
      <div
        className={`${headerPaddingClass ? headerPaddingClass : ""} ${
          top === "visible" ? "d-none d-lg-block" : "d-none"
        } header-top-area ${
          borderStyle === "fluid-border" ? "border-none" : ""
        }`}
      >
        {/** <div className={layout === "container-fluid" ? layout : "container"}>
          <HeaderTop borderStyle={borderStyle} />
        </div>*/}
      </div>

      <div
        className={` ${
          headerPaddingClass ? headerPaddingClass : ""
        } sticky-bar header-res-padding clearfix ${
          scroll > headerTop ? "stick" : ""
        }`}
      >
        <div
          className={`${
            layout === "mx-4" ? layout : "container-fluid"
          } headerParentContainer`}
        >
          <div className="row headerMain">
            <div className="col-xl-1 col-lg-1 col-md-2 col-2 d-flex justify-content-center align-items-center">
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
            </div>
            <div className="col-md-0 col-lg-7 headerResponsive">
              {/* Nav menu */}
              <NavMenu />
            </div>
            <div className="col-md-5 col-lg-4 col-10 d-flex justify-content-end align-items-center">
              <Tooltip title="Sales Item">
              </Tooltip>
              <IconGroup />
            </div>
          </div>
        </div>
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
