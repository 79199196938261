export const GET_ERRORS = "GET_ERRORS";

//dealer
export const ADD_DEALER = "ADD_DEALER";
export const GET_DEALERS = "GET_DEALERS";
export const DELETE_DEALER = "DELETE_DEALER";
export const UPDATE_DEALER = "UPDATE_DEALER";
export const ADD_IMAGE_BRAND = "ADD_IMAGE_BRAND";

//brand
export const ADD_BRAND = "ADD_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const DELETE_BRAND = "DELETE_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const BRAND_STATUS = "BRAND_STATUS";
export const GET_BRAND_IMAGES = "GET_BRAND_IMAGES";
export const GET_PAGINATED_LIST = "GET_PAGINATED_LIST"
export const GET_BRAND_BYSEARCH = "GET_BRAND_BYSEARCH"

//category
export const ADD_CATEGORY = "ADD_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const CATEGORY_STATUS = "CATEGORY_STATUS";
export const GET_PRODUCTS_BY_CATEGORY = "GET_PRODUCTS_BY_CATEGORY";

//color
export const ADD_COLOR = "ADD_COLOR";
export const GET_COLORS = "GET_COLORS";
export const DELETE_COLOR = "DELETE_COLOR";
export const UPDATE_COLOR = "UPDATE_COLOR";

//group
export const ADD_GROUP = "ADD_GROUP";
export const GET_GROUPS = "GET_GROUPS";
export const DELETE_GROUP = "DELETE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";

//location
export const ADD_LOCATION = "ADD_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";

//season
export const ADD_SEASON = "ADD_SEASON";
export const GET_SEASONS = "GET_SEASONS";
export const DELETE_SEASON = "DELETE_SEASON";
export const UPDATE_SEASON = "UPDATE_SEASON";

//size
export const ADD_SIZE = "ADD_SIZE";
export const GET_SIZES = "GET_SIZES";
export const DELETE_SIZE = "DELETE_SIZE";
export const UPDATE_SIZE = "UPDATE_SIZE";

//style
export const ADD_STYLE = "ADD_STYLE";
export const GET_STYLES = "GET_STYLES";
export const DELETE_STYLE = "DELETE_STYLE";
export const UPDATE_STYLE = "UPDATE_STYLE";
export const GET_STYLES_BY_CATEGORY = "GET_STYLES_BY_CATEGORY";
export const GET_PRODUCTS_BY_STYLE = "GET_PRODUCTS_BY_STYLE";

//section
export const ADD_SECTION = "ADD_SECTION";
export const GET_SECTIONS = "GET_SECTIONS";
export const DELETE_SECTION = "DELETE_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";

//floor
export const ADD_FLOOR = "ADD_FLOOR";
export const GET_FLOORS = "GET_FLOORS";
export const DELETE_FLOOR = "DELETE_FLOOR";
export const UPDATE_FLOOR = "UPDATE_FLOOR";

//age group
export const ADD_AGE_GROUP = "ADD_AGE_GROUP";
export const GET_AGE_GROUPS = "GET_AGE_GROUPS";
export const DELETE_AGE_GROUP = "DELETE_AGE_GROUP";
export const UPDATE_AGE_GROUP = "UPDATE_AGE_GROUP";

//purchase
export const ADD_PURCHASE = "ADD_PURCHASE";
export const GET_PURCHASES = "GET_PURCHASES";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";
export const GET_PURCHASES_BILL = "GET_PURCHASES_BILL";
export const GET_PURCHASE = "GET_PURCHASE";
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_ECOM_SALES = "GET_ECOM_SALES";
export const ADD_ECOM_SALE = "ADD_ECOM_SALE";
export const GET_PURCHASE_RETURN_BARCODE = "GET_PURCHASE_RETURN_BARCODE";
export const GET_PURCHASE_RETURNS = "GET_PURCHASE_RETURNS";
export const GET_PURCHASE_RETURN_BILLLIST = "GET_PURCHASE_RETURN_BILLLIST";
export const GET_PURCHASE_RETURN_BILLLIST_BYID = "GET_PURCHASE_RETURN_BILLLIST_BYID";
export const GET_PURCHASERETURN_INVOICE = "GET_PURCHASERETURN_INVOICE";
export const DELETE_PURCHASE_RETURN = "DELETE_PURCHASE_RETURN";
export const GET_PURCHASE_SEARCH_VALUE = "GET_PURCHASE_SEARCH_VALUE"
export const GET_PURCHASES_BYDATE = "GET_PURCHASES_BYDATE"
export const GET_PURCHASESBILLS_BYDATE = "GET_PURCHASESBILLS_BYDATE"
export const GET_INVENTORY_BYSEARCH = "GET_INVENTORY_BYSEARCH"
export const GET_GENERATEBARCODE_BYSEARCH = "GET_GENERATEBARCODE_BYSEARCH"
export const GET_NAGITIVE_INVENTORY = "GET_NAGITIVE_INVENTORY"
export const GET_BRAND_DESIGN_COMBINATION = "GET_BRAND_DESIGN_COMBINATION"
export const GET_BRAND_DESIGN_COMBO_FORSALE = "GET_BRAND_DESIGN_COMBO_FORSALE"
export const GET_DESIGNANDBRAND_BY_BARCODE = "GET_DESIGNANDBRAND_BY_BARCODE"
export const GET_BANNER_LIST = "GET_BANNER_LIST"
export const GET_LATEST_BANNER_LIST = "GET_LATEST_BANNER_LIST"
export const STOCK_SEARCH_SALESAPP = "STOCK_SEARCH_SALESAPP"
export const STOCK_FILTER_SALESAPP = "STOCK_FILTER_SALESAPP"
export const STOCK_DETAILS_BY_BARCODE = "STOCK_DETAILS_BY_BARCODE"
export const GET_BRAND_AND_DESIGN_BYSEARCH = "GET_BRAND_AND_DESIGN_BYSEARCH"
export const CLEAR_DATA_BY_BRAND_AND_DESIGN = "CLEAR_DATA_BY_BRAND_AND_DESIGN"


//customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";

//sale
export const CREATE_SALES = "CREATE_SALES";
export const GET_SALES = "GET_SALES";
export const GET_SALES_BILL = "GET_SALES_BILL";
export const GET_SALES_INVOICE = "GET_SALES_INVOICE";
export const GET_SALES_BILLS_USERID = "GET_SALES_BILLS_USERID";
export const GET_SALES_USING_BARCODE_USERID = "GET_SALES_USING_BARCODE_USERID";
export const GET_SALES_RETURNS = "GET_SALES_RETURNS";
export const GET_SALES_RETURN_BILL = "GET_SALES_RETURN_BILL"
export const GET_SALES_RETURN_SALERETURNID = "GET_SALES_RETURN_SALERETURNID";
export const GET_DEFECTED_PRODUCT_SALERETURNID = "GET_DEFECTED_PRODUCT_SALERETURNID";
export const GET_SALES_RETURNS_DEFECTED = "GET_SALES_RETURNS_DEFECTED";
export const GET_SALESRETURN_INVOICE = "GET_SALESRETURN_INVOICE";
export const GET_SALESRETURNDEFECTED_INVOICE = "GET_SALESRETURNDEFECTED_INVOICE"
export const GET_DEFECTED_PRODUCT_BILL = "GET_DEFECTED_PRODUCT_BILL";
export const DELETE_SALE = "DELETE_SALE";
export const GET_SALES_LIST = "GET_SALES_LIST";
export const GET_SALES_LIST_BYDATE = "GET_SALES_LIST_BYDATE"
export const GET_SALES_PRODUCT_BY_INVOICE_ID = "GET_SALES_PRODUCT_BY_INVOICE_ID";
export const GET_SALES_ORDERBYBILL_BYDATE = "GET_SALES_ORDERBYBILL_BYDATE";
export const GET_SALESRETURN_BYDATERANGE = "GET_SALESRETURN_BYDATERANGE";
export const GET_SALESRETURNBILL_BYDATERANGE = "GET_SALESRETURNBILL_BYDATERANGE";
export const GET_SALESRETURN_DEFECTED_BYDATE = "GET_SALESRETURN_DEFECTED_BYDATE";
export const GET_ALLDEFECTEDPRODUCTBILL_BYDATE = "GET_ALLDEFECTEDPRODUCTBILL_BYDATE";
export const GET_PURCHASERETURN_BYDATE = "GET_PURCHASERETURN_BYDATE";
export const GET_PURCHASE_RETURN_BILLLIST_BYDATE = "GET_PURCHASE_RETURN_BILLLIST_BYDATE";
export const GET_PURCHASES_BYID = "GET_PURCHASES_BYID";
export const ADD_ONLINE_ECOM_SALE = "ADD_ONLINE_ECOM_SALE";
export const CHECKLAST_FIVEBILLS = "CHECKLAST_FIVEBILLS";
export const GET_SALESWITHCUSTOMER = "GET_SALESWITHCUSTOMER";
export const GET_DEFECTIVE_SALES_RETURN_SALERETURNID = "GET_DEFECTIVE_SALES_RETURN_SALERETURNID";
export const GET_SALES_BILLBYCUSTOMERID = "GET_SALES_BILLBYCUSTOMERID";
export const GET_SALES_LISTBYINVOICEID = "GET_SALES_LISTBYINVOICEID";
export const GET_SALESDETAILS_BYINVOICEIDS = "GET_SALESDETAILS_BYINVOICEIDS";
export const CHECKBILL_IS_VERIFIED = "CHECKBILL_IS_VERIFIED";
export const ACTIVE_AND_INACTIVE_CUSTOMERS = "ACTIVE_AND_INACTIVE_CUSTOMERS";
export const GET_SALE_DEFECTIVE_SEARCH_VALUE = "GET_SALE_DEFECTIVE_SEARCH_VALUE";
export const GET_ECOM_SALES_INVOICE = "GET_ECOM_SALES_INVOICE";
export const GET_SALESORDER_BYCUSTOMERID = "GET_SALESORDER_BYCUSTOMERID";
export const SALES_BILL_BY_SALES_PERSON_ID = "SALES_BILL_BY_SALES_PERSON_ID";
export const SALES_BY_SEARCH_VALUE = "SALES_BY_SEARCH_VALUE";
export const GET_UPLODED_LR_LIST = "GET_UPLODED_LR_LIST";
export const GET_LASTSALESDATA_FOR_LR = "GET_LASTSALESDATA_FOR_LR";


//
export const DONE_DONE = "DONE_DONE";
export const GET_PRODUCTS_ON_SEARCH = "GET_PRODUCTS_ON_SEARCH";
export const GET_SALE_SEARCH_VALUE = "GET_SALE_SEARCH_VALUE"
export const GET_SALE_ORDER_SEARCH_VALUE = "GET_SALE_ORDER_SEARCH_VALUE"

//User
export const USER_LOGIN = "USER_LOGIN";
export const USER_REGISTER = "USER_REGISTER";
export const USER_DETAILS = "USER_DETAILS";
export const LIST_USERS = "LIST_USERS";
export const ADD_AGENT = "ADD_AGENT";
export const GET_AGENTS = "GET_AGENTS";
export const GET_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_USERS_NAME_USERNAME = "GET_USERS_NAME_USERNAME";
export const ADD_USER = "ADD_USER";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_PINCODE_DATA = "GET_PINCODE_DATA";
export const GET_AGENTS_LIST = "GET_AGENTS_LIST";

//Role
export const GET_ROLES = "GET_ROLES";

//REPORTS
export const GET_BEST_BRAND_REPORT = "GET_BEST_BRAND_REPORT"
export const GET_CUSTOMER_SALES_REPORTS = "GET_CUSTOMER_SALES_REPORTS";
export const GET_ALL_CUSTOMER_SALES_REPORTS = "GET_ALL_CUSTOMER_SALES_REPORTS";
export const GET_PURCHASE_REPORTS = "GET_PURCHASE_REPORTS";
export const GET_REPORTS = "GET_REPORTS";
export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const GET_STOCKAGE_REPORTS = "GET_STOCKAGE_REPORTS";
export const GET_SALESCOMPARISON_REPORTS = "GET_SALESCOMPARISON_REPORTS";
export const GET_FASTMOVING_REPORTS = "GET_FASTMOVING_REPORTS";
export const GET_BARCODE_REPORTS = "GET_BARCODE_REPORTS"
export const GET_NONMOVING_REPORTS = "GET_NONMOVING_REPORTS";
export const GET_TOPCUSTOMER_REPORTS = "GET_TOPCUSTOMER_REPORTS";
export const GET_INACTIVECUSTOMER_REPORTS = "GET_INACTIVECUSTOMER_REPORTS";
export const GET_SALESAGEING_REPORTS = "GET_SALESAGEING_REPORTS";
export const GET_AGENTWISE_REPORTS = "GET_AGENTWISE_REPORTS";
export const GET_SALESSUMMARY_REPORTS = "GET_SALESSUMMARY_REPORTS";
export const GET_ALL_SALESSUMMARY_REPORTS = "GET_ALL_SALESSUMMARY_REPORTS";
export const GET_SALESITEM_REPORTS = "GET_SALESITEM_REPORTS";
export const GET_SALESRETURN_REPORTS = "GET_SALESRETURN_REPORTS";
export const GET_DEALERPURCHASE_REPORTS = "GET_DEALERPURCHASE_REPORTS";
export const GET_ALL_DEALERPURCHASE_REPORTS = "GET_ALL_DEALERPURCHASE_REPORTS";
export const GET_PURCHASERETURN_REPORTS = "GET_PURCHASERETURN_REPORTS";
export const GET_ALL_PURCHASERETURN_REPORTS = "GET_ALL_PURCHASERETURN_REPORTS";
export const GET_SALESPERSON_REPORTS = "GET_SALESPERSON_REPORTS";
export const GET_PURCHASEALL_REPORTS = "GET_PURCHASEALL_REPORTS";
export const GET_SALESALL_REPORTS = "GET_SALESALL_REPORTS";
export const GET_ALL_SALESALL_REPORTS = "GET_ALL_SALESALL_REPORTS";
export const GET_CREDITANDDEBIT_REPORTS = "GET_CREDITANDDEBIT_REPORTS";
export const GET_STOCK_REPORTS = "GET_STOCK_REPORTS";
export const GET_STOCKAGE_REPORTS_BYSEARCH = "GET_STOCKAGE_REPORTS_BYSEARCH";
export const GET_ALL_STOCKAGE_REPORTS_BYSEARCH = "GET_ALL_STOCKAGE_REPORTS_BYSEARCH";
export const GET_STOCKAGE_BI_REPORTS_BYFILTER = "GET_STOCKAGE_BI_REPORTS_BYFILTER";
export const FLLOR_SECTION_BARND_SEARCH = "FLLOR_SECTION_BARND_SEARCH";
export const GET_SALESANDSALESAGE_REPORTS = "GET_SALESANDSALESAGE_REPORTS";
export const GET_CUSTOMER_REPORT = "GET_CUSTOMER_REPORT"
export const GET_SALESRETURN_REPORTS_Amount = "GET_SALESRETURN_REPORTS_Amount"
export const GET_BEST_SELLING_REPORT = "GET_BEST_SELLING_REPORT"
export const FLLOR_SECTION_BARND_SEARCH_FROM = "FLLOR_SECTION_BARND_SEARCH_FROM";
export const FLLOR_SECTION_BARND_SEARCH_TO = "FLLOR_SECTION_BARND_SEARCH_TO";

//COUNT
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_GROSSPROFIT_DATA = "GET_GROSSPROFIT_DATA";


//Po order
export const ADD_PO = "ADD_PO";
export const GET_POCREDENTIALS = "GET_POCREDENTIALS"
export const GET_PO_LIST = "GET_PO_LIST"
export const GET_PO_DATABYID = "GET_PO_DATABYID"

// Sales by User
export const SALES_BYUSER = "SALES_BYUSER"

//Clear Redux Reports Data
export const CLEAR_DATA = "CLEAR_DATA"

// Handle ProgressBar
export const SHOW_PROGRESS_BAR = "SHOW_PROGRESS_BAR"
export const HIDE_PROGRESS_BAR = "HIDE_PROGRESS_BAR"

// Opening Stock
export const GET_OPENINGSTOCK_DATA = "GET_OPENINGSTOCK_DATA"

//Recycle Bin
export const GET_DELETED_SALES_LIST = "GET_DELETED_SALES_LIST";
export const RESTORE_DELETED_SALE = "RESTORE_DELETED_SALE";
export const GET_DELETED_SALES_RETURN_LIST = "GET_DELETED_SALES_RETURN_LIST";
export const RESTORE_DELETED_SALE_RETURN = "RESTORE_DELETED_SALE_RETURN";
export const GET_DELETED_DEFECTIVE_RETURN_LIST = "GET_DELETED_DEFECTIVE_RETURN_LIST";
export const RESTORE_DELETED_DEFECTIVE_RETURN = "RESTORE_DELETED_DEFECTIVE_RETURN";
export const GET_DELETED_PURCHASE_RETURN_LIST = "GET_DELETED_PURCHASE_RETURN_LIST";
export const RESTORE_DELETED_PURCHASE_RETURN = "RESTORE_DELETED_PURCHASE_RETURN";

//Warehouse
export const WAREHOUSE_INVENTORY_LIST = "WAREHOUSE_INVENTORY_LIST";
export const ADD_WAREHOUSE_SALE = "ADD_WAREHOUSE_SALE";
export const WAREHOUSE_SALE_LIST = "WAREHOUSE_SALE_LIST";
export const WAREHOUSE_SALE_LIST_BY_BILL_ID = "WAREHOUSE_SALE_LIST_BY_BILL_ID"
export const WAREHOUSE_PURCHASE_BILL_LIST = "WAREHOUSE_PURCHASE_BILL_LIST"
export const WAREHOUSE_PURCHASE_BILL = "WAREHOUSE_PURCHASE_BILL"
export const WAREHOUSE_BARCODE = "WAREHOUSE_BARCODE"
export const WAREHOUSE_LOOK_UP_BARCODE = "WAREHOUSE_LOOK_UP_BARCODE"
export const WH_INVENTORY_BARCODE_BYSEARCH = "WH_INVENTORY_BARCODE_BYSEARCH"
export const WH_PURCHASEBILL_BYSEARCH = "WH_PURCHASEBILL_BYSEARCH"
export const WH_OUTBILL_BYSEARCH = "WH_OUTBILL_BYSEARCH"
export const WH_EMPTY_INVENTORY_DOWNLOAD = "WH_EMPTY_INVENTORY_DOWNLOAD"

//ECOM Sale 
export const GET_ECOM_SALE_Bill = "GET_ECOM_SALE_Bill"
export const GET_ECOM_SALE_List = "GET_ECOM_SALE_List"
export const Edit_USER_Credit = "Edit_USER_Credit"
export const UPDATE_ORDERSTATUS = "UPDATE_ORDERSTATUS"
export const GET_ECOM_SALES_BILLBYCUSTOMERID = "GET_ECOM_SALES_BILLBYCUSTOMERID"
export const GET_ECOM_SALES_LISTBY_CUSTOMERID = "GET_ECOM_SALES_LISTBY_CUSTOMERID"
export const CHECK_ECOM_BILL_IS_VERIFIED = "CHECK_ECOM_BILL_IS_VERIFIED"
export const GET_ECOM_SALESDETAILS_BYINVOICEIDS = "GET_ECOM_SALESDETAILS_BYINVOICEIDS"
export const ECOM_SALES_BILL_DETAILS = "ECOM_SALES_BILL_DETAILS"
export const ECOM_SALES_BILL_WHATSAPP = "ECOM_SALES_BILL_WHATSAPP"
export const CREDIT_PAYMENT_BY_SEARCH_VALUE = "CREDIT_PAYMENT_BY_SEARCH_VALUE"
export const CREDIT_PAYMENT_BY_DATE_RANGE = "CREDIT_PAYMENT_BY_DATE_RANGE"
export const GET_ECOM_SALE_RETURN_Bill = "GET_ECOM_SALE_RETURN_Bill"
export const GET_ECOM_SALE_RETURN_ProductBY_ID = "GET_ECOM_SALE_RETURN_ProductBY_ID"


// Change Category
export const GET_Agent_Change = "GET_Agent_Change"
export const GET_USERBY_AGENT = "GET_USERBY_AGENT"

//Available Stock
export const GET_AVAILABLE_STOCK = "GET_AVAILABLE_STOCK"
export const GET_AVAILABLE_STOCK_BY_SEARCH = "GET_AVAILABLE_STOCK_BY_SEARCH"




//texture
export const ADD_TEXTURE = "ADD_TEXTURE";
export const GET_TEXTURE = "GET_TEXTURE";
export const DELETE_TEXTURE = "DELETE_TEXTURE";
export const UPDATE_TEXTURE = "UPDATE_TEXTURE";
export const GET_TEXTURE_BY_ID = "GET_TEXTURE_BY_ID";


// Purchase Order
export const GET_PURCHASES_ORDERS = "GET_PURCHASES_ORDERS";
export const GET_PURCHASE_ORDER_BY_ID = "GET_PURCHASE_ORDER_BY_ID";
