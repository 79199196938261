import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAvailableStock } from "../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import Search from "../../../util/Search";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { getStockReport } from "../../../redux/actions/reportsActions";

const searchOptions = ["Barcode"];
const { Column } = Table;

class AvailableDtockReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      loading: false
    };
  }

  componentWillUnmount() {
    this.props.getStockReport("reset");
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cancelState = () => {
    this.setState({ searchValue: "" });
  };
  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  render() {
    const { searchValue } = this.state;
    const { availableStock, availableStockBySearchconst, reports } = this.props;
    const headers = [
      { label: "Bill DealerName", key: "dealerName" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Floor", key: "floorName" },
      { label: "Section", key: "section" },
      { label: "Season", key: "season" },      
      { label: "MOQ", key: "MOQ" },
      { label: "Quantity", key: "quantity" },
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "WSP", key: "wsp" },
      { label: "Total Purchase", key: "totalPurchase" },
      { label: "Total Sale", key: "tatalSale" },
    ];

    const handleDownloadButtonClick = async () => {
      this.setState({ loading: true });
    
      try {
    
        const csvData = this.props.reports.data;
    
        // Generate CSV content using PapaParse
        const csv = Papa.unparse({
          fields: headers?.map((header) => header.label),
          data: csvData.map((row) =>
            headers?.map((header) => row[header.key])
          ),
        });
    
        // Create Blob and initiate file download
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Available_Stock.csv`;
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    };

    return (
      <div>
        {/* <Row className="mt-4">
          <Search title="Available Stock" placeholder="Barcode" searchopt={searchOptions} cancelState={this.cancelState} changeFunction={this.onChange} />
        </Row> */}
        <>
        <div className="card mt-4 final-purchases-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div>
            <Table
              dataSource={reports && reports?.data || []}
              bordered="true"
              size="middle"
              // pagination={{
              //   pageSize: 100,
              //   total: searchValue && search.length > 0 ? search.length : availableStock?.totalCount,
              //   onChange: (page) => {
              //     this.props.getAvailableStock(page);
              //   },
              // }}
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Dealer Name"
                dataIndex="dealerName"
                key="dealerName"
                width={100}
                {...this.getColumnSearchProps("dealerName")}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={150}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                {...this.getColumnSearchProps("brand")}
                width={120}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={80}
                {...this.getColumnSearchProps("color")}
              />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                {...this.getColumnSearchProps("category")}
                width={120}
              />
              <Column
                title="Style"
                dataIndex="style"
                key="style"
                {...this.getColumnSearchProps("style")}
                width={120}
              />
              <Column
                title="Floor"
                dataIndex="floorName"
                key="floorName"
                {...this.getColumnSearchProps("floorName")}
                width={100}
              />
              <Column
                title="Section"
                dataIndex="section"
                key="section"
                width={120}
                {...this.getColumnSearchProps("section")}
              />
              <Column
                title="Season"
                dataIndex="season"
                key="season"
                width={120}
                {...this.getColumnSearchProps("season")}
              />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={100} />
              <Column
                title="Stock(QTY)"
                dataIndex="quantity"
                key="quantity"
                width={100}
                ender={(quantity) => (
                  <>
                    {(() => {
                      if (quantity <= 15) {
                        return (
                          <Tag color="volcano" key={quantity}>
                            {quantity}
                          </Tag>
                        );
                      } else {
                        return quantity;
                      }
                    })()}
                  </>
                )}
              />
              <Column
                title="Purchase Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={150}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              <Column
                title="Total Purchase"
                dataIndex="totalPurchase"
                key="totalPurchase"
                width={150}
              />
              <Column
                title="Total Sale"
                dataIndex="tatalSale"
                key="tatalSale"
                width={140}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (reports?.data?.length > 0) {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-modal mt-4"
                  block
                  onClick={handleDownloadButtonClick}
                >
                  {this.state.loading ? "Loading..." : "Download CSV"}
                </Button>
              </>
            );
          }
        })()}
        </>
      </div>
    );
  }
}

AvailableDtockReportList.propTypes = {
  getAvailableStock: PropTypes.func.isRequired,
  geteStockReport: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  reports: state?.reportData?.stockReport?.data,
  availableStock: state.purchaseData.availableStock,
  availableStockBySearch: state.purchaseData.availableStockBySearch,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getAvailableStock,
  getStockReport,
})(AvailableDtockReportList);