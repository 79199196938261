import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import moment from "moment";
const { Column } = Table;

export default class SalesPersonReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    const { reports } = this.props;
    const headers = [
      { label: "Name", key: "salesPersonName" },
      { label: "Total Quantity ", key: "salesQuantity" },
      { label: "Total Amount ", key: "salesTotalAmount" },
      { label: "Quantity Before Sales Age 120", key: "before120Quantity" },
      { label: "Amount Before Sales Age 120 ", key: "before120Amount" },
      { label: "Quantity After Sales Age 120", key: "after120Quantity" },
      { label: "Amount After Sales Age 120", key: "after120Amount" },
    ];

    return (
      <>
        <div className="card mt-3 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={
                reports && reports.data && reports.data.length > 0
                  ? reports.data
                  : []
              }
              bordered={true}
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Name"
                dataIndex="salesPersonName"
                key="salesPersonName"
                width={110}
                {...this.getColumnSearchProps("salesPersonName")}
              />
              <Column
                title="Total Quantity"
                dataIndex="salesQuantity"
                key="salesQuantity"
                width={170}
                {...this.getColumnSearchProps("salesQuantity")}
              />
              <Column
                title="Total Amount"
                dataIndex="salesTotalAmount"
                key="salesTotalAmount"
                width={170}
                {...this.getColumnSearchProps("salesTotalAmount")}
              />
              <Column
                title="Quantity Before Sales Age 120"
                dataIndex="before120Quantity"
                key="before120Quantity"
                width={300}
                {...this.getColumnSearchProps("before120Quantity")}
              />
              <Column
                title="Amount Before Sales Age 120"
                dataIndex="before120Amount"
                key="before120Amount"
                width={300}
                {...this.getColumnSearchProps("before120Amount")}
              />
              <Column
                title="Quantity After Sales Age 120"
                dataIndex="after120Quantity"
                key="after120Quantity"
                width={300}
                {...this.getColumnSearchProps("after120Quantity")}
              />
              <Column
                title="Amount After Sales Age 120"
                dataIndex="after120Amount"
                key="after120Amount"
                width={300}
                {...this.getColumnSearchProps("after120Amount")}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (
            this.props &&
            this.props.reports &&
            this.props.reports.data &&
            this.props.reports.data.length > 0
          ) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={this.props.reports.data}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}