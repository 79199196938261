import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Upload, Tooltip, message, Switch } from "antd";
import { PlusCircleOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { addProductImage } from "../../../redux/actions/purchaseAction";
import { getUniquePurchases, addEcomSale, deletImageByBarcode } from "../../../redux/actions/purchaseAction";
import { getBrandImages, deleteProductImage } from "../../../redux/actions/brandAction"
import noImage from "../../../assets/images/noImage.png"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import AddGlobalImage from "./AddGlobalImage";
import UpdateSingleImage from "./UpdateSingleImage";
import AddSingleImage from "./AddSingleImage";
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

class AddImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinStatus0: false,
            spinStatus1: false,
            spinStatus2: false,
            spinStatus3: false,
            loading: false,
            visible: false,
            name: "",
            previewVisible: false,
            previewImage: "",
            previewTitle: "",
            fileList1: [],
            fileList2: [],
            fileList3: [],
            fileList4: [],
            image: "",
            errors: {},
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    showModal = () => {
        this.props.getBrandImages({ barcode: this.props.data.barcode })
        this.setState({
            visible: true,
        });
    };

    handleModalCancel = () => {
        this.setState({ visible: false });
    };

    componentDidMount = () => {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.errors !== this.props.errors) {
            //Perform some operation here

            this.setState({ errors: this.props.errors });
            message.error(this.props.errors.message);
        }
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDefaultPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true,
            previewTitle: this.props.data.name,
        });
    }

    handleDelete = async (url, index) => {
        if (url) {
            if (index == 0) {
                this.setState({ spinStatus0: true })
            } else if (index == 1) {
                this.setState({ spinStatus1: true })
            } else if (index == 2) {
                this.setState({ spinStatus2: true })
            } else if (index == 3) {
                this.setState({ spinStatus3: true })
            } else {

            }
            let response = await this.props.deleteProductImage({ imageName: url, barcode: this.props?.data?.barcode })
            if (response.status == 200) {
                this.setState({ visible: false, spinStatus0: false, spinStatus1: false, spinStatus2: false, spinStatus3: false, });
            }
        }
        else {
            message.warning("No Image Uploaded to Delete")
        }
    }
    handleDeleteAll = () => {
        this.setState({ spinStatus0: true, spinStatus1: true, spinStatus2: true, spinStatus3: true })
        let requestBody = {
            imageName: this.props.productsImages,
            barcode: this.props?.data?.barcode
        }
        let response = this.props.deletImageByBarcode(requestBody)
            .then(obj => {
                if (obj.status == 200) {
                    return this.setState({ visible: false, spinStatus0: false, spinStatus1: false, spinStatus2: false, spinStatus3: false, })
                }
            }).catch(obj => {
                this.setState({ visible: false, spinStatus0: false, spinStatus1: false, spinStatus2: false, spinStatus3: false, })
                return message.error("Something went wrong!")
            })
    }
    
    onSubmit = (event) => {
        this.setState({ loading: true });
        // console.log("check mohit inside onsubmit barcode", this.props.details.barcode)

        if (this.state.fileList1[0]) {
            let reponse = this.props.addProductImage(this.props.data.barcode, [
                this.state.fileList1[0]?.originFileObj,
                this.state.fileList2[0]?.originFileObj,
                this.state.fileList3[0]?.originFileObj,
                this.state.fileList4[0]?.originFileObj,
            ]);

            let data = reponse.then(obj => {
                if (obj.status == 200) {
                    message.success(" Imaged added Successfully");
                    this.setState({ loading: false, visible: false });
                    this.setState({ fileList1: [], fileList2: [], fileList3: [], fileList4: [], });
                }
            }).catch(error => {
                message.error("Something went wrong try again!")
                this.setState({ loading: false, visible: false });
                this.setState({ fileList1: [], fileList2: [], fileList3: [], fileList4: [], });
            })
        } else {
            this.setState({ loading: false });
            message.warn("Kindly Choose Images!")
        }
    }


    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file, fileListName) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };

    handleChange = ({ fileList }, fileListName) => {
        // Update state for each fileList
        if (fileListName === "fileList1") {
            this.setState({ fileList1: fileList });
        } else if (fileListName === "fileList2") {
            this.setState({ fileList2: fileList });
        } else if (fileListName === "fileList3") {
            this.setState({ fileList3: fileList });
        } else if (fileListName === "fileList4") {
            this.setState({ fileList4: fileList });
        }
    };

    // beforeUpload = (file) => {
    //     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    //     if (!isJpgOrPng) {
    //         message.error("You can only upload JPG/PNG file!");
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //         message.error("Image must smaller than 2MB!");
    //     }
    //     return isJpgOrPng && isLt2M;
    // };

    beforeUpload = (file, fileList) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            return false; // Prevent upload
        }
    
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must be smaller than 2MB!");
            return false; // Prevent upload
        }
    
        // Create a new FileReader to read the image file
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                // Check the dimensions of the image
                if (img.width !== 275 || img.height !== 325) {
                    message.error("Image must be 275px by 325px!");
                    if(fileList === "fileList1"){
                        this.setState({fileList1 : []})
                    }
                    if(fileList === "fileList2"){
                        this.setState({fileList2 : []})
                    }
                    if(fileList === "fileList3"){
                        this.setState({fileList3 : []})
                    }
                    if(fileList === "fileList4"){
                        this.setState({fileList4 : []})
                    }
                    return false; // Prevent upload
                }
                // If all checks pass, you can proceed with the upload
                // You may want to call a function to handle the upload here
            };
        };
        reader.readAsDataURL(file);
    
        // Return false to prevent the upload until the image dimensions are checked
        return false;
    };


    onSaleChange(barcode, text) {
        const newStatus = text.sales
        if (text.sales == 'ON') {
            let saleStatusChange = {
                salesDiscount: 0,
                barcode,
                status: "OFF",
            };
            console.log("off", saleStatusChange)

            this.props.addEcomSale(saleStatusChange)
            this.props.getUniquePurchases(1);
        } else {
            let saleStatusChange = {
                salesDiscount: 0,
                barcode,
                status: "ON",

            };
            console.log("on", saleStatusChange)

            this.props.addEcomSale(saleStatusChange);
            this.props.getUniquePurchases(1);

        }

    }

    render() {
        console.log("#render this.state.fileList : ", this.props.data.barcode);
        const { visible, loading } = this.state;
        const { previewVisible, previewImage, fileList1, previewTitle, fileList2, fileList3, fileList4 } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload Images</div>
            </div>
        );
        let newData = this.props.brandAndDesignCombo?.map(obj => obj.data)
        return (
            <Row>
                {console.log("check productImages", this.props.productsImages)}
                {console.log("this.prop.data.barcode",this.props.data.barcode)}
                <Col span={24}>
                    <div className="btn">
                    
                    {console.log("filelist1", fileList1)}
                                    {console.log("this.props.productsImages[0]", this.props.productsImages[0])}
                        <Tooltip title="Add Image">
                            <PlusCircleOutlined onClick={this.showModal} />
                        </Tooltip>
                    </div>
                    <Modal
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={this.handleModalCancel}
                        title="Add Images"
                        style={{ top: 50 }}
                        okButtonProps={{ hidden: true }}
                        cancelButtonProps={{ hidden: true }}
                        destroyOnClose={true}
                    >
                        <Form
                            name="add-Images"
                            className="add-Images"
                            onFinish={this.onSubmit}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Form.Item
                                name="upload"
                            // valuePropName="fileList"
                            // getValueFromEvent={normFile}
                            >
                                {
                                    this.props.productsImages && this.props.productsImages.length > 0 && (this.props.productsImages[0] || this.props.productsImages[1] || this.props.productsImages[2]) ? <>
                                        {this.props.productsImages[0] && <> <div className="brand-image-show">
                                            {
                                                this.props.productsImages[0] ? <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={this.props.productsImages[0]}
                                                    />
                                                </> : <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={noImage}
                                                    />
                                                </>
                                            }
                                            <div className="brand-image-show-button">
                                                <div onClick={() => this.handleDefaultPreview(this.props?.productsImages?.[0])} style={{ cursor: "pointer" }}>
                                                    <EyeOutlined className="brand-image-show-icon" />
                                                    <p>SHOW</p>
                                                </div>
                                                
                                                <div style={{ cursor: "pointer" }}>
                                                    {/* <AddGlobalImage barcodeData={newData} cancel={this.handleModalCancel} /> */}
                                                    
                                                    <UpdateSingleImage  barcode={this.props.data.barcode} url={this.props.productsImages[0]} imageName={this.props.productsImages[0]} cancel={this.handleModalCancel}/>
                                                </div>
                                                <div onClick={() => this.handleDelete(this.props.productsImages[0], 0)} style={{ cursor: "pointer" }}>
                                                    {this.state.spinStatus0 ? <LoadingOutlined
                                                        className="brand-image-show-icon"
                                                        style={{
                                                            fontSize: 24,
                                                        }}
                                                        spin
                                                    /> : <DeleteOutlined className="brand-image-show-icon" />}
                                                    <p>DELETE</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        {this.props.productsImages[0] && !this.props.productsImages[1] &&<div>
                                        <AddSingleImage  barcode={this.props.data.barcode} cancel={this.handleModalCancel}/>
                                        </div>}</>}

                                        {this.props.productsImages[1] && <><div className="brand-image-show">
                                            {
                                                this.props.productsImages[1] ? <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={this.props.productsImages[1]}
                                                    />
                                                </> : <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={noImage}
                                                    />
                                                </>
                                            }
                                            <div className="brand-image-show-button">
                                                <div onClick={() => this.handleDefaultPreview(this.props?.productsImages?.[1])} style={{ cursor: "pointer" }}>
                                                    <EyeOutlined className="brand-image-show-icon" />
                                                    <p>SHOW</p>
                                                </div>
                                                <div style={{ cursor: "pointer" }}>
                                                    {/* <AddGlobalImage barcodeData={newData} cancel={this.handleModalCancel} /> */}
                                                    <UpdateSingleImage  barcode={this.props.data.barcode} url={this.props.productsImages[1]} imageName={this.props.productsImages[1]} cancel={this.handleModalCancel}/>
                                                </div>
                                                <div onClick={() => this.handleDelete(this.props.productsImages[1], 1)} style={{ cursor: "pointer" }}>
                                                    {this.state.spinStatus1 ? <LoadingOutlined
                                                        className="brand-image-show-icon"
                                                        style={{
                                                            fontSize: 24,
                                                        }}
                                                        spin
                                                    /> : <DeleteOutlined className="brand-image-show-icon" />}
                                                    <p>DELETE</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        {this.props.productsImages[1] && !this.props.productsImages[2] &&<div>
                                        <AddSingleImage  barcode={this.props.data.barcode} cancel={this.handleModalCancel}/>
                                        </div>}</>}

                                        {this.props.productsImages[2] && <><div className="brand-image-show">
                                            {
                                                this.props.productsImages[2] ? <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={this.props.productsImages[2]}
                                                    />
                                                </> : <>
                                                    <img
                                                        alt="example"
                                                        style={{ width: "180px", height: "150px" }}
                                                        src={noImage}
                                                    />
                                                </>
                                            }
                                            <div className="brand-image-show-button">
                                                <div onClick={() => this.handleDefaultPreview(this.props?.productsImages?.[2])} style={{ cursor: "pointer" }}>
                                                    <EyeOutlined className="brand-image-show-icon" />
                                                    <p>SHOW</p>
                                                </div>
                                                <div style={{ cursor: "pointer" }}>
                                                    {/* <AddGlobalImage barcodeData={newData} cancel={this.handleModalCancel} /> */}
                                                    <UpdateSingleImage  barcode={this.props.data.barcode} url={this.props.productsImages[2]} imageName={this.props.productsImages[2]} cancel={this.handleModalCancel}/>
                                                </div>
                                                <div onClick={() => this.handleDelete(this.props.productsImages[2], 2)} style={{ cursor: "pointer" }}>
                                                    {this.state.spinStatus2 ? <LoadingOutlined
                                                        className="brand-image-show-icon"
                                                        style={{
                                                            fontSize: 24,
                                                        }}
                                                        spin
                                                    /> : <DeleteOutlined className="brand-image-show-icon" />}
                                                    <p>DELETE</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        {this.props.productsImages[2] && !this.props.productsImages[3] &&<div>
                                        <AddSingleImage  barcode={this.props.data.barcode} cancel={this.handleModalCancel}/>
                                        </div>}
                                        </>}

                                        {this.props.productsImages[3] &&
                                            <><div className="brand-image-show">
                                                {
                                                    this.props.productsImages[3] ? <>
                                                        <img
                                                            alt="example"
                                                            style={{ width: "180px", height: "150px" }}
                                                            src={this.props.productsImages[3]}
                                                        />
                                                    </> : <>
                                                        <img
                                                            alt="example"
                                                            style={{ width: "180px", height: "150px" }}
                                                            src={noImage}
                                                        />
                                                    </>
                                                }

                                                <div className="brand-image-show-button">
                                                    <div onClick={() => this.handleDefaultPreview(this.props?.productsImages?.[3])} style={{ cursor: "pointer" }}>
                                                        <EyeOutlined className="brand-image-show-icon" />
                                                        <p>SHOW</p>
                                                    </div>
                                                    <div style={{ cursor: "pointer" }}>
                                                        {/* <AddGlobalImage barcodeData={newData} cancel={this.handleModalCancel} /> */}
                                                        <UpdateSingleImage  barcode={this.props.data.barcode} url={this.props.productsImages[3]} imageName={this.props.productsImages[3]} cancel={this.handleModalCancel}/>
                                                    </div>
                                                    <div onClick={() => this.handleDelete(this.props.productsImages[3], 3)} style={{ cursor: "pointer" }}>
                                                        {this.state.spinStatus3 ? <LoadingOutlined
                                                            className="brand-image-show-icon"
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            spin
                                                        /> : <DeleteOutlined className="brand-image-show-icon" />}
                                                        <p>DELETE</p>
                                                    </div>
                                                </div>
                                            </div><hr /></>}
                                    </> : <>
                                        {console.log("check mohit heeeeloo filelist", fileList2)}
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            accept=".png, .jpg"
                                            listType="picture-card"
                                            fileList={fileList1}
                                            onPreview={this.handlePreview}
                                            // onChange={this.handleChange}
                                            onChange={(fileList) => this.handleChange(fileList, "fileList1")}
                                            // beforeUpload={this.beforeUpload}
                                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList1" )}

                                        >
                                            {fileList1.length === 1 ? null : uploadButton}
                                        </Upload>

                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            accept=".png, .jpg"
                                            listType="picture-card"
                                            fileList={fileList2}
                                            disabled={fileList1.length > 0 ? false : true}
                                            onPreview={this.handlePreview}
                                            // onChange={this.handleChange}
                                            onChange={(fileList) => this.handleChange(fileList, "fileList2")}
                                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList2" )}
                                        >
                                            {fileList2.length === 1 ? null : uploadButton}
                                        </Upload>

                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            accept=".png, .jpg"
                                            listType="picture-card"
                                            fileList={fileList3}
                                            onPreview={this.handlePreview}
                                            // onChange={this.handleChange}
                                            disabled={fileList2.length > 0 ? false : true}
                                            onChange={(fileList) => this.handleChange(fileList, "fileList3")}
                                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList3" )}
                                        >
                                            {fileList3.length === 1 ? null : uploadButton}
                                        </Upload>

                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            accept=".png, .jpg"
                                            listType="picture-card"
                                            fileList={fileList4}
                                            onPreview={this.handlePreview}
                                            disabled={fileList3.length > 0 ? false : true}
                                            // onChange={this.handleChange}
                                            onChange={(fileList) => this.handleChange(fileList, "fileList4")}
                                            beforeUpload={(fileList)=>this.beforeUpload(fileList, "fileList4" )}
                                        >
                                            {fileList4.length === 1 ? null : uploadButton}
                                        </Upload>
                                    </>

                                }

                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img
                                        alt="example"
                                        style={{ width: "100%" }}
                                        src={previewImage}
                                    />
                                </Modal>
                            </Form.Item>
                            {/* <Form.Item className="float-right">
                                {console.log("check mohit props", this.props.details.sales)}
                                <Switch
                                    size="large"
                                    checked={this.props.details.sales == "OFF" ? false : true}
                                    onChange={() => this.onSaleChange(this.props.details.barcode, this.props.details)}
                                />
                            </Form.Item> */}


                            <Form.Item className="float-right">
                                <Button
                                    className="close-modal-red mr-3"
                                    onClick={this.handleDeleteAll}
                                >
                                    Delete All Images
                                </Button>
                                <Button
                                    className="close-modal mr-3"
                                    onClick={this.handleModalCancel}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="ok-modal"
                                    loading={loading}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>
        );
    }
}

AddImage.propTypes = {
    errors: PropTypes.object.isRequired,
    getUniquePurchases: PropTypes.func.isRequired,
    addEcomSale: PropTypes.func.isRequired,
    addProductImage: PropTypes.func.isRequired,
    getBrandImages: PropTypes.func.isRequired,
    deleteProductImage: PropTypes.func.isRequired,
    deletImageByBarcode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    productsImages: state.brandsData.brandImages,
    brandAndDesignCombo: state.purchaseData.ecomSalesPurchases,
});

export default connect(mapStateToProps, { getUniquePurchases, addEcomSale, addProductImage, getBrandImages, deleteProductImage, deletImageByBarcode })(AddImage);