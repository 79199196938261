import React, { Component, useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Card,
  Form,
  Button,
  Tooltip,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockAgeingBiReportsByFilter,
  getfloorSectionBrandSearch,
} from "../../../../redux/actions/reportsActions";
import StockAgeLineChart from "./StockAgeLineChart";
import gifLoader from "../../../../assets/images/giphy.gif";
import { getBrands } from "../../../../redux/actions/brandAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import debounce from "lodash.debounce";
import TopBarProgress from "react-topbar-progress-indicator";

const { Option } = Select;
let stockAgePeriodData = [
  {
    name: 15,
  },
  {
    name: 30,
  },
  {
    name: 45,
  },
  {
    name: 60,
  },
  {
    name: 75,
  },
];

const { Title } = Typography;

const StockAgeBiReport = () => {
  const dispatch = useDispatch();
  const stockAgeData = useSelector(
    (state) => state.reportData.stockAgeBiReportByFilter
  );

  const barndsData = useSelector((state) => state.brandsData.brands);
  const sectionData = useSelector((state) => state.sectionData.sections);
  const floorData = useSelector((state) => state.floorData.floors);
  const [brand, setbrand] = useState("");
  const [floor, setfloor] = useState("");
  const [section, setsection] = useState("");
  const [stockagePeriodRange, setStockAgePeriodRange] = useState(15);
  const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar);
  const debouncedGetBrands = debounce(() => dispatch(getBrands()), 500);
  const debouncedGetSections = debounce(() => dispatch(getSections()), 500);
  const debouncedGetFloors = debounce(() => dispatch(getFloors()), 500);

  const getLastIndex = (arr) => {
    // Initialize the last index variable
    let lastIndex = 0;
    // Iterate over the array
    arr.forEach((obj) => {
      // Extract the key (e.g., "0-20", "20-40", etc.)
      const key = Object.keys(obj)[0];
      // Split the key by hyphen and extract the upper limit
      const upperLimit = parseInt(key.split("-")[1]);
      // Update the last index if the current upper limit is greater
      if (upperLimit > lastIndex) {
        lastIndex = upperLimit;
      }
    });
    return lastIndex;
  };

  const categorizeByRange = (obj, range) => {
    if (!obj) return []; // Check if obj is null or undefined
    let rangeArr = [];
    // Initialize range categories
    for (let i = 0; i <= 300; i += range) {
      rangeArr.push({ [`${i}-${i + range}`]: [] });
    }
    let newRange = rangeArr.filter((obj, index) => index < 5);
    const lastIndexString = getLastIndex(newRange);
    newRange.push({ [`${lastIndexString}++`]: [] });

    // Iterate over objects and categorize them
    obj.forEach((item) => {
      let stockAge = item.stockAge;
      let found = false;
      for (let i = 0; i < newRange.length; i++) {
        let key = Object.keys(newRange[i])[0];
        let minMax = key.split("-");
        if (minMax.length === 1) {
          if (stockAge >= parseInt(minMax[0])) {
            newRange[i][key].push(item);
            found = true;
            break;
          }
        } else {
          if (
            stockAge >= parseInt(minMax[0]) &&
            stockAge <= parseInt(minMax[1])
          ) {
            newRange[i][key].push(item);
            found = true;
            break;
          }
        }
      }
      if (!found) {
        newRange[newRange.length - 1][`${lastIndexString}++`].push(item);
      }
    });
    return newRange;
  };
  // const groupedDataNew = categorizeByRange(stockAgeData?.data, stockagePeriodRange);

  const groupedDataNew = useMemo(() => {
    return categorizeByRange(stockAgeData?.data, stockagePeriodRange);
  }, [stockAgeData, stockagePeriodRange]);

  let newArrKey = [];
  let newArrValue = [];
  // Function for extracting keys and value
  groupedDataNew.forEach((obj) => {
    // Extracting the key and value from each object
    const key = Object.keys(obj)[0];
    const value = obj[key];
    // Pushing the key and value to their respective arrays
    newArrKey.push(key);
    newArrValue.push(value);
  });
  const seriesData = [
    {
      name: "Total Barcode",
      data: newArrValue?.map((obj) => obj.length),
    },
    {
      name: "Total Quantity",
      data: newArrValue?.map((obj) => {
        let total = 0;
        for (let index = 0; index < obj?.length; index++) {
          total = total + obj[index].quantity;
        }
        return total;
      }),
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: newArrKey,
    },
    legend: {
      position: "top",
    },
  };
  const searchFilter = () => {
    let obj = {
      Brand: brand,
      floor: floor,
      section: section,
    };
    for (let key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    dispatch(getStockAgeingBiReportsByFilter(obj));
  };
  const resetFun = () => {
    window.location.reload(true);
  };
  const handleChangeBrand = (key, value) => {
    let brandName = [];
    brandName = value?.map((obj) => obj.children);
    setbrand(brandName);
  };
  const handleChangeSection = (key, value) => {
    setsection([value.value]);
    if (floor) {
      dispatch(
        getfloorSectionBrandSearch({ section: [value.value], floor: floor })
      );
    } else {
      dispatch(getfloorSectionBrandSearch({ section: [value.value] }));
    }
  };

  const handleChangeFloor = (key, value) => {
    setfloor([value.value]);
    if (section) {
      dispatch(
        getfloorSectionBrandSearch({ floor: [value.value], section: section })
      );
    } else {
      dispatch(getfloorSectionBrandSearch({ floor: [value.value] }));
    }
  };
  const handeChangeStockAge = (e) => {
    setStockAgePeriodRange(e);
  };
  const getData = (name) => {
    let obj = {
      Brand: brand,
      floor: floor,
      section: section,
    };
    for (let key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    if (name === "brand") debouncedGetBrands();
    if (name === "section") debouncedGetSections();
    if (name === "floor") debouncedGetFloors();
  };
  let stockAgePeriod = stockAgePeriodData.map((obj) => {
    return (
      <Option name={obj.name} value={obj.name}>
        {obj.name}
      </Option>
    );
  });
  let brandOptions = barndsData?.map((brand) => {
    return (
      <Option name={brand.name} value={brand.name}>
        {brand.name}
      </Option>
    );
  });

  let sectionOptions = sectionData?.map((section) => {
    return (
      <Option name={section.name} value={section.name}>
        {section.name}
      </Option>
    );
  });

  let floorOptions = floorData?.map((floor) => {
    return (
      <Option name={floor.name} value={floor.name}>
        {floor.name}
      </Option>
    );
  });

  return (
    <>
      {progressBarStatus && <TopBarProgress />}
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Form
            name="add-Purchase"
            className="add-Purchase"
            onFinish={searchFilter}
          >
            <Row className="title-row" gutter={[8, 0]}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className="page-header">Stockage Report</div>
              </Col>

              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="Select Floor">
                  <Form.Item>
                    <Select
                      showSearch
                      placeholder="Select Floor"
                      optionFilterProp="children"
                      onChange={handleChangeFloor}
                      onClick={() => getData("floor")}
                      onInputKeyDown={() => getData("floor")}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {floorOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="Select Section">
                  <Form.Item name="section">
                    <Select
                      showSearch
                      placeholder="Select Section"
                      optionFilterProp="children"
                      onChange={handleChangeSection}
                      onClick={() => getData("section")}
                      onInputKeyDown={() => getData("section")}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {sectionOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="Select Brand">
                  <Form.Item name="brand">
                    <Select
                      mode="multiple"
                      name="brand"
                      id="brand"
                      showSearch
                      placeholder="Select Brand"
                      optionFilterProp="children"
                      onChange={handleChangeBrand}
                      onClick={() => getData("brand")}
                      onInputKeyDown={() => getData("brand")}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {brandOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="close-modal"
                    block
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Form.Item>
                  <Button
                    type="primary"
                    // htmlType="submit"
                    className="btn-stock-report-search"
                    block
                    onClick={() => resetFun()}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="card mt-2 final-purchases-list mt-4">
            <div className="card-body">
              <div className="mb-5 mt-2">
                <div>
                  <div id="chart">
                    <Row gutter={[16, 0]} className="mb-4">
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Title level={5}>
                          Stock Age Range : {stockagePeriodRange}
                        </Title>
                        <Title level={5}>
                          Average Stock Age :{" "}
                          <span style={{ color: "#52c41a" }}>
                            {stockAgeData?.avgStock &&
                              Number(stockAgeData?.avgStock).toFixed()}
                          </span>
                        </Title>
                        <Title level={5}>
                          Total Amount :{" "}
                          <span style={{ color: "#52c41a" }}>
                            {stockAgeData?.formattedAmount || ""}
                          </span>
                        </Title>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} align="end">
                        <Select
                          style={{ minWidth: "250px" }}
                          name="stockAgePeriod"
                          placeholder="Select Stock Age Period"
                          onChange={handeChangeStockAge}
                        >
                          {stockAgePeriod}
                        </Select>
                      </Col>
                    </Row>
                    <StockAgeLineChart
                      options={options}
                      seriesData={seriesData}
                    />
                  </div>
                  <div id="html-dist"></div>
                </div>
                <Row className="mt-4">
                  <Col span={24}>
                    <Title className="mt-4" level={5}>
                      Stock Age Summary
                    </Title>
                  </Col>
                </Row>
                {console.log("check mohit hello", groupedDataNew)}
                {console.log("check mohit hellostockAgeData", stockAgeData)}

                <Row className="rowgap-vbox " gutter={[24, 0]}>
                  {groupedDataNew?.map((group, index) => {
                    let rangeName = "";
                    let rangeArray = [];
                    for (const property in group) {
                      rangeName = property;
                      rangeArray = group[property];
                    }
                    let sumAmount = rangeArray?.reduce(
                      (acc, obj) =>
                        acc + Number(obj.wsp) * Number(obj.quantity),
                      0
                    );
                    let sumQty = rangeArray?.reduce(
                      (acc, obj) => acc + obj.quantity,
                      0
                    );
                    console.log("sumAmount", Number(sumAmount), "totalAmount", stockAgeData?.totalAmount )
                    let totalPercent =
                      (Number(sumAmount) / stockAgeData?.totalAmount) * 100;
                    return (
                      <>
                        <Col
                          key={1}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={6}
                          xl={8}
                          className="mb-24 mt-4"
                        >
                          {console.log("check mohit hello percent", totalPercent)}
                          <Card bordered={false} className="criclebox ">
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                  <span>{rangeName} Days</span>
                                  <Title level={5}>
                                    <small className="bnb2">₹</small>{" "}
                                    {Number(sumAmount).toFixed()}
                                  </Title>
                                  <span>Total Quantity : {sumQty}</span>
                                  <br />
                                  <span>
                                    Total Percentage :{" "}
                                    {isNaN(totalPercent) ? 0 : Number(totalPercent).toFixed(2)}{" "}
                                    <span style={{ color: "#52c41a" }}>%</span>
                                  </span>
                                </Col>
                                <Col xs={6}>
                                  <div className="icon-box">
                                    {" "}
                                    <i className="fas fa-line-chart"></i>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockAgeBiReport;